import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

import data from '../data/_data'
import PflegenFusszeile from './Fusszeile'
import SearchList from './_helpers/SearchList'
import { dict2options, fieldData2options, flattenObject2Array, object2options, optionsFilter, list2options, objectList2AttrList, projectduration } from './_helpers/functions'
import { sendGetConsultantListRequest, sendGetConsultant4ModerationListRequest } from '../redux/consultants'
import { sendGetConsultantprojectListRequest, sendGetConsultantproject4ModerationListRequest } from '../redux/consultantprojects'
import { sendGetCustomerListRequest } from '../redux/costumers'
import { sendGetCustomerprojectListRequest } from '../redux/customerprojects'
import { sendGetCompetenceListRequest } from '../redux/competences'
import { sendDataRequest } from '../redux/fielddata'
import businessobject_data from '../data/businessobject_data'
import NotFound from './NotFound';
import { unsetSuccess, setSearchFilterQuery } from '../redux/businessobjects'
import Pflegen_Gefuehrt from './Pflegen/Pflegen_Gefuehrt'
import Suche_Kopfzeile from './Suche_Kopfzeile'

/**
 * Haupt-Komponente für die Seiten "Beraterprofil Pflegen".
 * Suchen der Geschaeftsobjekte, auswählen zum Bearbeiten/Ansehen
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {String} username - benutzername des angemeldeten Benutzers
 * @param {String} businessObject - aktuell gewaehltes Geschaeftsobjekt
 * @param {Function} onSelect - Funktion zum setzen des ausgewählten Objektes
 * @param {String} onSelectCategory - Key des CategoryFilter bei onSelect
 * @param {Function} onSelectCreate - Funktion zum Anlegen eines neuen Objektes bei onSelect
 * @param {String} onSelectProperty - Key des als Fremdschlüssel zu verwendenen Objektes bei onSelect
 * @param {Object} lists - Liste aller Geschaeftsobjekte des Geschaeftsobjekt aus dem State, von der API, zur Anziege in der Suche
 * @param {Object} objectlist - Liste aller Objekte der Kompetezen
 * @param {Object} filter - key und value zum Filtern der anzuzeigenden Liste an Objekte
 * @param {Function} getConsultantList - Funktion zum request der Liste der Geschaeftsobjekte von der API
 * @param {Function} getConsultantprojectList - Funktion zum request der Liste der Geschaeftsobjekte von der API
 * @param {Function} getCostumerList - Funktion zum request der Liste der Geschaeftsobjekte von der API
 * @param {Function} getCostumerprojectList - Funktion zum request der Liste der Geschaeftsobjekte von der API
 * @param {Function} getCompetenceList - Funktion zum request der Liste der Geschaeftsobjekte von der API
 * @param {String} errorMsg - errorMsg wenn API call fehlschlägt
 * @param {String} successMsg - successMsg wenn API call erfolgreich
 * @param {Function} unsetSuccess - setzt die successMesg wieder zurück
 * @param {Object} fieldData - geschaeftsobjekt oder 'ansprechpartner', nur wenn Komponente direkt und nicht über das Routing aufgerufen wird
 * @param {Function} getFieldData - Funktion zum request der Liste der Geschaeftsobjekte von der API
 * @returns {HTML} Seite zum Suchen der Geschaeftsobjekte (inkl. Kopf- und Fußzeile) 
 * @see PflegenKopfzeile
 * @see PflegenFusszeile
 */
function Suche({role, username, lists, consultants4moderation, consultantprojects4moderation, filter, objectList, getConsultantList, getConsultantprojectList, getConsultants4Moderation, getConsultantprojects4Moderation, getCostumerList, getCostumerprojectList, getCompetenceList, errorMsg, successMsg, unsetSuccess, businessObject, onSelect, onSelectCategory, onSelectProperty, excludeFromSelect, onSelectPrefiltered, onSelectCreate, fieldData, getFieldData, fieldDataError, searchFilterQuery, setSearchFilterQuery}){
    // url param
    const { geschaeftsobjekt } = useParams()
    businessObject = (onSelect ? businessObject : (geschaeftsobjekt ? geschaeftsobjekt : businessObject))
    
    // state for the SearchList Filter, set by the Kopfzeile
    const [locked, setLocked] = useState(false)
    const [searchAttr, setSearchAttrIntern] = useState((searchFilterQuery?.searchAttr ? searchFilterQuery.searchAttr : ''))
    const [searchVal, setSearchVal] = useState((searchFilterQuery?.searchVal ? searchFilterQuery.searchVal : ''))
    const [searchAttr2, setSearchAttr2] = useState('')
    const [searchVal2, setSearchVal2] = useState('')
    const [searchFKAttr, setSearchFKAttr] = useState('')
    const [searchStartDate, setSearchStartDate] = useState('')
    const [searchEndDate, setSearchEndDate] = useState(-1)
    const [statusFilter, setStatusFilter] = useState({})
    const [categoryFilter, setCategoryFilter] = useState((onSelectCategory ? onSelectCategory : (businessObject === data.businessObject.moderation ? data.businessObject.consultant : '')))
    const [countList, setCountList] = useState(0)
    const [searchDate, setSearchDate] = useState(new Date())
    const [booleanFilter, setBooleanFilter] = useState({})
    const [customFilter, setCustomFilter] = useState(false)


    const setSearchAttr = (attr) => {
        if (Object.values(data.prefixes).some(p => attr.includes(p))){
            setSearchAttrIntern(attr.split('_')[1])
        } else {
            setSearchAttrIntern(attr)
        }
    }

    if (successMsg){
        unsetSuccess()
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });


    useEffect(() => {
        if (searchVal){
            setSearchFilterQuery({searchVal, searchAttr})
        }
    }, [searchVal, searchAttr])

    useEffect(() => {
        if (searchFilterQuery?.searchVal){
            setSearchVal(searchFilterQuery.searchVal)
            setSearchAttr(searchFilterQuery.searchAttr)
        }
    }, [searchFilterQuery])

    if (searchFilterQuery && searchFilterQuery.searchVal && searchFilterQuery.searchVal !== searchVal){
        setSearchVal(searchFilterQuery.searchVal)
        setSearchAttr(searchFilterQuery.searchAttr)
    }

    const getCompetenceCategoryFilterData =() => {
        let tempCatData = {}
        if (lists.competences){
            for (let cat of Object.keys(lists.competences)){
                if (businessobject_data.types[cat] && businessobject_data.types[cat].attributeGroup){
                    cat = businessobject_data.types[cat].attributeGroup
                    tempCatData[cat] = cat[0].toUpperCase() + cat.substring(1)
                }
                tempCatData[cat] = cat[0].toUpperCase() + cat.substring(1)
            }
            return tempCatData
        } else {
            return tempCatData
        }
    }

    const [categoryFilterData, setCategoryFilterData] = useState(getCompetenceCategoryFilterData())

    // load required Data from the API
    useEffect(() => {
        if (!fieldData || !Object.keys(fieldData).length){
            getFieldData();
        }
        if (!lists.competences || !Object.keys(lists.competences).length){
            getCompetenceList();
        }
        if (!lists.consultants || !Object.keys(lists.consultants).length){
            getConsultantList();
        }
    }, [])

    // load required Data from the API according to the current businessobject
    const requestListByBusinessObject = (cls) => {
        if (!objectList || !onSelect){
            switch (cls){
                case data.businessObject.consultant:
                    getConsultantList();
                    setSearchDate(new Date());
                    break;
                case data.businessObject.consultantproject:
                    getCostumerList();
                    getCostumerprojectList();
                    getConsultantprojectList();
                    setSearchDate(new Date());
                    break;
                case data.businessObject.customer:
                    getCostumerList();
                    setSearchDate(new Date());
                    break;
                case data.businessObject.customerproject:
                    getCostumerList();
                    getCostumerprojectList();
                    setSearchDate(new Date());
                    break;
                case data.businessObject.competence:
                    getFieldData();
                    getCompetenceList();
                    setSearchDate(new Date());
                    break;
                case data.businessObject.gefuehrt:
                    getConsultantprojectList();
                    getCostumerList();
                    getCostumerprojectList();
                    setSearchDate(new Date());
                    break;
                case data.businessObject.moderation:
                    getConsultants4Moderation();
                    getConsultantprojects4Moderation();
                    getCostumerList();
                    getCostumerprojectList();
                    setSearchDate(new Date());
                    break;
                case data.businessObject.drucken:
                    getConsultantList();
                    getCostumerList();
                    getCostumerprojectList();
                    getConsultantprojectList();
                    getCompetenceList();
                    setSearchDate(new Date());
                    break;
                default:
                    break;
            }
        }
    }

    // return header according to the current businessobject
    const switchListByBusinessObject = (cls) => {
        // if (objectList){return objectList}
        switch (cls){
            case data.businessObject.consultant:
                if (filter){
                    return optionsFilter(lists.consultants, filter.attr, filter.value)
                } else if (searchVal && (businessobject_data.types[searchAttr] && businessobject_data.types[searchAttr].dataAttribute && businessobject_data.types[searchAttr].dataAttribute === data.businessObject.competence) && !!searchFKAttr){
                    return filterByFK(lists.consultants, flattenObject2Array(lists.competences[data.businessObject.competence]), businessobject_data.types.kompetenzen.sortProperty)
                } 
                else {
                    return lists.consultants
                }
            case data.businessObject.consultantproject:
                if (searchVal && (businessobject_data.types[searchAttr] && businessobject_data.types[searchAttr].dataAttribute && businessobject_data.types[searchAttr].dataAttribute === data.businessObject.competence) && !!searchFKAttr){
                    return filterByFK(lists.consultantprojects, flattenObject2Array(lists.competences[data.businessObject.competence]), businessobject_data.types.kompetenzen.sortProperty)
                } else {
                return lists.consultantprojects
                }
            case data.businessObject.customer:
                return lists.customer
            case data.businessObject.customerproject:
                return lists.customerprojects
            case data.businessObject.competence:
                if (categoryFilter && categoryFilter !== data.businessObject.competence && categoryFilter !== data.businessObject.qualification){
                    if (businessobject_data.types[categoryFilter] && businessobject_data.types[categoryFilter].attributeGroup){
                        let groupedList = []
                        if (lists.competences){
                            for (let groupAttribute of businessobject_data[businessobject_data.types[categoryFilter].attributeGroup].childs){
                                for (let comp of lists.competences[groupAttribute]){
                                    groupedList.push({...comp, attributeType: groupAttribute})
                                }
                            }
                        }
                        return groupedList
                    } else {
                        return ((lists.competences && categoryFilter) ? lists.competences[categoryFilter] : [])
                    }
                } else {
                    return (lists.competences ? (categoryFilter ? flattenObject2Array(lists.competences[categoryFilter]) : flattenObject2Array(lists.competences[data.businessObject.competence])) : [])
                }
            case data.businessObject.moderation:
                if (categoryFilter){
                    if (categoryFilter === data.businessObject.consultant){
                        if (consultants4moderation){
                            if (searchVal && (businessobject_data.types[searchAttr] && businessobject_data.types[searchAttr].dataAttribute && businessobject_data.types[searchAttr].dataAttribute === data.businessObject.competence) && !!searchFKAttr){
                                return filterByFK(consultants4moderation, flattenObject2Array(lists.competences[data.businessObject.competence]), businessobject_data.types.kompetenzen.sortProperty)
                            } else {
                                return [...consultants4moderation]
                            }
                        } else {
                            return []
                        }
                    }
                    if (categoryFilter === data.businessObject.consultantproject){
                        if (consultantprojects4moderation){
                            if (searchVal && (businessobject_data.types[searchAttr] && businessobject_data.types[searchAttr].dataAttribute && businessobject_data.types[searchAttr].dataAttribute === data.businessObject.competence) && !!searchFKAttr){
                                return filterByFK(consultantprojects4moderation, flattenObject2Array(lists.competences[data.businessObject.competence]), businessobject_data.types.kompetenzen.sortProperty)
                            } else {
                                return [...consultantprojects4moderation]
                            }
                        } else {
                            return []
                        }
                    }
                    if (categoryFilter === data.businessObject.competence || categoryFilter === data.businessObject.qualification){return (lists.competences ? [...flattenObject2Array(lists.competences[categoryFilter]).filter(c => c.status === data.status.FREIGABE_MITARBEITER)] : [])}
                } else {
                    return (consultants4moderation ? [...consultants4moderation] : [])
                }
                // const competences4moderation = lists.competences?.filter(c => c.status === data.status.FREIGABE_MITARBEITER)
            case data.businessObject.drucken:
                var tempConsultantList = lists.consultants
                if (customFilter && lists.consultants && lists.consultantprojects){
                    tempConsultantList = []
                    for (let c of lists.consultants){
                        var indConsultantprojects = lists.consultantprojects.filter(cp => (cp.berater === c.kuerzel && !cp.enddatum))
                        if (indConsultantprojects.length === 0){
                            tempConsultantList.push(c)
                        }
                    }
                } 
                if (searchVal && (businessobject_data.types[searchAttr] && businessobject_data.types[searchAttr].dataAttribute && businessobject_data.types[searchAttr].dataAttribute === data.businessObject.competence) && !!searchFKAttr){
                    tempConsultantList = filterByFK(tempConsultantList, flattenObject2Array(lists.competences[data.businessObject.competence]), businessobject_data.types.kompetenzen.sortProperty)
                    const tempConsultantprojectList = filterByFK(lists.consultantprojects, flattenObject2Array(lists.competences[data.businessObject.competence]), businessobject_data.types.kompetenzen.sortProperty)
                    for (let cp of tempConsultantprojectList){
                        if (!tempConsultantList.some(c => c.kuerzel === cp.berater)){
                            tempConsultantList.push(lists.consultants.find(c => c.kuerzel === cp.berater))
                        }
                    }
                    return tempConsultantList
                }
                tempConsultantList = tempConsultantList?.filter(c => c.status === data.status.FREIGABE_QUALITAETSSICHERUNG)
                return tempConsultantList
            default:
                return []
        }
    }
    
    // setting according to the current businessobject
    const settings = {
        benutzer: {
            createButtonValue: "Benutzer anlegen",
            extendStyle: {display: 'none'}
        },
        berater: {
            createButtonValue: "Berater anlegen",
            extendStyle: {}
        },
        kunde: {
            createButtonValue: "Kunde anlegen",
            extendStyle: {}
        },
        beraterprojekt: {
            createButtonValue: "Beraterprojekt anlegen",
            extendStyle: {}
        },
        kundenprojekt: {
            createButtonValue: "Kundenprojekt anlegen",
            extendStyle: {}
        },
        kompetenz: {
            createButtonValue: [{label: categoryFilterData[categoryFilter]+" anlegen", value: businessObject+"/"+categoryFilter}], //[...Object.keys(categoryFilterData).map((k, i) => ({label: categoryFilterData[k]+" anlegen", value: k}))],
            extendStyle: {display: 'none'}
        },
        ansprechpartner: {
            createButtonValue: "Ansprechpartner anlegen",
            extendStyle: {}
        },
        moderation: {
            createDisabled: true,
            extendStyle: {}
        },
        drucken: {
            createDisabled: true,
            extendStyle: {}
        }
    }//display: 'none'

    // Filter Competence by Selected Tab (categoryFilter)
    // const preFilterCompetences = (objectList) => {
    //     const cF = (categoryFilter ? categoryFilter : 'kompetenz')
    //     let tempList = []
    //     if (objectList && cF){
    //         for (let k of Object.keys(objectList[cF])){
    //             for (let e of objectList[cF][k]){
    //                 if (cF !== data.businessObject.competence && cF !== data.businessObject.qualification){
    //                     e = {...e, attributeType:k}
    //                 } 
    //                 tempList.push(e)
    //             }
    //         } 
    //     }
    //     return tempList
    // }

    // create options for React-Select of available competence/qualification/... categorie
    const getCompetenceFilterData = (cF) => {
        if (fieldData && lists.competences){
            if (cF === data.businessObject.competence){
                return object2options(fieldData2options(fieldData)[businessobject_data.types.kategorie.dataAttribute])
            } else if (cF === data.businessObject.qualification){
                return object2options(fieldData2options(fieldData)[businessobject_data.types.typ.dataAttribute])
            } else if (cF && businessobject_data.types[cF] && businessobject_data.types[cF].attributeGroup){
                return list2options(businessobject_data[businessobject_data.types[cF].attributeGroup].childs)
            } else {
                return object2options(fieldData2options(fieldData)[businessobject_data.types.kategorie.dataAttribute])
            }
        }
        else {return []}
    }

    const filterByFK = (list, lookUp, lookUpAttr) => {
        let tList = []
        if (list){
            for (let elem of list){
                for (let k of elem[searchAttr]){
                    let lElem = lookUp.find(l => (l.id === ((businessobject_data.types[searchAttr] && businessobject_data.types[searchAttr].idProperty) ? k[businessobject_data.types[searchAttr].idProperty] : k) ))
                    if (lElem[lookUpAttr] === searchFKAttr && lElem.titel.toLowerCase().includes(searchVal.toLowerCase())){
                        tList.push(elem)
                        break
                    }
                }
            }
        }
        return tList
    }

    const decode2presentation = (attr, value, elem) => {
        if (value && attr.includes('Id') && !businessobject_data.displayList[attr]){
            if (lists.competences){
                const dataLocation = ((businessobject_data.types[attr] && businessobject_data.types[attr].dataLocation) ? businessobject_data.types[attr].dataLocation : null)
                const dataAttribute = ((dataLocation === data.businessObject.competence) ? elem.kategorie  : ((businessobject_data.types[attr] && businessobject_data.types[attr].dataAttribute) ? businessobject_data.types[attr].dataAttribute : attr))
                if (dataLocation && dataAttribute){
                    return optionsFilter(lists.competences[dataLocation][dataAttribute], 'id', value)[0].titel
                }
                return optionsFilter(lists.competences[(dataLocation ? dataLocation : dataAttribute)], 'id', value)[0].titel
            } else {return value}
        } if (businessobject_data.types[attr] && businessobject_data.types[attr].type === data.inputTypes.standard.DATE){
            return new Date(value).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})+''
        } if (typeof value === 'string' && (value.toUpperCase() === value || value.includes('__') || value.includes('_u_'))){
            return ((fieldData && fieldData2options(fieldData)[((businessobject_data.types[attr] && businessobject_data.types[attr].dataAttribute) ? businessobject_data.types[attr].dataAttribute : attr)]) ? fieldData2options(fieldData)[((businessobject_data.types[attr] && businessobject_data.types[attr].dataAttribute) ? businessobject_data.types[attr].dataAttribute : attr)][value] : value)
        } if (businessobject_data.displayList[attr] && typeof businessobject_data.displayList[attr] === 'object'){
            const join = businessobject_data.displayList[attr]
            const List2Join = switchListByBusinessObject(join.join)
            var joinedList = [];
            if (!List2Join || List2Join.length === 0 || !value){return ''}
            if (join.joinType === 'left'){
                if (!Array.isArray(value)){
                    if (typeof value === 'object' && value[attr] && Array.isArray(value[attr])){
                        value = value[attr]
                    } else {
                        return ''
                    }
                }
                if (join.filterBy && join.filterValue) {
                    value = optionsFilter(value, join.filterBy, join.filterValue)
                }
                joinedList = optionsFilter(List2Join, join.joinOn.right, objectList2AttrList(value, join.joinOn.left))
            }
            if (join.joinType === 'right'){
                if (!value[join.joinOn.left]){return ''}
                let joinValue = value[join.joinOn.left]
                if (Array.isArray(joinValue) && join.filterBy && join.filterValue) {
                    joinValue = optionsFilter(joinValue, join.filterBy, join.filterValue)
                } 
                joinedList = optionsFilter(List2Join, join.joinOn.right, objectList2AttrList(joinValue, join.joinOn.right))
            }
            if (joinedList.length > 0){
                if (join.nextJoin){
                    return decode2presentation(join.nextJoin, joinedList[0])
                }
                if (join.calc){
                    if (join.calc === "projectduration"){
                        let experience = parseFloat(value.erfahrung)
                        for (let pro of joinedList){
                            experience += projectduration(pro)
                        }
                        return parseFloat(experience.toFixed(1))
                    }
                }
                var result = "";
                for(let t of objectList2AttrList(joinedList, join.display).slice(0,3)){
                    result += (t + ", ")
                }
                if (result){
                    return (result.length > 2 ? result.slice(0, -2) : result)
                } 
            } else {return "";}
        } if (Array.isArray(value) || (value && value[attr] && Array.isArray(value[attr]))) {
            value = (Array.isArray(value) ? value : value[attr])
            let result = ""
            for (let e of value){
                if (typeof e !== 'object'){
                    result += (e + ", ")
                } else {
                    if (businessobject_data.displayList[attr] && typeof businessobject_data.displayList[attr] !== 'object'){
                        if (businessobject_data.displayList[attr].includes(",")){
                            let attrs = businessobject_data.displayList[attr].split(", ")
                            result += ((e[attrs[0]] ? e[attrs[0]]+ ", " : '') + (e[attrs[1]] ? e[attrs[1]]+ "; " : ''))
                        } else {
                            result += ((e[businessobject_data.displayList[attr]] ? e[businessobject_data.displayList[attr]]+", " : ''))
                        }
                    } else {
                        if (e.titel){
                            result += (e.titel + ", ")
                        }
                        if (e.name){
                            result += (e.name + ", ")
                        } else {return "";}
                    } 
                }
            }
            if (result){
                return (result.length > 2 ? result.slice(0, -2) : result)
            }
        } else {
            return ''
        }
    }

    if (onSelect && (statusFilter && Object.keys(statusFilter).length === 0 )){
        setStatusFilter({FREIGABE_QUALITAETSSICHERUNG: 'Freigabe QS'})
    }

    useEffect(() => {
        if(Object.keys(categoryFilterData).length === 0){setCategoryFilterData(getCompetenceCategoryFilterData())}
    }, [lists.competences]) // DEV
    useEffect(() => {
        if (categoryFilterData && Object.keys(categoryFilterData)[0] && businessObject === data.businessObject.competence){setCategoryFilter(Object.keys(categoryFilterData)[0])}
        else {setCategoryFilter((businessObject === data.businessObject.moderation ? data.businessObject.consultant : ''))}
    }, [categoryFilterData, businessObject, successMsg])
    useEffect(() => {
        requestListByBusinessObject(businessObject);
    }, [businessObject, successMsg])
    
    // render NotFound
    if (!Object.values(data.businessObject).includes(businessObject) || geschaeftsobjekt === data.businessObject.contactperson){
        
        return <NotFound notFoundSite={'pflegen'} notFoundObject={businessObject} errorMsg={errorMsg} />
    }
    else if (businessObject===data.businessObject.gefuehrt){
        return (<Pflegen_Gefuehrt searchDate={searchDate} />)
    }

    return(
        <div className="content">
        <Suche_Kopfzeile accessStatus={data.accessStatus.search} onSelect={onSelect} onSelectPrefiltered={onSelectPrefiltered} role={role} businessObject={businessObject} successMsg={successMsg} setLockedState={setLocked} setSearchAttrState={setSearchAttr} setSearchValState={setSearchVal} setSearchAttrState2={setSearchAttr2} setSearchValState2={setSearchVal2} setCustomFilterState={setCustomFilter} setStatusFilterState={setStatusFilter} setBooleanFilterState={setBooleanFilter} loggedInUsername={username} competenceFilterData={getCompetenceFilterData(categoryFilter)} categoryFilterData={categoryFilterData} onSelectCategory={onSelectCategory} setSearchStartDateState={setSearchStartDate} setSearchEndDateState={setSearchEndDate} setCategoryFilterState={setCategoryFilter} categoryFilterState={categoryFilter} setSearchFKAttrState={setSearchFKAttr} filterQuery={searchFilterQuery} setFilterQuery={setSearchFilterQuery} />
        <div className="inner-content">
            <SearchList role={role} username={username} settings={settings} onSelectPrefiltered={onSelectPrefiltered} businessObject={businessObject} list={(objectList ? objectList : switchListByBusinessObject(businessObject))} decode2presentation={decode2presentation} setCountList={setCountList} locked={locked} searchAttr={searchAttr} searchVal={(!!searchFKAttr ? '' : searchVal)} searchAttr2={searchAttr2} searchVal2={searchVal2} dateFilter={{searchStartDate, searchEndDate}} statusFilter={statusFilter} booleanFilter={booleanFilter} categoryFilter={categoryFilter} onSelect={onSelect} excludeFromSelect={excludeFromSelect} onSelectProperty={onSelectProperty} onSelectCreate={onSelectCreate} />
            {(fieldDataError && !errorMsg) && <div><strong className="error">{fieldDataError}</strong><br /> <br /> </div>}
            {errorMsg && <div><strong className="error">{errorMsg}</strong></div>}
        </div>
        <PflegenFusszeile accessStatus={data.accessStatus.search} countList={countList} date={searchDate} />
        </div>
    )
}

/**
 * Weist den verwendeten lokalen Variablen, variablen aus dem State zu
 * 
 * @param {*} state - aktueller State
 * @param {*} ownProps - weitere Variablen
 * @returns den aktuellen State als Komponentenvariablen
 */
 const mapStateToProps = (state, ownProps) => ({
    role: state.user.role,
    username: state.user.username,
    lists: {
        consultants: state.consultants.list,
        consultantprojects: state.consultantprojects.list,
        customer: state.customers.list,
        customerprojects: state.customerprojects.list,
        competences: state.competences.list
    },
    consultants4moderation: state.consultants.moderation,
    consultantprojects4moderation: state.consultantprojects.moderation,
    errorMsg: state.businessobjects.error,
    fieldData: state.fielddata.data,
    fieldDataError: state.fielddata.error,
    successMsg: state.businessobjects.success,
    searchFilterQuery: state.businessobjects.searchFilterQuery,
    ...ownProps
})

/**
 * Weist den verwendeten lokalen Funktionen, Funktionen aus dem Redux zu
 * 
 * @returns Die gemappten Funktionen
 */
const mapDispatchToProps = dispatch => ({
    getConsultantList: () => dispatch(sendGetConsultantListRequest()),
    getConsultantprojectList: () => dispatch(sendGetConsultantprojectListRequest()),
    getConsultants4Moderation: () => dispatch(sendGetConsultant4ModerationListRequest()),
    getConsultantprojects4Moderation: () => dispatch(sendGetConsultantproject4ModerationListRequest()),
    getCostumerList: () => dispatch(sendGetCustomerListRequest()),
    getCostumerprojectList: () => dispatch(sendGetCustomerprojectListRequest()),
    getCompetenceList: () => dispatch(sendGetCompetenceListRequest()),
    getFieldData: () => dispatch(sendDataRequest()),
    unsetSuccess: () => dispatch(unsetSuccess()),
    setSearchFilterQuery: (query) => dispatch(setSearchFilterQuery(query))
}) 

export default connect(mapStateToProps, mapDispatchToProps)(Suche)