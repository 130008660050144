import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';

import businessobject_data from '../../../data/businessobject_data'
import data from '../../../data/_data'

import PflegenKopfzeile_Kundenprojekt from './PflegenKopfzeile_Kundenprojekt'

import {sendGetCustomerprojectListRequest, sendDeleteCustomerprojectRequest, sendCreateCustomerprojectRequest, sendUpdateCustomerprojectRequest, sendFileDownload, sendCreateCustomerprojectFileRequest, sendDeleteCustomerprojectFileRequest, sendCustomerprojectFileDownload} from '../../../redux/customerprojects'
import PflegenFusszeile from '../../Fusszeile'
import NotFound from './../../NotFound';
import message_data from './../../../data/message_data';
import { PermissionForbidden } from './../../_helpers/ReadPermission';
import { somethingChanges, defaultEncoder, equals, getOptionsInit, optionsDataByTypeInit, optionsFilter, validateValues, checkInvalidation, checkReadOnly, arrayDefaultFilter, fieldData2options } from './../../_helpers/functions';
import { setSuccess } from './../../../redux/businessobjects';
import { DropdownInput, InputByType, TextInput, FileInput, ObjectFKInput } from './../../_helpers/InnerContent';
import PopUp from './../../_helpers/PopUp';
import {HandleMainInputs} from '../../_helpers/InnerContent';



/**
 * Unter-Komponente für die Seiten "Beraterprofil Pflegen".
 * Bearbeitet oder legt den gewählte Berater an
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {String} username - benutzername des angemeldeten Benutzers
 * @param {String} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} accessStatus - status des Pflegens des Geschaeftsobjektes (suche, neu, gefuehrt)
 * @param {Object} id - die ID des konkreten Kundenprojekts
 * @param {Object} createdId - die ID des gerade erstellten Beraters
 * @param {Object} lists - Liste aller Kundenprojekte aus dem State, von der API
 * @param {Object} lists - Dict mit Listen aller Berater und Kunden aus dem State, von der API
 * @param {Function} getList - Funktion zum request der Kundenprojekte von der API
 * @param {Function} sendCreate - Funktion zum API call zum Erstellen des Objektes
 * @param {Function} sendUpdate - Funktion zum API call zum Aktualisieren des Objektes
 * @param {Function} sendDelete - Funktion zum API call zum Löschen des Objektes
 * @param {Function} sendCreateFile - Funktion zum API call zum Erstellen einer Datei
 * @param {Function} sendDeleteFile - Funktion zum API call zum zum Löschen einer Datei
 * @param {Function} sendFileDownload - Funktion zum API call zum Downloaden einer Datei
 * @param {Object} requestedFile4Download - Die Angefragte Datei
 * @param {String} errorMsg - errorMsg wenn API call fehlschlägt
 * @param {String} fieldData - Feldauspraegungen aus dem State
 * @param {String} competenceData - Kompetenzen zur Auswahl im Dropdown o.ä.
 * @param {String} fieldDataError - errorMsg wenn API call, speziell für die Feldauspraegungen fehlschlägt
 * @param {String} onSelect - Funktion welche aufgerufen wird wenn Angeleggt wird, nur wnen Komponentnet direkt und nicht über das Routing aufgerufen wird
 * @param {String} onSelectProperty - Key des als Fremdschlüssel zu verwendenen Objektes bei onSelect
 * @param {String} successMsg - successMsg wenn Anlegen oder Update erfolgreich
 * @param {String} setfurtherSuccess - successMsg wenn auch das Update oder Anlegen von Dateien erfolgreich
 * @returns {HTML} Seite zum Pflegen der Geschaeftsobjekte
 * @see PflegenKopfzeile
 * @see PflegenFusszeile
 */
function Pflegen_Kundenprojekt({role, username, id, createdId, lists, accessStatus, readOnly, errorMsg, fieldDataError, object, requestedFile, list, getList, sendDelete, sendUpdate, sendCreate, sendFileDownload, sendCreateFile, sendDeleteFile, setfurtherSuccess, successMsg, onSelect, onSelectProperty, competenceData, fieldData}){
    // some State Var for PopUp and Notification about the success of an api call
    const [showDeletePopUp, setShowDeletePopUp] = useState(false)
    const [showLockPopUp, setShowLockPopUp] = useState(false)    
    const [failedValidation, setFailedValidation] = useState({notnull: {}, regex: {}, semantic:  {}})

    const businessObject = data.businessObject.customerproject
    const [tab, setTabintern] = useState(Object.keys(businessobject_data[businessObject].fields)[0])
    const setTab = (tab) => {
        if(somethingChanges(accessStatus, values, object, businessObject) && !validateValues(setError, values, failedValidation, setFailedValidation)){
            return;
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTabintern(tab)
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    const history = useHistory();
    useEffect(() => {if (successMsg === message_data.success.redux.default){history.push('/pflegen')}}, [successMsg])

    // get object from list by id
    if(accessStatus === data.accessStatus.update && list){
        object = list.find(o => o.id === parseInt(id))
    }

    const [error, setError] = useState("")
    // values for the object to safe
    const [values, setValues] = useState((accessStatus === data.accessStatus.update ? defaultEncoder(object) : defaultEncoder(businessobject_data.kundenprojekt.default)))
    // check the rights to read an write of the current user
    if (values){readOnly=(readOnly ? readOnly : PermissionForbidden(role, username, id, data.businessObject.customerproject, values))}
    // load object from default
    useEffect(() => {
        if (object && accessStatus === data.accessStatus.update){
            setValues(defaultEncoder(object))
        }
        if (accessStatus === data.accessStatus.create){
            setValues(defaultEncoder(businessobject_data.kundenprojekt.default))
        }
    }, [object, id])
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [error, errorMsg])
    // get object from list by id, when id changes
    useEffect(() => {
        if(accessStatus !== data.accessStatus.create){getList()};
        if(accessStatus === data.accessStatus.update && list){
            object = list.find(o => o.id === parseInt(id))
        }
    }, [id])

    // open PopUpto to Confirm on Function call
    const onDelete = () => {setShowDeletePopUp(true)}
    const onLock = () => {setShowLockPopUp(true)}
    // close PopUp and do api call
    const onDelete_sure = () => {
        setShowDeletePopUp(false);
        sendDelete(id);
    }
    const onLock_sure = () => {
        setShowLockPopUp(false)
        values.status = (object.status === data.status.GESPERRT ? data.status.FREIGABE_QUALITAETSSICHERUNG : data.status.GESPERRT)
        onSave()
    }
    // validate and copy the object to save it via API call
    const onSave = () => {
        if (!validateValues(setError, values, failedValidation, setFailedValidation)){return;}
        let files2delete = []
        if (object.dateien){
            for (let file of object.dateien){
                if (!values.dateien.find(f => f.id === file.id)){
                    files2delete.push(file.id)
                }
            }
        }

        for (let key of Object.keys(values)){
            if (!!values[key] || (!!object[key] && !values[key])){
                if (Array.isArray(values[key])){
                    //filter default values out of the array
                    let tempValues = arrayDefaultFilter(values[key], key, businessObject)
                    if (tempValues.length > 0 || (object[key] && object[key].length > 0)){
                        object[key] =  tempValues
                    }
                } else {
                    object[key] = values[key]
                }
            }
        }

        if(onSelect){
            if (onSelectProperty){
                onSelect(object[onSelectProperty])
            } else {onSelect(object)}
        } else {
            sendUpdate(object)
        }
        if (object.dateien){
            for (let file of object.dateien){
                if(!equals(file,businessobject_data.datei.default) && !file.id && file.dateiData){
                    sendCreateFile(object.id, file)
                }
            }
        }
        for (let id of files2delete){
            sendDeleteFile(object.id, id);
        }
        
    }
    // lock the Object and create it
    const onCreateLock = () => {
        values.status = data.status.GESPERRT
        onCreate()
    }
    // validate and create the object via API call
    const onCreate = () => {
        if (!validateValues(setError, values, failedValidation, setFailedValidation)){return;}
        object = {}
        for (let key of Object.keys(values)){
            if (key !== 'dateien' && ((!!values[key] && !equals(values[key], businessobject_data[businessObject].default[key])) || (businessobject_data.types[key] && ((typeof businessobject_data.types[key].notnull === 'boolean' && businessobject_data.types[key].notnull) || (Array.isArray(businessobject_data.types[key].notnull) && businessobject_data.types[key].notnull.includes(businessObject)))))){
                if (Array.isArray(values[key])){
                    //filter default values out of the array
                    let tempValues = arrayDefaultFilter(values[key], key, businessObject)
                    if (tempValues.length > 0){
                        object[key] =  tempValues
                    }
                } else {
                    object[key] = values[key]
                }
            }
        }
        sendCreate(object)
        
        if(onSelect){
            if (onSelectProperty){
                onSelect(object[onSelectProperty])
            } else {onSelect(object)}
        }
    }

    // after creating or updating the object, save files and images via API call
    useEffect(() => {
        if (createdId){
            let furtherUploads = false;
            if (!equals(values.dateien,businessobject_data.berater.default.dateien) && values.dateien){
                for (let file of values.dateien){
                    if(!equals(file, businessobject_data.datei.default) && !file.id && file.dateiData){
                        furtherUploads = true;
                        sendCreateFile(createdId, file)
                    }
                }
            }
            
            if (!furtherUploads) {
                setfurtherSuccess();
            }
        }
    },[createdId])

    // download a file
    const onFileDownload = (fileId) => {
        if(accessStatus===data.accessStatus.update && fileId){
            sendFileDownload(object.id, fileId)
        }
    }
    // load functions to get keys and options data
    const optionsDataByType = optionsDataByTypeInit(competenceData, fieldData2options(fieldData))
    const getOptions = getOptionsInit(optionsDataByType)


    var tabs
    var fields
    tabs = Object.keys(businessobject_data[businessObject].fields)
    fields = businessobject_data[businessObject].fields[tab]

    // return inputfield for each key by type
    //load options for the input if needed
    function returnInput(type, index, key, presentation){
        if (type){
            switch(type.type){
                case data.inputTypes.advanced.DROPDOWN:
                    let optionsDrop = optionsDataByType(key)
                    return <DropdownInput key={index} multiSelect={!!type.multiSelect} value={values[key]} presentation={presentation} name={key} readOnly={checkReadOnly(key, readOnly, accessStatus)} setValue={v => setValues({...values, [key]: v})} options={optionsDrop} notNull={((typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject)))} />
                case data.inputTypes.advanced.FILE:
                    return <FileInput key={index} picture={!!type.picture} requestedFile={requestedFile} downloadFile={onFileDownload} multiple={!!type.multiSelect} dateitypOptions={optionsDataByType('dateityp')} presentation={presentation} field={key} readOnly={checkReadOnly(key, readOnly, accessStatus)} value={values[key]} setValue={v => setValues({...values, [key]: v})}  />
                case data.inputTypes.advanced.OBJECTFK:
                    function getList4ObjectFK(){
                        switch(type.businessObject){
                            case data.businessObject.customer:
                                return lists.customers
                            case data.businessObject.contactperson:
                                return (optionsFilter(lists.customers, 'id', values.kundeId)[0] ? optionsFilter(lists.customers, 'id', values.kundeId)[0].ansprechpartner : undefined)
                            case data.businessObject.consultant:
                                return lists.consultants
                            default:
                                return []
                        }
                    }
                    return <ObjectFKInput key={index} name={key} readOnly={checkReadOnly(key, readOnly, accessStatus)} presentation={presentation} value={values[key]} setValue={v => setValues({...values, [key]: v})} multiSelect={type.multiSelect} create={type.create} objectList={getList4ObjectFK()} businessObject={type.businessObject} valueProperty={type.selectProperty} presentationProperty={(type.presentationProperty ? type.presentationProperty : type.selectProperty)} filter={((type.filterBy && type.filterValue) ? {attr: type.filterBy, value: type.filterValue} : null)} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null))} notNull={((typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject)))}/>
                default:
                    return <InputByType key={index} passKey={index} type={type.type} asNumber={type.asNumber} readOnly={checkReadOnly(key, readOnly, accessStatus)} value={values[key]} name={key} presentation={presentation} setValue={v => setValues({...values, [key]: v})} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null))} notNull={((typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject)))} />
            }
        } else {
            return <TextInput key={index} readOnly={type ? checkReadOnly(key, readOnly, accessStatus) : readOnly} value={values[key]} name={key} presentation={presentation} setValue={v => setValues({...values, [key]: v})} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null))} notNull={(businessobject_data.types[key] && ((typeof businessobject_data.types[key].notnull === 'boolean' && businessobject_data.types[key].notnull) || (Array.isArray(businessobject_data.types[key].notnull) && businessobject_data.types[key].notnull.includes(businessObject))))} />
        }
    }

    if (Object.keys(values).length === 0 && values.constructor === Object){
        return (
            <div className="content" id="customer">
                <PflegenKopfzeile_Kundenprojekt accessStatus={accessStatus} readOnly={true} businessObjectFunctions={{onDelete, onLock, onSave, onCreate, onCreateLock}} setTab={setTab} tabs={tabs} tab={tab} />
                <div className="inner-content">
                    {errorMsg && <div id="server-error"> <br /> <strong className="error">{errorMsg}</strong></div>}
                    <div className="not-found">
                        <div>Der Kunde <b>{id}</b> wurde nicht gefunden.</div>
                    </div>
                </div>
                <PflegenFusszeile accessStatus={accessStatus} modDate={'00.00.0000 00:00'} />
            </div>
        )
    } else {
        return(
            <div className="content">
                <PflegenKopfzeile_Kundenprojekt id={id} role={role} loggedInUsername={username} accessStatus={accessStatus} businessObject={data.businessObject.contactperson} object={values} readOnly={readOnly} somethingChanges={somethingChanges(accessStatus, values, object, businessObject)} businessObjectFunctions={{onDelete, onLock, onSave, onCreate, onCreateLock}} setTab={setTab} tabs={Object.keys(businessobject_data.ansprechpartner.fields)} tab={tab} />
                <div className="inner-content">
                {(fieldDataError && !errorMsg) && <div><strong className="error">{fieldDataError}</strong><br /> <br /> </div>}
                {errorMsg && <div id="server-error"><strong className="error">{errorMsg}</strong></div>}
                {showDeletePopUp ? <PopUp title='Sind Sie sich sicher?' text={<div>Sie möchten das Kundenprojekt, <strong>{id}</strong> löschen</div>} close={onDelete_sure} abort={() => setShowDeletePopUp(false)} closeText="Löschen" /> : null}
                {showLockPopUp ? <PopUp title='Sind Sie sich sicher?' text={<div>Sie möchten das Kundenprojekt, <strong>{id}</strong> {(values.status === data.status.GESPERRT ? "Entsperren" : "Sperren")}</div>} close={onLock_sure} abort={() => setShowLockPopUp(false)} closeText={(values.status === data.status.GESPERRT ? "Entsperren" : "Sperren")} /> : null}
                {accessStatus === data.accessStatus.create && <h3>Neues Kundenprojekt anlegen</h3>}
                {error && <div id="client-error"> <br /> <small className="error">{error}</small></div>}
                <HandleMainInputs fields={fields} tab={tab} businessObject={businessObject} returnInput={returnInput} />
                </div>
                <PflegenFusszeile accessStatus={accessStatus} modDate={(object ? object.modDate : '00.00.0000 00:00')} />
            </div>
        )
    }
}

/**
 * Weist den verwendeten lokalen Variablen, variablen aus dem State zu
 * 
 * @param {*} state - aktueller State
 * @param {*} ownProps - weitere Variablen
 * @returns den aktuellen State als Komponentenvariablen
 */
 const mapStateToProps = (state, ownProps) => ({
    role: state.user.role,
    username: state.user.username,
    createdId: state.customerprojects.createdId,
    errorMsg: state.businessobjects.error,
    successMsg: state.businessobjects.success,
    list: state.customerprojects.list,
    lists: {
        consultants: state.consultants.list,
        customers: state.customers.list
    },
    competenceData: state.competences.list,
    fieldData: state.fielddata.data,
    fieldDataError: state.fielddata.error,
    requestedFile: state.customerprojects.requestedFile,

    ...ownProps
})

/**
 * Weist den verwendeten lokalen Funktionen, Funktionen aus dem Redux zu
 * 
 * @returns Die gemappten Funktionen
 */
const mapDispatchToProps = dispatch => ({
    getList: () => dispatch(sendGetCustomerprojectListRequest()),
    sendDelete: (id) => dispatch(sendDeleteCustomerprojectRequest(id)),
    sendUpdate: (customer) => dispatch(sendUpdateCustomerprojectRequest(customer)),
    sendCreate: (customer) => dispatch(sendCreateCustomerprojectRequest(customer)),
    sendCreateFile: (id, file) => dispatch(sendCreateCustomerprojectFileRequest(id, file)),
    sendFileDownload: (id, fileId) => dispatch(sendCustomerprojectFileDownload(id, fileId)),
    sendDeleteFile: (id, fileId) => dispatch(sendDeleteCustomerprojectFileRequest(id, fileId)),
    setfurtherSuccess: () => dispatch(setSuccess())
}) 

export default connect(mapStateToProps, mapDispatchToProps)(Pflegen_Kundenprojekt)