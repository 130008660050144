import React from 'react'

import logo from '../styles/BPC_Bild_Wortmarke.svg'

/**
 * Fußzeilen-Componente ohne Redux (asynchrone APIAnfragen, Abhängikeiten zum Store)
 * nach dem Login auf jeder Seite
 * 
 * zunächst Platzhalter für gegebenenfalls Impressum o.ä.
 * 
 * @author DHR
 * @returns {HTML} Footer
 */
function Footer({extClass}){


    return(
        <div className={"footer"+(extClass ? " "+extClass : "")}>
            <hr />
            <div className="logo"><a className="logo-link" href="https://www.beckmann-partner.de"><img src={logo} alt="BPC_Logo"/></a></div>
            <div id="triangle"></div>
            <div className="footer-end">
            <div className='legal-infos'>
                    <p>Ein Angebot der <a href='https://www.beckmann-partner.de/'>Beckmann & Partner CONSULT GmbH</a><br /></p>
                    <p>
                        Meisenstraße 79<br/>
                        33607 Bielefeld<br/>
                        Tel: 0521 25290 0<br/>
                        Fax: 0521 25290 499<br/>
                        <a href='mailto:hallo@beckmann-partner.de'>hallo@beckmann-partner.de</a>
                    </p>
                    <div className='legal-link'><a href='https://www.beckmann-partner.de/kontakt'>Kontakt</a></div>
                    <div className='legal-link'><a href='https://www.beckmann-partner.de/impressum'>Impressum</a></div>
                    <div className='legal-link'><a href='https://www.beckmann-partner.de/datenschutzerklaerung'>Datenschutzerklärung</a></div>
                </div>
            </div>
        </div>
    )
}
export default Footer