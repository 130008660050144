import { SERVER, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_REQUEST_START, BUSINESSOBJECT_REQUEST_ERROR, BUSINESSOBJECT_RESPONSE_ERROR, BUSINESSOBJECT_SUCCESS, SET_COMPETENCES, COMPETENCE_PM_C_SUCCESS, COMPETENCE_PM_Q_SUCCESS } from "./constants"
import { getApiRequestBuilder } from "./util"
import {setSuccess} from './businessobjects'

import message_data from '../data/message_data'

/**
 * Redux API um Kompetenzen am Backend zu verwalten
 * 
 * @author DHR
 */
// build the requestBuilder with the Failure types for the Reducer
const requestBuilder = getApiRequestBuilder(BUSINESSOBJECT_REQUEST_START, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_REQUEST_ERROR, BUSINESSOBJECT_RESPONSE_ERROR)
export const requestURL_get = SERVER + "kompetenz"
const requestURL_create = SERVER + ""
const requestURL_delete = SERVER + ""
const requestURL_update = SERVER + ""
const requestURL_PMCreateC = SERVER + "kompetenz/erstellungsanfrage"
const requestURL_PMCreateQ = SERVER + "qualifikation/erstellungsanfrage"

/**
 * Setzt das Json Objekt der Response als Liste aller Kompetenzen
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben.
 * 
 * @param {Object} jsonData Responsejson.data
 * @returns action(type,...payload) zum aktualisieren des States
 */
 const setList = (jsonData) => ({type: SET_COMPETENCES, ...jsonData})


/**
 * Sendet Request um die Liste aller Kompetenzen zu erhalten
 * 
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
 export function sendGetCompetenceListRequest(){
    
    return (requestBuilder(
        (requestURL_get),
        {
        method: 'GET', 
        headers: { credentials: "same-origin", 'Accept': 'application/json'},
        }, setList)
    )
}
/**
 * Sendet Request um eine Kompetenz zu löschen
 * 
 * @param {String} id - id der zu löschenden Kompetenz
 * @returns Redux Action
 */
 export function sendDeleteCompetenceRequest(id, category){
    return(requestBuilder(
        (requestURL_delete + category + '/' +id), 
        {
            method: 'DELETE', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' }
        }, setSuccess)
    )
}

/**
 * Sendet Request um Attribute der Kompetenz neu zusetzen
 * 
 * @param {Object} competence - Objekt der Kompetenz
 * @returns Redux Action
 */
 export function sendUpdateCompetenceRequest(competence, category){
    return(requestBuilder(
        (requestURL_update + category + '/' + competence.id), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(competence)
        }, setSuccess)
    )
}

/**
 * Sendet Request um eine Kompetenz anzulegen
 * 
 * @param {Object} competence - Objekt der Kompetenz
 * @returns Redux Action
 */
 export function sendCreateCompetenceRequest(competence, category){
    return(requestBuilder(
        (requestURL_create + category), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(competence)
        }, setSuccess)
    )
}

/**
 * Setzt die angelegte Kompetenz in den State
 * 
 * @param {Object} competence - Objekt der Kompetenz
 * @returns action(type) zum aktualisieren des States
 */
const setSuccessPMCompetence = (competence) => ({type: COMPETENCE_PM_C_SUCCESS, data:competence})

/**
 * Sendet einen Request zum Anlegen einer Kompetenz
 * Aus Sicht des Projektberaters nur mit FreigabeMA
 * 
 * @param {Object} competence - Objekt der Kompetenz
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendCreatePMCompetenceRequest(competence){
    return(requestBuilder(
        (requestURL_PMCreateC), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(competence)
        }, (location) => setSuccessPMCompetence({...competence, kompetenzId:parseInt(location.slice(location.lastIndexOf('/')+1))}))
    )
}

/**
 * Setzt die angelegte Qualifiaktion in den State
 * 
 * @param {Object} qualification - Objekt der Qualifikation
 * @returns action(type) zum aktualisieren des States
 */
const setSuccessPMQualification = (qualification) => ({type: COMPETENCE_PM_Q_SUCCESS, data:qualification})

/**
 * Sendet einen Request zum Anlegen einer Qualifikation
 * Aus Sicht des Projektberaters nur mit FreigabeMA
 * 
 * @param {Object} qualification - Objekt der Qualifikation
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendCreatePMQualificationRequest(qualification){
    return(requestBuilder(
        (requestURL_PMCreateQ), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(qualification)
        }, (location) => setSuccessPMQualification({...qualification, qualifikationId:parseInt(location.slice(location.lastIndexOf('/')+1))}))
    )
}

/**
 * Zugriff auf den aktuellen State und erweitern bzw. modifizieren dessen
 * Reducer by action.type
 * 
 * @see constants.js
 * @param {*} state - takes the currentstate
 * @param {*} action - takes some type and some values as payload
 * @returns the new state 
 */
 export const competences = (state = { }, { type, ...payload }) => {
    switch (type) {
        case SET_COMPETENCES:
            return {
                ...state, 
                list: payload,
                competencePMCompetenceRequest: null,
                competencePMQualificationRequest: null
            }
        case COMPETENCE_PM_Q_SUCCESS:
            return {
                ...state,
                competencePMQualificationRequest: payload.data
            }
        case COMPETENCE_PM_C_SUCCESS:
            return {
                ...state,
                competencePMCompetenceRequest: payload.data
            }
        default:
            return state
    }
}