import { SERVER, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_REQUEST_START, BUSINESSOBJECT_REQUEST_ERROR, BUSINESSOBJECT_RESPONSE_ERROR, BUSINESSOBJECT_SUCCESS, SET_CUSTOMERPROJECTS, SET_CUSTOMERPROJECT_FILE, CUSTOMERPROJECT_CREATE_SUCCESS } from "./constants"
import { getApiRequestBuilder } from "./util"
import {setSuccess} from './businessobjects'

import message_data from '../data/message_data'

/**
 * Redux API um die Kundenprojekte am Backend zu verwalten
 * 
 * @author DHR
 */
// build the requestBuilder with the Failure types for the Reducer
const requestBuilder = getApiRequestBuilder(BUSINESSOBJECT_REQUEST_START, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_REQUEST_ERROR, BUSINESSOBJECT_RESPONSE_ERROR)
export const requestURL_get = SERVER + "kundenprojekt"
const requestURL_create = SERVER + "kundenprojekt"
const requestURL_delete = SERVER + "kundenprojekt"
const requestURL_update = SERVER + "kundenprojekt"

/**
 * Setzt den Success des Anlegen eines Kundenprojekts im State anhand der id
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben
 * 
 * @returns action(type) zum aktualisieren des States
 */
 const setCreateSuccess = (id) => ({type: CUSTOMERPROJECT_CREATE_SUCCESS, id})

 /**
 * Setzt das Json Objekt der Response als Liste aller Kundenprojekte
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben
 * 
 * @param {Object} jsonData - Responsejson.data
 * @returns action(type,...payload) zum aktualisieren des States
 */
  const setCustomerprojectList = (jsonData) => ({type: SET_CUSTOMERPROJECTS, list:jsonData})

/**
 * Sendet Request um die Liste des jeweiligen Geschäftsobjektes zu erhalten
 * 
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
 export function sendGetCustomerprojectListRequest(){
    
    return (requestBuilder(
        (requestURL_get),
        {
        method: 'GET', 
        headers: { credentials: "same-origin", 'Accept': 'application/json'},
        }, setCustomerprojectList)
    )
}

/**
 * Setzt das Json Objekt der Response als Datei zum Kundenprojekt im State
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben
 * 
 * @param {String} filename - name der erhaltenen Datei
 * @param {Object} data - die erhaltene Datei
 * @param {int} id - id des Kundenprojekt
 * @returns action(type) zum aktualisieren des States
 */
const setCustomerprojectFile = (filename, data, id) => ({type: SET_CUSTOMERPROJECT_FILE, id, filename, data})

/**
 * Sendet Request um die Datei zum Kundenprojekt zu erhalten
 * 
 * @param {int} id - id des Kundenprojekt
 * @param {int} fileId - id der anzufragenden Datei
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendCustomerprojectFileDownload(id, fileId){
    return(requestBuilder(
        (requestURL_create + '/' + id + '/' + 'datei/'+fileId), 
        {
            method: 'GET', 
            headers: { credentials: "same-origin", 'Accept': 'application/octet-stream' },
        }, (filename, data) => (setCustomerprojectFile(filename, data, id)))
    )
}


/**
 * Sendet Request um das Kundenprojekt zu löschen
 * 
 * @param {int} id - id des Kundenprojekt
 * @returns Redux Action
 */
 export function sendDeleteCustomerprojectRequest(id){
    return(requestBuilder(
        (requestURL_delete + '/'+id), 
        {
            method: 'DELETE', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' }
        }, setSuccess)
    )
}

/**
 * Sendet Request zum Löschen von Dateien eines Kundenprojekt
 * 
 * @param {int} id - id des Kundenprojekt
 * @param {int} fileId - id der zulöschenden Datei
 * @returns Redux Action
 */
export function sendDeleteCustomerprojectFileRequest(id, fileId){
    return(requestBuilder(
        (requestURL_delete + '/'+ id  + '/datei/'+ fileId), 
        {
            method: 'DELETE', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' }
        }, setSuccess)
    )
}
/**
 * Sendet Request um Attribute des Kundenprojekt neu zusetzen
 * 
 * @param {Object} customerproject - Objekt des Kundenprojektes
 * @returns Redux Action
 */
 export function sendUpdateCustomerprojectRequest(customerproject){
    return(requestBuilder(
        (requestURL_update + '/' + customerproject.id), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(customerproject)
        }, setSuccess)
    )
}
/**
 * Sendet Request zum Anlegen des Kundenprojekt
 * 
 * @param {Object} customerproject
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
 export function sendCreateCustomerprojectRequest(customerproject){
    return(requestBuilder(
        (requestURL_create), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(customerproject)
        }, (location) => (setCreateSuccess(location.slice(location.lastIndexOf('/')+1))))
    )
}

/**
 * Übergibt die Metadaten der neuangelegten Datei der Funktion zum Upload der Datei
 * 
 * @param {int} id - id des Kundenprojekt
 * @param {Object} file - Daten der Datei
 * @param {int} fileId - id der Datei
 * @returns action(type) zum aktualisieren des States vom Upload
 */
const setFileMetaData = (id, file, fileId) => {return sendFileUpload(id, file.dateiData, fileId)}

/**
 * Sendet Request zum Anlegen von Metadaten einer Datei zu einem Kundenprojekt
 * 
 * @param {int} id - id des Kundenprojekt
 * @param {int} file - Daten der Datei
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendCreateCustomerprojectFileRequest(id, file){
    let uFile = {...file}
    delete uFile.dateiData
    return(requestBuilder(
        (requestURL_create + '/' + id + '/datei'), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(uFile)
        }, (location) => (setFileMetaData(id, file, location.slice(location.lastIndexOf('/')+1))))
    )
}

/**
 * Sendet Request zum Hochladen der Dateidaten zu den entsprechend angelegten Metadaten
 * 
 * @param {int} id - id des Kundenprojekt
 * @param {Object} file - Daten der Datei
 * @param {int} fileId - id der Datei
 * @returns @returns Redux Action
 */
export function sendFileUpload(id, file, fileId){
    let uFile = new FormData()
    uFile.append('file', file)
    return(requestBuilder(
        (requestURL_create + '/' + id + '/' + 'datei/'+fileId), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin" }, //'Content-Type': 'multipart/form-data'
            body: uFile
        }, setSuccess)
    )
}

/**
 * Zugriff auf den aktuellen State und erweitern bzw. modifizieren dessen
 * Reducer by action.type
 * 
 * @see constants.js
 * @param {*} state - takes the currentstate
 * @param {*} action - takes some type and some values as payload
 * @returns the new state 
 */
 export const customerprojects = (state = { }, { type, ...payload }) => {
    switch (type) {
        case SET_CUSTOMERPROJECTS:
            return {
                ...state, 
                list: payload.list,
                createdId: 0
            }
        case SET_CUSTOMERPROJECT_FILE:
            return {
                ...state,
                requestedFile: {data: payload.data, filename: payload.filename, id: payload.id}
            }
        case CUSTOMERPROJECT_CREATE_SUCCESS:
            return {
                ...state,
                createdId: payload.id
            }
        default:
            return state
    }
}