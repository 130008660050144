import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripVertical } from '@fortawesome/free-solid-svg-icons'


/**
 * Rendert einen Listen Eintrag, Möglichkeit das jeweilige Objekt zu Bearbeiten
 * 
 * @author DHR
 * @param {int} columnCount - anzahl der Spalten der Liste
 * @param {int} id - aktuelle ID des ausgewählte Geschäftsobjektes
 * @param {Object} rowData - Daten des Listenelements
 * @returns {HTML} Element in der SearchList
 */
const SearchListElement = ({readOnly=false, columnCount, dragable, provided, id, rowData, onEdit, onEditLook, onSelect, secondaryStyle, excludeFromSelect}) => {
    if (onSelect){readOnly = true}
    return(
        <div className={"searchlist-element"+(secondaryStyle ? " locked-style" : "")} ref={(provided ? provided.innerRef : null)} {...(provided ? provided.dragHandleProps : null)} {...(provided ? provided.draggableProps : null)} >
            {dragable && <div className="searchlist-element-item" ><FontAwesomeIcon className="" icon={faGripVertical} /></div>}
            {rowData.map((value, index) => (<div key={index} onClick={() => onEdit(id)} className="searchlist-element-item" >{(typeof value == 'boolean' ? <input type="checkbox" disabled checked={value} /> : value)}</div>))}
            <div className="searchlist-element-item no-mobile" id="listButton" style={{}}>
                {(excludeFromSelect && typeof excludeFromSelect === 'string'  ? <p className="hint">{excludeFromSelect}</p> : <button className="list no-tablet no-mobile" disabled={excludeFromSelect} onClick={() => onEdit(id)}>{readOnly ? (!!onSelect ? 'Auswählen' : 'Ansehen') : 'Bearbeiten'}</button>)}
                {!readOnly && <button className="list" onClick={() => onEditLook(id)}>Ansehen</button>}
            </div>
            {(rowData.length < columnCount-1) ? <div className="searchlist-element-item no-mobile" id="placeholder" ></div> : null}
        </div>
    )
}

export default (SearchListElement)