import { SERVER, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_REQUEST_START, BUSINESSOBJECT_REQUEST_ERROR, BUSINESSOBJECT_RESPONSE_ERROR, BUSINESSOBJECT_SUCCESS, CONSULTANT_UPDATE_SUCCESS, SET_CONSULTANTS, SET_CONSULTANT, SET_CONSULTANT_FILE, SET_CONSULTANT_PIC, CONSULTANT_CREATE_SUCCESS, BUSINESSOBJECT_FILE_REQUEST_ERROR, BUSINESSOBJECT_FILE_RESPONSE_ERROR, SET_CONSULTANTS4MODERATION } from "./constants"
import { getApiRequestBuilder } from "./util"
import {setSuccess} from './businessobjects'

/**
 * Redux API um Berater am Backend zu verwalten
 * 
 * @author DHR
 */
// build the requestBuilder with the Failure types for the Reducer
const requestBuilder = getApiRequestBuilder(BUSINESSOBJECT_REQUEST_START, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_REQUEST_ERROR, BUSINESSOBJECT_RESPONSE_ERROR)
export const requestURL_get = SERVER + "berater"
const requestURL_get4Moderation = SERVER + "berater/moderation"
const requestURL_create = SERVER + "berater"
const requestURL_delete = SERVER + "berater"
const requestURL_update = SERVER + "berater"


/**
 * Setzt die Id des erstellten Berater in den State
 * 
 * @param {int} id - id des erstellten Beraters
 * @returns action(type) zum aktualisieren des States
 */
 const setCreateSuccess = (id) => ({type: CONSULTANT_CREATE_SUCCESS, id})
 /**
 * Setzt die Id des geupdateten Berater in den State
 * 
 * @param {int} id - id des geupdateten Beraters
 * @returns action(type) zum aktualisieren des States
 */
 const setUpdateSuccess = (id) => ({type: CONSULTANT_UPDATE_SUCCESS, id})

/**
 * Setzt das Json Objekt der Response als den spezifisch angefragten Berater
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben.
 * 
 * @param {Object} jsonData - Responsejson.data
 * @returns action(type,...payload) zum aktualisieren des States
 */
 const setConsultant = (id, jsonData) => ({type: SET_CONSULTANT, specific:jsonData})

 /**
 * Setzt das Json Objekt der Response als Liste aller Berater
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben.
 * 
 * @param {Object} jsonData Responsejson.data
 * @returns action(type,...payload) zum aktualisieren des States
 */
  const setConsultantList = (jsonData) => ({type: SET_CONSULTANTS, list:jsonData})
  const setConsultant4ModerationList = (jsonData) => ({type: SET_CONSULTANTS4MODERATION, list:jsonData})

/**
 * Sendet Request um die Liste des aller Berater zu erhalten
 * 
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
 export function sendGetConsultantListRequest(){
    
    return (requestBuilder(
        (requestURL_get),
        {
        method: 'GET', 
        headers: { credentials: "same-origin", 'Accept': 'application/json'},
        }, setConsultantList)
    )
}
export function sendGetConsultant4ModerationListRequest(){
    
    return (requestBuilder(
        (requestURL_get4Moderation),
        {
        method: 'GET', 
        headers: { credentials: "same-origin", 'Accept': 'application/json'},
        }, setConsultant4ModerationList)
    )
}

/**
 * Sendet Request um die Details des eindeutigen Berater zu erhalten
 * 
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
 export function sendGetConsultantRequest(id){
    return (requestBuilder(
        (requestURL_get + '/'+id),
        {
        method: 'GET', 
        headers: { credentials: "same-origin", 'Accept': 'application/json'},
        }, (j) => (setConsultant(id, j)))
    )
}

/**
 * Setzt die Daten der angefragten Datei in den State
 * 
 * @param {String} filename - name der angefragten Datei
 * @param {Object} data - die Datei
 * @param {int} id - id des Beraters
 * @param {boolean} profilepicture - gibt an ob es sich um ein Profilbild handelt
 * @returns action(type) zum aktualisieren des States
 */
const setConsultantFile = (filename, data, id, profilepicture=false) => ((profilepicture ? {type: SET_CONSULTANT_PIC, id, filename, data}  : {type: SET_CONSULTANT_FILE, id, filename, data})) //...id, ...data

/**
 * Sendet Request um eine Datei zum Berater zu erhalten
 * 
 * @param {int} id - id des Kundenprojekt
 * @param {int} fileId - id der anzufragenden Datei
 * @param {boolean} profilepicture - gibt an ob es sich um ein Profilbild handelt
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendFileDownload(id, fileId, profilepicture=false){
    return(getApiRequestBuilder(BUSINESSOBJECT_REQUEST_START, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_FILE_REQUEST_ERROR, BUSINESSOBJECT_FILE_RESPONSE_ERROR)(
        (requestURL_create + '/' + id + '/' + (profilepicture ? 'profilbild' : 'datei/'+fileId)), 
        {
            method: 'GET', 
            headers: { credentials: "same-origin", 'Accept': 'application/octet-stream' },
        }, (filename, data) => (setConsultantFile(filename, data, id, profilepicture)))
    )
}

export function clearConsultant() {
    return { type: SET_CONSULTANT, specific: {} };
}
/**
 * Sendet Request um den Benutzer zu löschen
 * 
 * @param {String} id - id des zu löschenden Beraters
 * @returns Redux Action
 */
 export function sendDeleteConsultantRequest(id){
    return(requestBuilder(
        (requestURL_delete + '/'+id), 
        {
            method: 'DELETE', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' }
        }, setSuccess)
    )
}
/**
 * Sendet Request um Attribute des Beraters neu zusetzen
 * 
 * @param {Object} consultant - Objekt des Beraters
 * @returns Redux Action
 */
 export function sendUpdateConsultantRequest(consultant){
    return(requestBuilder(
        (requestURL_update + '/' + consultant.id), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(consultant)
        }, () => setUpdateSuccess(consultant.id))
    )
}
/**
 * Sendet Request um einen Berater anzulegen
 * 
 * @param {Object} consultant - Objekt des Berater
 * @returns Redux Action
 */
 export function sendCreateConsultantRequest(consultant){
    return(requestBuilder(
        (requestURL_create), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(consultant)
        }, (location) => (setCreateSuccess(location.slice(location.lastIndexOf('/')+1))))
    )
}

/**
 * Sendet Request um eine Datei zu löschen
 * 
 * @param {int} id - id des Berater
 * @param {int} fileId - id der zulöschenden Datei
 * @returns Redux Action
 */
export function sendDeleteFileRequest(id, fileId){
    return(requestBuilder(
        (requestURL_delete + '/'+ id  + '/datei/'+ fileId), 
        {
            method: 'DELETE', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' }
        }, setSuccess)
    )
}

/**
 * Übergibt die Metadaten der neuangelegten Datei der Funktion zum Upload der Datei
 * 
 * @param {int} id - id des Berater
 * @param {Object} file - Daten der Datei
 * @param {int} fileId - id der Datei
 * @returns action(type) zum aktualisieren des States vom Upload
 */
const setFileMetaData = (id, file, fileId) => {return sendFileUpload(id, file.dateiData, false, fileId)}


/**
 * Sendet Request zum Anlegen von Metadaten einer Datei zu einem Berater
 * 
 * @param {int} id - id des Berater
 * @param {int} file - Daten der Datei
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendCreateFileRequest(id, file){
    let uFile = {...file}
    delete uFile.dateiData
    return(requestBuilder(
        (requestURL_create + '/' + id + '/datei'), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(uFile)
        }, (location) => (setFileMetaData(id, file, location.slice(location.lastIndexOf('/')+1))))
    )
}

/**
 * Sendet Request zum Hochladen der Dateidaten zu den entsprechend angelegten Metadaten
 * 
 * @param {int} id - id des Kundenprojekt
 * @param {Object} file - Daten der Datei
 * @param {int} fileId - id der Datei
 * @returns @returns Redux Action
 */
export function sendFileUpload(id, file, profilepicture, fileId){
    let uFile = new FormData()
    uFile.append('file', file)
    return(requestBuilder(
        (requestURL_create + '/' + id + '/' + (profilepicture ? 'profilbild' : 'datei/'+fileId)), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin" }, //'Content-Type': 'multipart/form-data'
            body: uFile
        }, setSuccess)
    )
}

/**
 * Zugriff auf den aktuellen State und erweitern bzw. modifizieren dessen
 * Reducer by action.type
 * 
 * @see constants.js
 * @param {*} state - takes the currentstate
 * @param {*} action - takes some type and some values as payload
 * @returns the new state 
 */
 export const consultants = (state = { }, { type, ...payload }) => {
    switch (type) {
        case SET_CONSULTANTS:
            return {
                ...state, 
                list: payload.list,
                createdId: 0
            }
        case SET_CONSULTANTS4MODERATION:
            return {
                ...state, 
                moderation: payload.list
            }
        case SET_CONSULTANT:
            return {
                ...state,
                specific: payload.specific
            }
        case SET_CONSULTANT_PIC:
            if (Array.isArray(state.specific)) {
                return {
                    ...state,
                    specific: state.specific.map((c) => {
                        if (c.id === payload.id) {
                            return {...c, profilbild: {...c.profilbild, dateiData: {data: payload.data, filename: payload.filename}}}
                        }
                        return c
                    })
                }
            }
            return {
                ...state,
                specific: {...state.specific, profilbild: {...state.specific.profilbild, dateiData: {data: payload.data, filename: payload.filename}}}
            }
        case SET_CONSULTANT_FILE:
            return {
                ...state,
                requestedFile: {data: payload.data, filename: payload.filename, id: payload.id}
            }
        case CONSULTANT_CREATE_SUCCESS:
            return {
                ...state,
                createdId: payload.id
            }
        case CONSULTANT_UPDATE_SUCCESS:
            return {
                ...state,
                createdId: payload.id
            }
        default:
            return state
    }
}