import { USER_REQUEST_FAILED, USER_REQUEST_START, USER_RESPONSE_ERROR, SET_USER, SET_USERNAME, RESET_USER, SERVER, UNSET_USER, USER_REQUEST_ERROR, REFRESH_USER, REQUIRED_USER, UNSUCCESS_REFRESH_USER, USERS_REQUEST_START, BUSINESSOBJECT_REQUEST_START, USER_IDLE_INTERVALID } from "./constants"
import { getApiRequestBuilder } from "./util"

import message_data from '../data/message_data'
import data from './../data/_data';

/**
 * Redux API um den Nutzer am Backend zu authentifizieren
 * 
 * @author DHR
 */

// build the requestBuilder with the Failure types for the Reducer
const requestBuilder = getApiRequestBuilder(USER_REQUEST_START, USER_REQUEST_FAILED, USER_REQUEST_ERROR, USER_RESPONSE_ERROR)
export const requestURL_Login = SERVER + 'benutzer/login'
const requestURL_PasswortSet = SERVER + 'benutzer/passwortErsetzen'
export const requestURL_refresh = SERVER + 'benutzer/refresh'

/**
 * Setzt den Benutzer als Eingeloggt im State
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben.
 * 
 * @param {Object} jsonData - Responsejson.data
 * @returns action(type,...payload) zum aktualisieren des States
 */
const setUser = (jsonData, benutzername) => ({type: SET_USER, ...{...jsonData, benutzername: benutzername}})

/**
 * Sende Login Request mit Nutzername und Passwort als JSON.
 * Es wird eine onSucces-Funktion 'SetUser' Übergeben
 * 
 * @param {String} Nutzername - Nutzername zum Anmelden
 * @param {String} Passwort - Passwort zum Anmelden
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendLoginRequest(benutzername, passwort){
    return(requestBuilder(
        requestURL_Login, 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({benutzername, passwort})
        }, 
        (jsonData) => setUser(jsonData, benutzername))
    )
}

/**
 * Sende Login Request mit Nutzername und Passwort als JSON.
 * Es wird eine onSucces-Funktion 'refreshUser' Übergeben
 * 
 * @param {String} Nutzername - Nutzername zum Anmelden
 * @param {String} Passwort - Passwort zum Anmelden
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendLoginRefreshRequest(benutzername, passwort){
    return(requestBuilder(
        requestURL_Login, 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({benutzername, passwort})
        }, 
        refreshUser)
    )
}

/**
 * Setzt die logoutTime im State neu
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben.
 * 
 * @returns action(type,...payload) zum aktualisieren des States
 */
const refreshUser = () => ({type: REFRESH_USER})

/**
 * Sende Request mit neuem Passwort als JSON
 * 
 * @param {String} passwort - Passwort zum neu setzen, schon validiert
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendPasswortSetRequest(passwort){
    return(requestBuilder(
        requestURL_PasswortSet,
        {
            method: 'POST',
            headers: { credentials: "same-origin", 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({passwort})
        },resetUser)
    )
}

/**
 * Setzt die Id des aktuellen Idle Intervals im State
 * 
 * @param {int} intervalId - id des Intervalls
 * @returns action(type) zum aktualisieren des States
 */
export const setIdleTimerIntervalId = (intervalId) => {return {type: USER_IDLE_INTERVALID, intervalId}}

/**
 * Setzt temporyPassword vom Nutzer auf false
 * 
 * @returns action(type) zum aktualisieren des States
 */
const resetUser = () => ({type: RESET_USER})

/**
 * Logout des Nutzers
 * 
 * @returns action(type) zum aktualisieren des States
 */
export const unsetUser = () => ({type: UNSET_USER})

/**
 * Zugriff auf den aktuellen State und erweitern bzw. modifizieren dessen
 * Reducer by action.type
 * 
 * @see constants.js
 * @param {*} state - takes the currentstate
 * @param {*} action - takes some type and some values as payload
 * @returns the new state 
 */
export const user = (state = { }, { type, ...payload }) => {
    switch (type) {
        case SET_USER:
            return {
                ...state,
                username: payload.benutzername,
                role: payload.rolle,
                mandantId: payload.mandantId,
                temporaryPasswort: !!payload.einmalPasswort,
                logoutTime: new Date().getTime() + data.logoutTimer.regular,
                error: ""
            }
        case SET_USERNAME:
            return {
                ...state,
                username: payload.benutzername
            }
        case USER_REQUEST_FAILED:
            return {
                error: message_data.error.redux.default
            }
        case USER_REQUEST_ERROR:
            if (payload.errorMessage.responseStatusCode === 401){
                return {error: message_data.error.redux.user.status401}
            }
            return {
                error: message_data.error.redux.default + ' - ' + payload.errorMessage.responseStatusCode + ': ' + payload.errorMessage.responseStatusText
            }
        case UNSET_USER:
            if (payload.errorMessage){
                return {error: message_data.error.redux.user.unsetUser + ': ' +  payload.errorMessage}
            }
            return {
                error: message_data.error.redux.user.unsetUser
            }
        case USER_RESPONSE_ERROR:
            return {
                error: payload.errorMessage
            }
        case RESET_USER:
            return{
                ...state,
                temporaryPasswort: false
            }
        case REFRESH_USER:
            return{
                ...state,
                logoutTime: new Date().getTime() + data.logoutTimer.regular,
                lastInvocation: new Date().getTime()
            }
        case UNSUCCESS_REFRESH_USER:
            return{
                ...state,
                lastInvocation: new Date().getTime()
            }
        case REQUIRED_USER:
            return{
                ...state,
                logoutTime: new Date().getTime(),
                error: message_data.error.redux.unauthorized
            }
        case USER_IDLE_INTERVALID:
            return{
                ...state,
                currentIdleIntervalId: payload.intervalId
            }
        default:
            return state
    }
}