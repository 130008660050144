import { requestURL_refresh } from './user'
import { UNSET_USER, REFRESH_USER, UNSUCCESS_REFRESH_USER, USERS_REQUEST_START, BUSINESSOBJECT_REQUEST_START } from './constants'
import data from '../data/_data'

let logoutTime = null;
let lastInvocation = null;

/**
 * Speichert die Werte von logoutTime und lastInvocation beim Beginn jedes API Request lokal zwischen
 * Ruft die Verlängerung der Authetifizierung auf
 * 
 * @param {*} store - der Store (State) der Anwendung
 */
export const refresher = store => next => action => {
    const { type, ...payload } = action
    next(action)
    if (type === USERS_REQUEST_START || type === BUSINESSOBJECT_REQUEST_START){
        const tempStore = store.getState()
        logoutTime = tempStore.user.logoutTime
        lastInvocation = tempStore.user.lastInvocation
        store.dispatch(refreshUserRequest())
    }
}

/**
 * Request zum erneuten setzen des AuthToken (JWT) des Nutzers
 * Request nur unter bestimmten Bedinungen, zur Entlastung der Kommunikation
 * Logout bei Fehlschlagen
 */
export const refreshUserRequest = () => dispatch => {
    var currentDate = new Date()
    var TimeLeft = logoutTime - currentDate
    var TimeSinceLastInvocation = currentDate - lastInvocation
    if ((TimeLeft < (data.logoutTimer.regular - 60000)) && (TimeSinceLastInvocation ? TimeSinceLastInvocation > 30000 : true)){
        fetch(requestURL_refresh, {method: 'POST',headers: { credentials: "same-origin", 'Accept': 'application/json', 'Content-Type': 'application/json' },body: {} })
        .then(response => { 
            console.log('benutzer/refresh:status' + response.status) 
            if (response.status === 200){
                dispatch({type: REFRESH_USER})
            }
            if (response.status === 401) {
                const errorMsg = " - " + response.status + response.statusText
                dispatch({type: UNSET_USER, errorMsg})
            }
        })
        .catch(error => {
            console.error(error)
            dispatch({ type: UNSUCCESS_REFRESH_USER})
        })
    }
}