import React, { useEffect, useState } from 'react'
import businessobject_data from '../../../data/businessobject_data'
import { useHistory, useLocation } from 'react-router'

import { HeaderButtons, MultiSelect, SingleSelect, Tabs } from '../../_helpers/Kopfzeile'
import data from '../../../data/_data'
import Select from 'react-select';
import { PermissionForbidden } from './../../_helpers/ReadPermission';
import { TooltipInfo } from '../../_helpers/InnerContent';


/**
 * Kopfzeilen-Komponente für die Seiten "Beraterprofil Plegen", Rendert alle folegenden Komponenten zur Einstellung der Filter- und Suchkriterien
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {String} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} accessStatus - status des Pflegens des Geschaeftsobjektes (suche, neu, gefuehrt)
 * @param {Object} category - Kompetenzkategorie zur Anzeige
 * @param {Object} id - die ID des konkreten Beraters
 * @param {Object} object - das ausgewaehlte Geschaeftsobjekt
 * @param {boolean} somethingChanges - gibt an, ob sich das Object geändert hat
 * @param {Object} businessObjectFunctions - Objekt als Sammlung der Funktionen zum Pflegen im Parent, dem Subject (e.g. Anlegen)
 * @param {String} onSelect - Funktion welche aufgerufen wird wenn Angeleggt wird, nur wnen Komponentnet direkt und nicht über das Routing aufgerufen wird
 * @param {String} onSelectCategory - Key des CategoryFilter bei onSelect
 * @param {Function} setLocked - Funktion zum setzen des Status GESPERRT in der Suche
 * @param {Function} setSearchAttr - Funktion zum setzen des Attributes der Suche
 * @param {Function} setSearchVal - Funktion zum setzen des Wertes zur Suche
 * @param {Function} setStatusFilter - Funktion zum setzen des Status zum Filtern in der Suche
 * @param {Function} setTab - Funktion zum festlegen des ausgeaehlten Reiters
 * @param {Array} tabs - zur Verfügung stehende Reiter
 * @param {String} tab - ausgewaehlter Reiter
 * @param {String} successMsg - SuccesMsg der API bei Erfolg
 * @param {String} loggedInUsername - Username des aktuell angemeldeten Benutzers
 * @param {Object} competenceFilterData - optionen fürs Dropdown zum auswaehlen zum Filtern
 * @param {Function} setSearchFKAttr - Funktion zum setzen des Attributes der Kompetenz zum Filtern in der Suche
 * @param {Function} setSearchStartDate - Funktion zum setzen des Startdatums zum Filtern in der Suche
 * @param {Function} setSearchEndDate - Funktion zum setzen des Enddatums zum Filtern in der Suche
 * @param {Function} setCategoryFilter - Funktion zum setzen der Category zum Filtern in der Suche
 * @param {Function} onFilter - Funktion zum Anwenden der gesetzten Filter in der Parent Komponente
 * @param {Function} onFilterReset - Funktion zum Zurücksetzen der Filter in der Parent Komponente
 * @Param {String} searchAttr - Filterparameter: Attribut der Objekte auf das sich das searchVal bezieht
 * @Param {String} searchVal -  Filterparameter: Value des Attributes der Objekte auf das sich searchAttr bezieht
 * @param {String} locked - Filterparameter: Gibt an, ob die Objekter den Status GESPERRT haben müssen
 * @Param {String} statusFilter - Filterparameter: Value des Status der Objekte
 * @Param {String} searchStartDate - Filterparameter: Startdatum der Objekte
 * @Param {String} searchEndDate - Filterparameter: Enddatum der Objekte
 * @param {String} categoryFilter - Kompetenzkategorie
 * @see Pflegen
 * @see Suche als Parent
 * @returns {HTML} Kopfzeile zum Filtern der Suche oder wechseln der Reiter während der Bearbeitung
 */
function PflegenKopfzeile_Kompetenz({role, accessStatus, category, somethingChanges, id, object, onSelect, readOnly, onSelectCategory, businessObjectFunctions, setLocked, locked, setSearchAttr, setSearchVal, searchAttr, searchVal, statusFilter, setStatusFilter, successMsg, loggedInUsername, competenceFilterData, categoryFilter=data.businessObject.competence, setCategoryFilter, categoryFilterData, setSearchStartDate, setSearchEndDate, searchStartDate, searchEndDate, onFilter, onFilterReset}){
    var options = []
    if (categoryFilter) {
        const searchAttributes = (businessobject_data.types[categoryFilter] && businessobject_data.types[categoryFilter].attributeGroup ? businessobject_data[businessobject_data.types[categoryFilter].attributeGroup].searchAttributes : businessobject_data[categoryFilter].searchAttributes)
        for (var value of Object.keys(searchAttributes)){
            if (value !== 'isSynonym'){options.push({value: value, label: searchAttributes[value]})}
        }// [{value: '',label: ''}]
    }
    let history = useHistory();
    let location = useLocation();
    
    const urlQueryParams = new URLSearchParams(location.search)
    const urlQueryTab = urlQueryParams.get("tab")

    const [tab, setTab] = useState(( categoryFilter ? categoryFilter : Object.keys(categoryFilterData)[0]))
    var tabs = (categoryFilterData ? Object.keys(categoryFilterData) : null)

    history.listen((location, action) => {
        if (action === "POP" && category && accessStatus !== data.accessStatus.search){
            history.replace({
                search: '?tab='+((businessobject_data.types[category] && businessobject_data.types[category].attributeGroup)?businessobject_data.types[category].attributeGroup:category)
            })
        } else if (action === "POP" && location.search){
            history.replace({
                search: ""
            })
        }
    })

    const back2Search = () => {
        let tab = ((businessobject_data.types[category] && businessobject_data.types[category].attributeGroup)?businessobject_data.types[category].attributeGroup:category)
        history.push('/pflegen/kompetenz?tab='+tab)
    }

    useEffect(() => {
        if (urlQueryTab && accessStatus===data.accessStatus.search && categoryFilterData && urlQueryTab !== categoryFilter){
            if (Object.keys(categoryFilterData).includes(urlQueryTab)){
                setCategoryFilter(urlQueryTab)
                setTab(urlQueryTab)                
            } else {
                history.replace({
                    search: ""
                })
            }
        }
    }, [urlQueryTab])

    const setTabExt = (tab) => {history.replace({search: ""}); setTab(tab); setCategoryFilter(tab)}
    if (onSelectCategory && setCategoryFilter){setCategoryFilter(onSelectCategory)}
    useEffect(() => {if (accessStatus === data.accessStatus.search && categoryFilter){setSearchVal(''); setSearchAttr(options[0].value)}}, [tab])

    if (tab !== categoryFilter && tab === urlQueryTab){
        setCategoryFilter(urlQueryTab)
    }

    if (accessStatus === data.accessStatus.search){
        return(
            <div className="content-header" id="competence-search">
                <div className="search">
                    <div className="textlabel"><b>Suche: </b>Kompetenz</div>
                    {!onSelect && role === data.roles.BECKOFFICE && <MultiSelect cssId="statusFilter" filterState={statusFilter} setFilterState={setStatusFilter} selection={{GESPERRT: 'Gesperrt', FREIGABE_QUALITAETSSICHERUNG: 'Freigabe QS'}/*data.presentation.status*/} />}
                    
                    <div className="search-bar">
                        <Select className="dropdown" classNamePrefix="react-select" name="SearchAttr" isDisabled={!categoryFilter} options={options} isSearchable={false} defaultValue={options[0]} value={options.find(opt => {return opt.value === searchAttr})} placeholder={(readOnly ? "" : "Bitte wählen...")} onChange={s => setSearchAttr(s.value)}/>
                        {((searchAttr==='kategorie' || searchAttr==='typ' || searchAttr==='attributeType') ?
                            <Select className="dropdown" classNamePrefix="react-select" name="SearchVal" options={competenceFilterData} isSearchable={false} placeholder={(readOnly ? "" : "Bitte wählen...")} onChange={s => setSearchVal(s.value)}/>
                            :
                            <input className="search-input" type="text" name="SearchVal" disabled={!categoryFilter} value={searchVal} onChange={e=> setSearchVal(e.target.value)} onKeyDown={(event) => {if(event.key == 'Enter'){onFilter()}}} />
                        )}
                    </div>

                    <Tabs tabs={tabs} activeTab={tab} setTab={setTabExt} readOnly={!!onSelectCategory} />
                </div>
                
                <HeaderButtons main={{function: onFilter, value: 'Filter anwenden'}} second={{function: onFilterReset, value: 'Filter zurücksetzen'}} />
            </div>
        )
    } else {
        return(
            <div className="content-header">
                <div id="placeholder"></div>
                {(object&&(object.status || !object.id)) && <div className="status-stamp">{(!object.id ? 'Anlegen' : data.presentation.status[object.status])}<TooltipInfo name={(!object.id ? 'anlegen' : object.status)} /></div>}
                {accessStatus === data.accessStatus.update && <div className="sum-attributes">
                    <div className="label">Kompetenz<input disabled value={category} /></div>
                    {Object.keys(businessobject_data.kompetenz.sumAttributes).map((attr, index) => (<div key={index} className="label">{businessobject_data.kompetenz.sumAttributes[attr]}<input disabled value={object ? (attr.includes("_") ? (object[attr.substring(0,attr.indexOf("_"))]+', '+object[attr.substring(attr.indexOf("_")+1)])  : object[attr]) : ''} /></div>))}
                </div>}
                {accessStatus === data.accessStatus.create && <HeaderButtons main={{function:businessObjectFunctions.onCreate, value: 'Anlegen', tooltip: 'Legen Sie über die Checkbox "Sperren" fest, ob die Kompetenz beim Anlegen für Mitarbeiter Gesperrt sein soll oder nicht'}} disabled={(!somethingChanges ? true : readOnly)} smalls={[{function:back2Search, value: (!somethingChanges ? 'Zurück' : 'Änderungen verwerfen'), disabled: 'never'}]} />}
                {accessStatus === data.accessStatus.update && <HeaderButtons main={(object && object.status === data.status.FREIGABE_MITARBEITER ? {function: businessObjectFunctions.onRelease, value: 'Änderungen Übernehmen & Freigeben', tooltip: "Änderungen des Mitarbeiters in Freigabe Qualitätssicherung übernehmen"} : {function:businessObjectFunctions.onSave, value:'Änderungen speichern', tooltip: "Änderungen speichern und Status nicht verändern", disabled:(!somethingChanges ? true : readOnly)})} opposit={{function:() => history.push('/pflegen/kompetenz/' + category+'/' + id), value:'Bearbeiten', disabled:(PermissionForbidden(role, loggedInUsername, id, data.businessObject.competence, object)||!object)}} disabled={readOnly} smalls={[{function:businessObjectFunctions.onDelete, value:'Löschen'}, {function:back2Search, value: (!somethingChanges ? 'Zurück' : 'Änderungen verwerfen'), disabled: 'never'}]}  />}
                
            </div>
        )
    }
}

export default PflegenKopfzeile_Kompetenz