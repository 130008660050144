import React from 'react'
import data from '../data/_data'

/**
 * Fußzeile-Komponente für die Seiten "Beraterprofil Plegen", zeigt Statistiken zum Content
 * 
 * @author DHR
 * @param {String} accessStatus - status des Pflegens des Geschaeftsobjektes (suche, neu, gefuehrt)
 * @param {int} countList - Anzahl der angezeigten Elemente in der Searchlist
 * @param {String} date - aktuelles Datum zur Anzeige
 * @param {String} modDate - Datum der letzten Änderung
 * @returns {HTML} Fußzeile zu den Komponenten der Pflege
 */
function Fusszeile({accessStatus, countList, date = new Date(), modDate}){

    return(
        <div className="content-footer">
            <div className="content-footer-left">
            {(accessStatus === data.accessStatus.search ? <div>Angezeigte Ergebnisse: {countList}</div> : <div>{(accessStatus === data.accessStatus.update ? <div>Zuletzt geändert: {new Date(modDate).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}Uhr</div> : <div></div> )}</div>)}
            </div>
            <div className="content-footer-right">
                Suchergebnis vom: {new Date(date).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}Uhr
            </div>
        </div>
    )
}

export default Fusszeile