import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import businessobject_data from '../../../data/businessobject_data'

import {DateSearch, HeaderButtons, MultiSelect, Tabs} from '../../_helpers/Kopfzeile'
import data from '../../../data/_data'
import Select from 'react-select'
import {PermissionForbidden} from './../../_helpers/ReadPermission'
import {optionsFilter} from './../../_helpers/functions'
import { TooltipInfo } from '../../_helpers/InnerContent';

/**
 * Kopfzeilen-Komponente für die Seiten "Beraterprofil Plegen", Rendert alle folegenden Komponenten zur Einstellung der Filter- und Suchkriterien
 *
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {String} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} accessStatus - status des Pflegens des Geschaeftsobjektes (suche, neu, gefuehrt)
 * @param {Object} id - die ID des konkreten Beraters
 * @param {Object} object - das ausgewaehlte Geschaeftsobjekt
 * @param {boolean} somethingChanges - gibt an, ob sich das Object geändert hat
 * @param {Object} businessObjectFunctions - Objekt als Sammlung der Funktionen zum Pflegen im Parent, dem Subject (e.g. Anlegen)
 * @param {String} onSelect - Funktion welche aufgerufen wird wenn Angeleggt wird, nur wnen Komponentnet direkt und nicht über das Routing aufgerufen wird
 * @param {Function} setSearchAttr - Funktion zum setzen des Attributes der Suche
 * @param {Function} setSearchVal - Funktion zum setzen des Wertes zur Suche
 * @param {Function} setStatusFilter - Funktion zum setzen des Status zum Filtern in der Suche
 * @param {Function} setTab - Funktion zum festlegen des ausgeaehlten Reiters
 * @param {Array} tabs - zur Verfügung stehende Reiter
 * @param {String} tab - ausgewaehlter Reiter
 * @param {String} successMsg - SuccesMsg der API bei Erfolg
 * @param {String} loggedInUsername - Username des aktuell angemeldeten Benutzers
 * @param {Object} competenceFilterData - optionen fürs Dropdown zum auswaehlen zum Filtern
 * @param {Function} setSearchFKAttr - Funktion zum setzen des Attributes der Kompetenz zum Filtern in der Suche
 * @param {Function} setSearchStartDate - Funktion zum setzen des Startdatums zum Filtern in der Suche
 * @param {Function} setSearchEndDate - Funktion zum setzen des Enddatums zum Filtern in der Suche
 * @param {Function} setCategoryFilterState - Funktion zum setzen der Category zum Filtern in der Suche
 * @param {Function} onFilter - Funktion zum Anwenden der gesetzten Filter in der Parent Komponente
 * @param {Function} onFilterReset - Funktion zum Zurücksetzen der Filter in der Parent Komponente
 * @Param {String} searchAttr - Filterparameter: Attribut der Objekte auf das sich das searchVal bezieht
 * @Param {String} searchVal -  Filterparameter: Value des Attributes der Objekte auf das sich searchAttr bezieht
 * @Param {String} statusFilter - Filterparameter: Value des Status der Objekte
 * @Param {String} searchStartDate - Filterparameter: Startdatum der Objekte
 * @Param {String} searchEndDate - Filterparameter: Enddatum der Objekte
 * @see Pflegen
 * @see Suche als Parent
 * @returns {HTML} Kopfzeile zum Filtern der Suche oder wechseln der Reiter während der Bearbeitung
 */
function PflegenKopfzeile_Berater({role, id, accessStatus, readOnly, somethingChanges, object, businessObjectFunctions, onSelect, setSearchAttr, setSearchVal, searchAttr, searchVal, statusFilter, setStatusFilter, setTab, tabs, tab, successMsg, loggedInUsername, competenceFilterData, setSearchFKAttr, setSearchStartDate, setSearchEndDate, searchStartDate, searchEndDate, onFilter, onFilterReset}) {
    var options = []
    for (var value of Object.keys(businessobject_data.berater.searchAttributes)) {
        options.push({value: value, label: businessobject_data.berater.searchAttributes[value]})
    } // [{value: '',label: ''}]
    let history = useHistory()

    useEffect(() => {
        if (accessStatus === data.accessStatus.search && !searchAttr) {
            setSearchAttr(options[0].value)
        }
    })

    const getMainFunction = () => {
        if (role === data.roles.MITARBEITER) {
            return {function: businessObjectFunctions.onSavePMPrivate, value: 'Änderungen Speichern', tooltip: 'Änderungen speichern in Kopie speichern', disabled: !somethingChanges ? true : readOnly}
        } else {
            if (object && object.status === data.status.FREIGABE_MITARBEITER) {
                return {function: businessObjectFunctions.onRelease, value: 'Änderungen Übernehmen & Freigeben', disabled: readOnly, tooltip: 'Änderungen des Mitarbeiters in Freigabe Qualitätssicherung übernehmen'}
            }
            return {function: businessObjectFunctions.onSave, value: 'Änderungen Speichern', tooltip: 'Änderungen speichern und Status nicht verändern', disabled: !somethingChanges ? true : readOnly}
        }
    }
    const getSideMainFunction = () => {
        if (role === data.roles.MITARBEITER && !readOnly) {
            return {function: businessObjectFunctions.onSavePM, value: 'Änderungen Speichern & Freigeben', tooltip: 'Änderungen speichern und zur Qualiätssicherung freigeben', disabled: !somethingChanges ? true : readOnly}
        } else {
            if (object && object.status === data.status.FREIGABE_MITARBEITER) {
                return {function: businessObjectFunctions.onSavePMPrivate, value: 'Änderungen nicht übernehmen', disabled: readOnly, tooltip: "Änderungen des Mitarbeiters nicht freigeben und dem Mitarbeiter zurückgeben"}
            }
            if (object && object.status === data.status.BEARBEITUNG && object.kuerzel === loggedInUsername) {
                return {function: businessObjectFunctions.onSavePM, value: 'Änderungen Übernehmen & Freigeben', disabled: readOnly, tooltip: 'Änderungen speichern und zur Qualiätssicherung freigeben'}
            }
            return null
        }
    }

    if (accessStatus === data.accessStatus.search) {
        // Searchlist
        return (
            <div className="content-header" id="consultant-search">
                <div className="search">
                    <div className="textlabel">
                        <b>Suche: </b>Berater
                    </div>

                    <div className="fast-search">
                        <button
                            className={'micro' + (searchAttr === 'kuerzel' && searchVal === loggedInUsername ? ' clicked' : '')}
                            onClick={() => {
                                setSearchAttr('kuerzel')
                                setSearchVal(loggedInUsername)
                                onFilter('kuerzel', loggedInUsername)
                            }}>
                            Nur eigene
                        </button>
                    </div>

                    {!onSelect && role === data.roles.BECKOFFICE && <MultiSelect filterState={statusFilter} setFilterState={setStatusFilter} selection={{GESPERRT: 'Gesperrt', FREIGABE_QUALITAETSSICHERUNG: 'Freigabe QS'} /*data.presentation.status*/} />}

                    <DateSearch searchStartDate={searchStartDate} searchEndDate={searchEndDate} setSearchStartDate={setSearchStartDate} setSearchEndDate={setSearchEndDate} alternativeLabels={{start: 'Eintritt', end: 'Austritt', started: 'Aktiv', ended: 'Inaktiv'}} />

                    <div className="search-bar">
                        <Select className="dropdown" classNamePrefix="react-select" name="SearchAttr" options={options} value={optionsFilter(options, 'value', searchAttr)} isSearchable={false} defaultValue={options[0]} placeholder={readOnly ? '' : 'Bitte wählen...'} onChange={(s) => setSearchAttr(s.value)} />
                        {searchAttr === 'kompetenzen' && <Select className="dropdown" classNamePrefix="react-select" name="SearchFKAttr" options={competenceFilterData} isSearchable={false} placeholder={readOnly ? '' : 'Bitte wählen...'} onChange={(s) => setSearchFKAttr(s.value)} />}
                        <input className="search-input" type="text" name="SearchVal" value={searchVal} onChange={(e) => setSearchVal(e.target.value)} onKeyDown={(event) => {if(event.key == 'Enter'){onFilter()}}} />
                    </div>
                </div>

                {successMsg && (
                    <div className="success">
                        <strong>{successMsg}</strong>
                    </div>
                )}
                <HeaderButtons main={{function: onFilter, value: 'Filter anwenden'}} second={{function: onFilterReset, value: 'Filter zurücksetzen'}} />
            </div>
        )
    } else {
        // Edit or Create
        return (
            <div className="content-header">
                <Tabs tabs={tabs} activeTab={tab} setTab={setTab} />
                {object && (object.status || !object.id) && <div className="status-stamp">{!object.id ? 'Anlegen' : data.presentation.status[object.status]}<TooltipInfo name={(!object.id ? 'anlegen' : object.status)} /></div>}
                {accessStatus === data.accessStatus.update && (
                    <div className="sum-attributes">
                        {Object.keys(businessobject_data.berater.sumAttributes).map((attr, index) => (
                            <div key={index} className="label">
                                {businessobject_data.berater.sumAttributes[attr]}
                                <input disabled value={object ? (attr.includes('_') ? object[attr.substring(0, attr.indexOf('_'))] + ', ' + object[attr.substring(attr.indexOf('_') + 1)] : object[attr]) : ''} />
                            </div>
                        ))}
                    </div>
                )}
                {successMsg && (
                    <div className="success">
                        <strong>{successMsg}</strong>
                    </div>
                )}
                {accessStatus === data.accessStatus.create && <HeaderButtons main={{function: businessObjectFunctions.onCreate, value: 'Anlegen', tooltip: 'Anlegen und für andere freigeben'}} /*, second={{function:businessObjectFunctions.onCreateLock, value: 'Anlegen', tooltip: "Anlegen und für Mitarbeiter sperren"}}*/ disabled={!somethingChanges ? true : readOnly} smalls={[{function: () => history.push('/pflegen/berater'), value: (!somethingChanges ? 'Zurück' : 'Änderungen verwerfen'), disabled: 'never'}]} />}
                {accessStatus === data.accessStatus.update && (
                    <HeaderButtons
                        main={getMainFunction()}
                        second={getSideMainFunction()}
                        disabled={readOnly}
                        opposit={{function: () => history.push('/pflegen/berater/' + object.id), value: 'Bearbeiten', disabled: PermissionForbidden(role, loggedInUsername, id, data.businessObject.consultant, object) || !object}}
                        smalls={[role === data.roles.BECKOFFICE ? {function: businessObjectFunctions.onDelete, value: 'Löschen'} : null, {function: businessObjectFunctions.onLock, value: object ? (object.status === data.status.GESPERRT ? 'Entsperren' : 'Sperren') : 'Sperren', disabled: readOnly || role === data.roles.MITARBEITER}, {function: () => history.push('/pflegen/berater'), value: (!somethingChanges ? 'Zurück' : 'Änderungen verwerfen'), disabled: 'never'}]}
                    />
                )}
            </div>
        )
    }
}

export default PflegenKopfzeile_Berater
