/**
 * Konstanten für die actions der Reducer, Global Verfügbar
 * entspricht der types der action = {type, ...payload}
 * Konvention: 'domain/action'
 * 
 * @author DHR
 */

export const SERVER = process.env.REACT_APP_SERVER //wird durch die ENV-Variablen in der DockerCompose gesetzt, ohne Docker verwende .env File

export const USER_REQUEST_START = 'user/request'
export const USER_REQUEST_FAILED = 'user/failed'
export const USER_REQUEST_ERROR = 'user/error'
export const USER_RESPONSE_ERROR = 'user/errorMsg'
export const SET_USER = 'user/login'
export const SET_USERNAME = 'user/username'
export const RESET_USER = 'user/reset'
export const REFRESH_USER = 'user/refresh'
export const UNSUCCESS_REFRESH_USER = 'user/unsuccessRefresh'
export const REQUIRED_USER = 'user/loginRequired'
export const UNSET_USER = 'user/logout'
export const USER_IDLE_INTERVALID = 'user/idleIntervalId'

export const USERS_REQUEST_START = 'users/request'
export const USERS_REQUEST_FAILED = 'users/failed'
export const USERS_REQUEST_ERROR = 'users/error'
export const USERS_RESPONSE_ERROR = 'users/errorMsg'
export const SET_USERS = 'users/setList'
export const USERS_SUCCESS = 'users/success'
export const USERS_UNSET_SUCCESS = 'users/unsetSuccess'

export const BUSINESSOBJECT_REQUEST_START = 'businessObject/request'
export const BUSINESSOBJECT_REQUEST_FAILED = 'businessObject/failed'

export const DATA_REQUEST_START = 'data/request'
export const DATA_REQUEST_FAILED = 'data/failed'
export const DATA_REQUEST_ERROR = 'data/error'
export const DATA_RESPONSE_ERROR = 'data/errorMsg'
export const SET_DATA = 'data/setList'

export const BUSINESSOBJECT_REQUEST_ERROR = 'businessObject/error'
export const BUSINESSOBJECT_RESPONSE_ERROR = 'businessObject/errorMsg'
export const BUSINESSOBJECT_SUCCESS = 'businessObject/success'
export const BUSINESSOBJECT_UNSET_SUCCESS = 'businessObject/unsetSuccess'
export const BUSINESSOBJECT_FILE_REQUEST_ERROR = 'businessObject/file/error'
export const BUSINESSOBJECT_FILE_RESPONSE_ERROR = 'businessObject/file/errorMsg'
export const BUSINESSOBJECT_FILE_SUCCESS = 'businessObject/file/success'
export const BUSINESSOBJECT_FILE_UNSET_SUCCESS = 'businessObject/file/unsetSuccess'

export const SET_COMPETENCES = 'competences/setList'
export const COMPETENCE_PM_C_SUCCESS = 'competences/PM/competence/request'
export const COMPETENCE_PM_Q_SUCCESS = 'competences/PM/qualification/request'
export const SET_CONSULTANTS = 'consultants/setList'
export const SET_CONSULTANTS4MODERATION = 'consultants/setList/moderation'
export const SET_CONSULTANT = 'consultants/setSpecific'//consultant files and profilpic
export const SET_CONSULTANT_FILE = 'consultants/setSpecific/addFile'
export const SET_CONSULTANT_PIC = 'consultants/setSpecific/addPic'
export const CONSULTANT_CREATE_SUCCESS = 'consultants/create'
export const CONSULTANT_UPDATE_SUCCESS = 'consultants/update'
export const SET_CUSTOMERS = 'customer/setList'
export const SET_CUSTOMERPROJECTS = 'customerproject/setList'
export const SET_CONSULTANTPROJECTS = 'consultantproject/setList'
export const SET_CONSULTANTPROJECTS4MODERATION = 'consultantproject/setList/moderation'
export const SET_CONSULTANTPROJECTSDETAIL = 'consultantproject/setList/detailed'
export const SET_CONSULTANTPROJECT = 'consultantproject/setSpecific'
export const CONSULTANTPROJECT_CREATE_SUCCESS = 'consultantproject/create'
export const CONSULTANTPROJECT_UPDATE_SUCCESS = 'consultantproject/update'
export const SET_CONSULTANTPROJECT_FILE = 'consultantproject/setSpecific/addFile'
export const CUSTOMERPROJECT_CREATE_SUCCESS = 'consultantproject/create'
export const SET_CUSTOMERPROJECT_FILE = 'customerproject/setSpecific/addFile'
export const SET_LAYOUTS = 'layout/setList'
export const SET_LAYOUT = 'layout/setSpecific'
export const LAYOUT_CREATE_SUCCESS = 'layout/create'
export const SET_PROFILE = 'layout/setProfile'

export const SET_SEARCH_FILTER_QUERY = 'search/setFilterQuery'

export const SET_LOADING = 'loading/setLoading';
export const SET_LOADINGSCREEN_RESPONSE = 'loading/setLoadingScreenResponse';