import React, { useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router'

import data from '../../data/_data'
import { sendDataRequest } from '../../redux/fielddata'
import { sendGetCompetenceListRequest } from '../../redux/competences'
import Pflegen_Berater from './Berater/Pflegen_Berater'
import Pflegen_Beraterprojekt from './Beraterprojekt/Pflegen_Beraterprojekt'
import Pflegen_Kunde from './Kunde/Pflegen_Kunde'
import Pflegen_Kundenprojekt from './Kundenprojekt/Pflegen_Kundenprojekt'
import Pflegen_Kompetenz from './Kompetenz/Pflegen_Kompetenz'
import message_data from './../../data/message_data';
import NotFound from '../NotFound'
import { PermissionForbidden } from '../_helpers/ReadPermission'

/**
 * Haupt-Komponente für die Seiten "Beraterprofil Pflegen".
 * Laden der benötigten Daten und Rendern der speziellen Seite eines jeden Geschäftobjektes
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {String} username - benutzername des angemeldeten Benutzers
 * @param {String} businessObject - aktuell gewaehltes Geschaeftsobjekt
 * @param {Function} onSelect - Funktion zum setzen des ausgewählten Objektes
 * @param {String} onSelectObject - Ausgewähltes Objekt
 * @param {Function} onSelectRemove - Funktion zum Löschen eines neuen Objektes bei onSelect
 * @param {String} onSelectProperty - Key des als Fremdschlüssel zu verwendenen Objektes bei onSelect
 * @param {Object} competenceData - Daten des Geschäftsobjektes Kompetenz zur Auswahl in den Dropdown
 * @param {Object} getCompetenceData - Funktion zum aktualisieren der Kompetenzdaten an der API
 * @param {String} errorMsg - errorMsg wenn API call fehlschlägt
 * @param {String} successMsg - successMsg wenn API call erfolgreich
 * @param {Object} fieldData - geschaeftsobjekt oder 'ansprechpartner', nur wenn Komponente direkt und nicht über das Routing aufgerufen wird
 * @param {Function} getFieldData - Funktion zum request der Liste der Geschaeftsobjekte von der API
 * @returns {HTML} Seite zum Verwalten der Geschaeftsobjekte (inkl. Kopf- und Fußzeile) 
 * @see PflegenKopfzeile
 * @see PflegenFusszeile
 */
function Pflegen({role, username, successMsg, errorMsg, getFieldData, onSelect, onSelectObject, onSelectProperty, onSelectRemove, businessObject, fieldData, competenceData, getCompetenceData}) {
    const { geschaeftsobjekt, id } = useParams()
    
    const history = useHistory();
    // if the user is allowed to edit but dont want to, a url param sets the readOnly
    const readOnly = new URLSearchParams(useLocation().search).get('readOnly')
    // if an api call return successfully than the user is routed back
    useEffect(() => {if (successMsg === message_data.success.redux.default){history.push('/pflegen/'+geschaeftsobjekt)}}, [successMsg])
    // request api Data if needed
    useEffect(() => {
        if (!fieldData || !Object.keys(fieldData).length){
            getFieldData();
        }
        if (!competenceData || !Object.keys(competenceData).length){
            getCompetenceData();
        }
    }, [])

    const accessStatus = ((onSelect) ? (onSelectObject ? data.accessStatus.update : data.accessStatus.create) : ((id === 'neu') ? data.accessStatus.create : data.accessStatus.update))

    // return header according to the current businessobject
    function switchBusinessobject(cls){
        if (accessStatus === data.accessStatus.create && Object.values(data.businessObject).includes(cls)){
            if(PermissionForbidden(role, username, 'neu', cls)){
                return <NotFound notAuth={true} notFoundSite={cls} notFoundObject={'neu'} />
            }
        }
        
        switch (cls){
            case data.businessObject.consultant:
                return <Pflegen_Berater readOnly={readOnly} id={id} accessStatus={accessStatus} onSelect={onSelect} onSelectProperty={onSelectProperty} /> 
            case data.businessObject.consultantproject:
                return <Pflegen_Beraterprojekt readOnly={readOnly} id={id} accessStatus={accessStatus} onSelect={onSelect} onSelectProperty={onSelectProperty} /> 
            case data.businessObject.contactperson:
            case data.businessObject.customer:
                return <Pflegen_Kunde readOnly={readOnly} id={id} businessObject={cls} accessStatus={accessStatus} onSelect={onSelect} onSelectObject={onSelectObject} onSelectRemove={onSelectRemove} onSelectProperty={onSelectProperty} />
            case data.businessObject.customerproject:
                return <Pflegen_Kundenprojekt readOnly={readOnly} id={id} accessStatus={accessStatus} onSelect={onSelect} onSelectProperty={onSelectProperty} />
            case data.businessObject.competence:
                return <Pflegen_Kompetenz readOnly={readOnly} id={id} accessStatus={accessStatus} onSelect={onSelect} onSelectProperty={onSelectProperty} />
            default:
                return <NotFound notFoundSite={cls} notFoundObject={id} />
        }
        
    }

    businessObject = (onSelect ? businessObject : geschaeftsobjekt)

    return(
        switchBusinessobject(businessObject)
    )
}

/**
 * Weist den verwendeten lokalen Variablen, variablen aus dem State zu
 * 
 * @param {*} state - aktueller State
 * @param {*} ownProps - weitere Variablen
 * @returns den aktuellen State als Komponentenvariablen
 */
 const mapStateToProps = (state, ownProps) => ({
    role: state.user.role,
    username: state.user.username,
    errorMsg: state.businessobjects.error,
    successMsg: state.businessobjects.success,
    fieldData: state.fielddata.data,
    fieldDataError: state.fielddata.error,
    competenceData: state.competences.list,
    ...ownProps
})

/**
 * Weist den verwendeten lokalen Funktionen, Funktionen aus dem Redux zu
 * 
 * @returns Die gemappten Funktionen
 */
const mapDispatchToProps = dispatch => ({
    getFieldData: () => dispatch(sendDataRequest()),
    getCompetenceData: () => dispatch(sendGetCompetenceListRequest()),
}) 

export default connect(mapStateToProps, mapDispatchToProps)(Pflegen)