import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import AdministrationKopfzeile from './AdministrationKopfzeile'
import { sendGetUsersRequest, unsetSuccess } from '../../redux/users'
import SearchList from '../_helpers/SearchList'
import data from '../../data/_data'
import Fusszeile from '../Fusszeile'

/**
 * Komponente des Menüpunktes administration/benutzer, Liste und Suche von Benutzern zum Verwalten
 * Möglichkeit den spezifischen Benutzer auszuwählen
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers, aus dem State
 * @param {String} username - aktueller Benutzername des angemeldeten Benutzers, aus dem State
 * @param {Object} userlist - Die Liste aller Benutzer, aus dem State
 * @param {Function} getUsers - Request um Liste aller Benutzer vom Backend zu erhalten
 * @param {String} errorMsg - Error Message sollte Request fehlschlagen, aus dem State
 * @return {HTML} SearchList der Benutzer, anfrage der Benutzer von der API
 * @see users für Funktionen und Variablen aus dem State/Redux
 */
function Administration_Benutzer({role, username, unsetSuccess, userList, getUsers, errorMsg, successMsg, onSelect, onSelectProperty, onSelectCreate}){ 
    const [statusFilter, setStatusFilter] = useState(false)
    const [roleFilter, setRoleFilter] = useState(0)
    const [searchAttr, setSearchAttr] = useState('')
    const [searchVal, setSearchVal] = useState('')
    const [countUserList, setCountUserList] = useState(0)
    var date;
    //Bei Komponenten Aufruf Request ans Backend
    useEffect(() => { getUsers(); date = new Date(); }, [])

    
    if (successMsg){
        unsetSuccess()
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });

    const settings = {
        benutzer: {
            createButtonValue: "Benutzer anlegen",
            extendStyle: {display: 'none'}
        }
    }

    return(
        <div className="content">
        <AdministrationKopfzeile accessStatus={data.accessStatus.search} onSelect={onSelect} businessObject={data.businessObject.user} successMsg={successMsg} setStatusFilterState={setStatusFilter} setRoleFilterState={setRoleFilter} setSearchAttrState={setSearchAttr} setSearchValState={setSearchVal}/>
        <div className="inner-content">
            <SearchList role={role} username={username} businessObject={data.businessObject.user} settings={settings} list={userList} setCountList={setCountUserList} statusFilter={statusFilter} roleFilter={roleFilter} searchAttr={searchAttr} searchVal={searchVal} onSelect={onSelect} onSelectProperty={onSelectProperty} onSelectCreate={onSelectCreate} />
            {errorMsg && <div id="server-error"><strong className="error">{errorMsg}</strong></div>}
        </div>
        <Fusszeile accessStatus={data.accessStatus.search} countList={countUserList} date={date} />
        </div>
    )
}

/**
 * Weist den verwendeten lokalen Variablen, variablen aus dem State zu
 * 
 * @param {*} state aktueller State
 * @param {*} ownProps weitere Variablen
 * @returns den aktuellen State als Komponentenvariablen
 */
 const mapStateToProps = (state, ownProps) => ({
    role: state.user.role,
    username: state.user.username,
    userList: state.users.list,
    errorMsg: state.users.error,
    successMsg: state.users.success,
    ...ownProps
})

/**
 * Weist den verwendeten lokalen Funktionen, Funktionen aus dem Redux zu
 * 
 * @returns Die gemappten Funktionen
 */
const mapDispatchToProps = dispatch => ({
    getUsers: () => dispatch(sendGetUsersRequest()),
    unsetSuccess: () => dispatch(unsetSuccess())
}) 

export default connect(mapStateToProps, mapDispatchToProps)(Administration_Benutzer)