import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './styles/style.scss';

import { Provider } from 'react-redux';
import { store, persistedStore } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Router, Route } from 'react-router-dom';
import { history } from './history';

/**
 * generiert durch create-react-app, kleine Anpassung der Übergabe des stores, der history und des Persistent State des User-Reducer für Redux
 * Haupt-Komponente, diese wird gerendert und Rendert alle ihre Kinder
 * 
 * @author DHR
 * @param {*} store - customStore
 * @returns {HTML} welcher durch ReactDOM.render gerendert wird
 */
 const Root = ({ store }) => (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
        <Router history={history}>
          <Route path="/" component={App} />
        </Router>
      </PersistGate>
    </Provider>
)


ReactDOM.render(<Root store={store} />, document.getElementById('root'));