import {SET_LOADING, SET_LOADINGSCREEN_RESPONSE} from './constants'

export const setLoadingState = (status) => ({
    type: SET_LOADING,
    payload: status,
})

export const setLoadingScreenResponse = (status, success = false) => ({
    type: SET_LOADINGSCREEN_RESPONSE,
    payload: {status: status, success: success},
})

export const loadingReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
                // loadingScreenResponse: '',
                // loadingScreenSuccess: false,
            }
        case SET_LOADINGSCREEN_RESPONSE:
            return {
                ...state,
                loadingScreenResponse: action.payload?.status,
                loadingScreenSuccess: action.payload?.success,
            }
        default:
            return state
    }
}
