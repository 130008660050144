import React from'react'
import Select from 'react-select';

/**
 * Kombiniert einige Elemente zur Wiederverwendung in den Kopfzeilen zur Pflege und zur Administration
 * 
 * @author DHR
 */

/**
 * Generiert einige Buttons
 * Zwei normal große und einige kleine
 * Das Objekt des Button kann dabei die Attribute disabled, function und value besitzen
 * 
 * @param {Object} main - Primär Button
 * @param {Object} second - zweiter Primär Button der nur angezeigt wird wenn alle anderen deaktiviert sind
 * @param {Object} sideMain - zweiter Primär Button
 * @param {boolean} disabled - gibt an, ob alle Button deaktiviert sind
 * @param {Object} small - Liste von Button die kleiner Angezeigt werden
 * @returns {HTML} mehrere Button
 */
export const HeaderButtons = ({main, second, opposit, disabled=false, smalls}) => {
    return (
        <div className="header-buttons">
            <div className="header-buttons-small">
                {smalls && smalls.map((b, i) => {
                    if (b){
                        if (b.disabled === 'never'){return <button key={i} className='small' tooltip={(b.tooltip ? b.tooltip : null)} onClick={b.function}>{b.value}</button>}
                        else if (!disabled){
                            return <button key={i} className='small' disabled={(b.disabled ? b.disabled : disabled)} tooltip={(b.tooltip ? b.tooltip : null)} onClick={b.function}>{b.value}</button>
                        }
                    }
                })}
            </div>
            <div className="header-buttons-main">
                {(opposit && disabled) && <button id="header-buttons-opposit" tooltip={(opposit.tooltip ? opposit.tooltip : null)} disabled={(opposit.disabled ? opposit.disabled : !disabled)} onClick={opposit.function}>{opposit.value}</button>}
                {second && second.function && <button id="header-buttons-second" tooltip={(second.tooltip ? second.tooltip : null)} disabled={(second.disabled ? second.disabled : disabled)} onClick={second.function}>{second.value}</button>}
                {main && <button id="header-buttons-main" tooltip={(main.tooltip ? main.tooltip : null)} disabled={(main.disabled ? main.disabled : disabled)} onClick={main.function}>{main.value}</button>}
            </div>
        </div> 
    )

}

/**
 * Generiert Tabs zwischen denen gewechselt werden kann
 * 
 * @param {Object} tabs - Liste von Tabs
 * @param {Function} setTab - Funktion zum setzen des gewählten Tabs
 * @param {String} activeTab - gibt an, welcher Tab aktiv ist um dies zu markieren
 * @param {boolean} readOnly - gibt an, ob alle Button deaktiviert sind
 * @returns {HTML} mehrere Tabs
 */
export const Tabs = ({tabs, setTab, activeTab, readOnly}) => {
    if (!tabs){return null}
    return (
        <div className="tab-container">
            {(tabs && tabs.length>1) && tabs.map((t, index) => (<div key={index} className={(activeTab === t ? "tab-active" : "tab")+(readOnly ? " disabled" : "")} onClick={() => {if(!readOnly){setTab(t)}}} >{t}</div>))}
        </div>
    )
}

/**
 * Suche in der Kopfzeile
 * Filtern nach Kompetenzen mit entsprechender Kategorie und Wert
 * 
 * @param {String} searchValue - Titel der Kompetenz
 * @param {Function} setSearchValue - Funktion zum setzten des Filters nach dem Namen
 * @param {Function} setSearchAttrribute - Funktion zum setzten des Filters nach der Kategorie
 * @param {Object} options - Liste als optionen für React-Select
 * @returns {HTML} Dropdown zum Einstellen des Filters
 */
export const DropdownSearch = ({searchValue, setSearchValue, setSearchAttrribute, options, onFilter}) => {
    return (
        <div className="dropdown-search"> {/*KompetenzSearch */}
            <Select className="dropdown" id="selector" classNamePrefix="react-select" name="DropdownSearch_Attribute" options={options} noOptionsMessage={() => 'Keine Daten vom Server empfangen'} placeholder="Bitte wählen..." isSearchable={false} defaultValue={options[0]} onChange={s => setSearchAttrribute(s.value)}/>
            <input className="search-input" type="text" name="DropdownSearch_Value" value={searchValue} onChange={e=> setSearchValue(e.target.value)} onKeyDown={(onFilter ? (event) => {if(event.key == 'Enter'){onFilter()}} : () => {})} />
        </div>
    )
}

/**
 * Kalenderpicker zum Wählen eines Datumszeitraumes
 * 
 * @param {String} searchStartDate - Startdatum zum Filtern
 * @param {String} searchEndDate - Enddatum zum Filtern
 * @param {Function} setSearchStartDate - Funtkion zum setzen des Startdatums im Parent
 * @param {Function} setSearchEndDate - Funtkion zum setzen des Enddatums im Parent
 * @returns {HTML} Datumsselector
 */
export const DateSearch = ({searchStartDate, searchEndDate, setSearchStartDate, setSearchEndDate, readOnly, alternativeLabels}) => {

    return (
        <div className="date-search"> 
            <div className='date-search-item'>
                <div className="label">{(alternativeLabels ? alternativeLabels.start : 'Von')}</div><input name="searchStartDate" type='date' value={searchStartDate} disabled={readOnly} onChange={e => setSearchStartDate(new Date(e.target.value).toISOString().slice(0, 10))} />
                <button className="micro" disabled={readOnly} onClick={() => {setSearchStartDate(new Date(0).toISOString().slice(0, 10)); setSearchEndDate(new Date().toISOString().slice(0, 10))}} >{(alternativeLabels ? alternativeLabels.ended : 'Abgeschlossen')}</button>
            </div>
            <div className='date-search-item'>
                <div className="label">{(alternativeLabels ? alternativeLabels.end : 'Bis')}</div><input name="searchEndDate" type='date' value={searchEndDate} disabled={readOnly} onChange={e => setSearchEndDate(new Date(e.target.value).toISOString().slice(0, 10))} />
                <button className={"micro" + (searchEndDate===-1 ? ' clicked' : '')} disabled={readOnly} onClick={() => {setSearchEndDate(-1)}} >{(alternativeLabels ? alternativeLabels.started : 'Laufend')}</button>
            </div>
        </div>
    )
}

/**
 * Generiert eine Liste von Checkboxes aus denen nur ein Element ausgewählt werden kann
 * 
 * @param {String} filterState - Name der Ausgewählten Checkbox
 * @param {Function} setFilterState - Funktion zum setzen der ausgewählten Checkboxe
 * @param {Object} selection - Liste von Elementen die als Checkbox mit Label angezeigt werden sollen
 * @param {boolean} readOnly - gibt an, ob alle Elemente deaktiviert sind
 * @param {String} cssId - id des Elements für CSS
 * @returns {HTML} Checkboxes
 */
export const SingleSelect = ({cssId, filterState, setFilterState, selection, readOnly, notNull}) => {
    return (
        <div className={"checkbox-select"+(notNull ? ' required' : '')} id={cssId}> {/*RoleSelect */}
            {Object.keys(selection).map((sel, index) => (<div key={index} className="checkbox-label" ><input name={sel} type="checkbox" disabled={readOnly} checked={(filterState === sel)} onChange={e => filterState === sel ? (!notNull ? setFilterState(0) : () => {}) : setFilterState(sel) } />{selection[sel]}</div>))}
        </div>
    )
}

/**
 * Generiert eine Liste von Checkboxes
 * 
 * @param {Object} filterState - Liste der Namen der Ausgewählten Checkboxes
 * @param {Function} setFilterState - Funktion zum setzen der ausgewählten Checkboxes
 * @param {Object} selection - Liste von Elementen die als Checkbox mit Label angezeigt werden sollen
 * @param {String} cssId -id des Elements für CSS
 * @returns {HTML} Checkboxes
 */
export const MultiSelect = ({cssId, filterState, setFilterState, selection}) => {
    return(
        <div className="checkbox-select" id={cssId}> {/*StatusSelect*/}
            {Object.keys(selection).map((sel, index) => (<div key={index} className="checkbox-label"><input name={sel} type="checkbox" checked={!!(filterState[sel])} onChange={() => setFilterState({...filterState, [sel]: !filterState[sel]})} />{selection[sel]}</div>))}
        </div>
    )
}