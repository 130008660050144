import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import {sendLoginRequest, sendPasswortSetRequest} from './redux/user'
import PasswordStrengthBar from 'react-password-strength-bar';

import PopUp from './components/_helpers/PopUp'
import VersionInfo from './components/_helpers/VersionInfo';
import logo from './styles/BPC_Wortmarke.svg'
import data from './data/_data'
import message_data from './data/message_data'
import businessobject_data from './data/businessobject_data';
import Footer from './components/Footer';

/**
 * Anmeldeseite, designt unabhängig von anderen Komponenten
 * 
 * Fallback für alle Routen wenn Authentifizierung nicht gegeben
 * @see PrivateRoute
 * 
 * @author DHR
 * @param {*} history - importierte history um Pfad programmatisch zu ändern
 * @param {String} role - Variable aus dem State
 * @param {String} temporaryPasswort - Variable aus dem State
 * @param {Function} sendLogin - Funktion aus dem Redux
 * @param {Function} sendPasswortSet - Funktion aus dem Redux
 * @param {String} errorMsg - variable aus dem State
 * @returns zu renderndes HTML mit Anmeldeformular
 * @see user für Funktionen und Variablen aus dem State/Redux
 */
function Login({history, role, temporaryPasswort, sendLogin, sendPasswortSet, errorMsg}){
    //setzen der Default Variablen
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [showPopUp, setShowPopUp] = useState(false)
    const [password_wdh, setPassword_wdh] = useState('')
    const [passwordStrength, setPasswordStrength] = useState(false)

    //Hat der Benutzer schon einen ROLENAME und es ist die erstmalige Anmeldung, so wird ein PopUp gezeigt um ein neues passwort zusetzen
    useEffect(() => { 
        if (temporaryPasswort && role){ setShowPopUp(true) }
        else if (role === data.roles.QUALITAETSSICHERUNG){history.push("/moderation")}
        else if (role){history.push("/pflegen")}
    }, [temporaryPasswort])
    //Hat der Benutzer schon einen ROLENAME und es ist nicht die erstmalige Anmeldung, so wird der Nutzer zur Seite '/pflegen' weitergeleitet
    //missing dependencie Warning "history in CallBack Array, but we dont want to trigger the Effect when history changes"

    //Validiere die Eingabe und rufe die Funktion zum senden eines Requestes auf, setze neues passwort nach erstmaligen Anmelden
    const onFirstLogin = e => {
        e.preventDefault()
        if (!password || password !== password_wdh){
            setError(message_data.error.login.passwordWDH)
        } else {
            if (passwordStrength && password.match(businessobject_data.types.passwort.regex)){
                sendPasswortSet(password)
            
                setShowPopUp(false)
                setPassword('')
                setPassword_wdh('')
                setError('')    
            } else {
                setError(message_data.error.login.passwordSecure)
            }
        }        
    }

    //Validiere die Eingabe und rufe die Funktion zum senden eines Requestes auf, Anmelden
    const onSubmit = e => {
        e.preventDefault()
        if (username && password){
            sendLogin(username, password)

            setUsername('')
            setPassword('')
        }
    }

    return(
        <div className="loginpage">
            <div className="header">
                <div className="logo"><a className="logo-link" href="https://www.beckmann-partner.de"><img src={logo} alt="BPC_Logo"/></a></div>
                <div className="header-grid no-tablet">
                    <div className="header-grid-column">
                        <div className="title">Beraterprofil Webanwendung</div>
                    </div>
                </div>
                <hr />
            </div>
            <div className="center-form-box">
                <p>Bitte melden Sie sich an</p>
                <form id='login' onSubmit={onSubmit} >
                    <label>Benutzername: </label><input type='text' name='benutzername' autoFocus placeholder='Benutzername' value={username} onChange={e=> setUsername(e.target.value.toUpperCase())}/> <br />
                    <label>Passwort: </label><input type='password' name='passwort' placeholder='Passwort' autoComplete='current-password' value={password} onChange={e=> setPassword(e.target.value)}/> <br />
                    {error && <small className='error'>{error}</small>} {error && <br />}
                    <button type="submit">Anmelden</button> 
                <div className='contant-links'>
                    <br />
                    <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%62%65%63%6B%50%72%6F%66%69%6C%65%40%62%65%63%6B%6D%61%6E%6E%2D%70%61%72%74%6E%65%72%2E%64%65">Administrator kontaktieren</a>
                </div>
                </form>
                <br />
                <small>*Diese Seite verwendet Cookies zur Authentifizierung</small>
                {errorMsg && <div><br /><strong className="error">{errorMsg}</strong></div>}
            </div>
            <VersionInfo />
            {showPopUp && <PopUp title='Erstmalige Anmeldung' text='Sie verwenden ein Einmalpasswort, bitte w&auml;hlen Sie ein neues' 
                content={
                    <div className='inner-contents'>
                        <form id='login' onSubmit={onFirstLogin} >
                            {/* <ReactPasswordStrength autoFocus className="StrengthInputBox" minLength={8} minScore={2} scoreWords={['schwach', 'okay', 'gut', 'stark', 'sehr stark']} changeCallback={e => {setPassword(e.password); setPasswordStrength(!!e.isValid)}} inputProps={{ name: "password", autoComplete: "off", className: "StrengthInput", placeholder: "Passwort"}} /> */}
                            <label>Passwort: <div className="input-strength"><input id="pw" type='password' autoFocus name='passwort' placeholder='Passwort' value={password} onChange={e => setPassword(e.target.value)}/> <PasswordStrengthBar className="input-strength-bar" password={password} minLength={10} scoreWords={['schwach', 'okay', 'gut', 'stark', 'sehr stark']} onChangeScore={(score, feedback) => {(score>2 ? setPasswordStrength(true) : setPasswordStrength(false) )}} /></div></label>
                            <br />
                            <label>Wiederholen: <input id="wdh" type='password' name='passwort_wdh' placeholder='Wiederholen' value={password_wdh} onChange={e => setPassword_wdh(e.target.value)}/></label>
                            <br />
                            <button type="submit">Best&auml;tigen</button> 
                        </form>
                        <br/>
                        {error && <small className='error'>{error}</small>} {error && <br />}
                        {errorMsg && <strong className="error">{errorMsg}</strong>}
                    </div>
                }
            />}
            <Footer />
        </div>
    );
}

/**
 * Weist den verwendetetn lokalen Variablen, variablen aus dem State zu
 * 
 * @param {*} state - aktueller State
 * @param {*} ownProps - weitere lokale Variablen
 * @returns den aktuellen State als Komponentenvariablen
 */
const mapStateToProps = (state, ownProps) => ({
    role: state.user.role,
    temporaryPasswort: state.user.temporaryPasswort,
    errorMsg: state.user.error,
    ...ownProps
})

/**
 * Weist den verwendetetn lokalen Funktionen, Funktionen aus dem Redux zu
 * 
 * @returns Die gemappten Funktionen
 */
const mapDispatchToProps = dispatch => ({
    sendLogin: (benutzername, passwort) => dispatch(sendLoginRequest(benutzername, passwort)),
    sendPasswortSet: (passwort) => dispatch(sendPasswortSetRequest(passwort))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)