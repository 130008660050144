import data from './_data'

const businessobject_data = {

    benutzer:{
        default:{
            benutzername: "",
            name: "",
            vorname: "",
            anredetitel: "",
            rolle: "",
            email: "",
            einmalPasswort: true,
            beschreibung: "",
            status: "FREIGABE_QUALITAETSSICHERUNG",
            mandantId: 1 //DEV for mandanthandling
        },
        fields:{
            topic1: {
                anredetitel: "Titel",
                name: "Name",
                vorname: "Vorname",
                beschreibung: "Beschreibung",
            },
            topic2: {
                benutzername: "Benutzername",
                email: "E-Mail",
                rolle: "Berechtigung",
            },
        },
        searchAttributes:{
            name: "Name",
            vorname: "Vorname",
            benutzername: "Benutzername",
            rolle: "Berechtigung",
            email: "E-Mail"
        }
    },
    berater:{
        default:{
            anrede: "",
            anredetitel: "",
            name: "",
            vorname: "",
            email: "",
            adressen: [],
            beraterfunktionId: 0,
            betreuer: "",
            isBerater: true,
            isKundenbetreuer: false,
            isBeraterbetreuer: false,
            startdatum: "",
            enddatum: "",
            profilbild: {
                name: "",
                beschreibung: "",
                dateityp: "",
                dateiData: ""
            },
            geburtstag: "",
            motto: "",
            projektexpertise: "",
            hobbies: "",
            onlineProfile: [],
            beschreibung: "",
            erfahrung: 0,
            techSchwerpunkt: [],
            fachSchwerpunkt: [],
            sprachen: [],
            beraterlabelId: 0,
            bildungswege: [],
            qualifikationen: [],
            status: "FREIGABE_QUALITAETSSICHERUNG",
            kuerzel: "",
            kompetenzen: [],
            dateien: []
        },
        fields:{
            stammdaten: {
                topic1: {
                    kuerzel: "Beraterkürzel (Benutzer)",
                    group1:{
                        anrede: "Anrede",
                        anredetitel: "Titel", 
                    },
                    group2:{
                        name: "Name",
                        vorname: "Vorname",
                    },
                    email: "E-Mail",
                    adressen: "Adresse"
                },
                topic2:{
                    betreuer: "Betreuer",
                    beraterfunktionId: "Funktion",
                    group3:{
                        isBerater: "Berater",
                        isKundenbetreuer: "Kundenbetreuer",
                        isBeraterbetreuer: "Beraterbetreuer",
                    },
                    group4:{
                        startdatum: "Eintrittsdatum",
                        enddatum: "Austrittsdatum",
                    },
                    beschreibung: "Beschreibung"
                },
            },
            persönliches: {
                topic1:{
                    profilbild: "Profilbild",
                    geburtstag: "Geburtstag",
                    onlineProfile: "Onlineprofil-Link",
                    group1:{
                        motto: "Motto",
                        hobbies: "Hobbies"
                    },
                },
                topic2: {
                    beraterlabelId: "Label",
                    erfahrung: "Erfahrung (Monate)",
                    group2:{
                        techSchwerpunkt: "Technischer Schwerpunkt",
                        fachSchwerpunkt: "Fachlicher Schwerpunkt"
                    },
                    projektexpertise: "Projektexpertise",
                    sprachen: "Fremdsprachen",
                },
                bildungswege: "Bildungsweg"
            },
            qualifikationen: {
                dateien: "Dateien",
                kompetenzen: "Kompetenzen",
                qualifikationen: "Qualifikation"
            }
        },
        searchAttributes:{
            kuerzel: "Kürzel",
            name: "Name",
            vorname: "Vorname",
            email: "E-Mail",
            adressen: "Ort",
            aktuellesProjekt: "Aktuelle Projekte",
            kompetenzen: "Kernkompetenzen"
        },
        sumAttributes:{
            name_vorname: "Name",
            kuerzel: "Kuerzel",
            id: "ID",
            email: "E-Mail"
        }
    },
    kunde:{
        default:{
            name: "",
            symbol: "",
            ansprechpartner: [],
            kundenbetreuer: [],
            branche: "",
            branchenspezifikation: "",
            adressen: [],
            webseite: "",
            email: "",
            beschreibung: "",
            status: "FREIGABE_QUALITAETSSICHERUNG"
        },
        fields:{
            stammdaten:{
                topic1: {
                    group1:{
                        name: "Name",
                        symbol: "Kundenkürzel"
                    },
                    branche: "Branche",
                    branchenspezifikation: "Branchenspez.",
                },
                topic2: {
                    email: "E-Mail",
                    webseite: "Webseite",
                    kundenbetreuer: "Kundenbetreuer",
                }
            },
            details:{
                topic1: {
                    ansprechpartner: "Ansprechpartner",
                    adressen: "Adresse",
                    beschreibung: "Beschreibung"
                }
            }
        },
        searchAttributes:{
            name: "Name",
            symbol: "Kürzel",
            adressen: "Ort",
            branche: "Branche",
            branchenspezifikation: "Branchenspezifikation",
            ansprechpartner: "Ansprechparter",
            kundenbetreuer: "Kundenbetreuer",
            aktuellesProjekt: "Aktuelle Projekte"
        },
        sumAttributes:{
            name: "Name",
            symbol: "Kundenkürzel",
            id: "ID",
            email: "E-Mail"
        }
    },
    kundenprojekt:{
        default:{
            kundeId: 0,
            name: "",
            abteilung: "",
            kundenbetreuer: [],
            ansprechpartnerId: [],
            kommentar: "",
            startdatum: "",
            enddatum: "",
            kurzBeschreibung: "",
            langBeschreibung: "",
            isKomplett: false,
            dateien: []
        },
        fields:{
            stammdaten:{
                topic1: {
                    kundeId: "Kunde",
                    name: "Titel",
                    abteilung: "Abteilung",
                    group1:{
                        startdatum: "Startdatum",
                        enddatum: "Enddatum"
                    },
                    ansprechpartnerId: "Ansprechpartner",
                    kommentar: "Kommentar",
                },
                topic2: {
                    kurzBeschreibung: "Kurze Projektbeschreibung",
                    langBeschreibung: "Ausführliche Projektbeschreibung",
                    kundenbetreuer: "Betreuer",
                    isKomplett: "Komplett",
                    dateien: "Dateien",
                }
            }
        },
        searchAttributes:{
            name: "Titel",
            kundeId: "Kunde",
            abteilung: "Abteilung"
        },
        sumAttributes:{
            id: "ID",
            name: "Titel",
            kundeId: "Kunde"
        }
    },
    beraterprojekt:{
        default:{
            projekttyp: "",
            kundenprojektId: 0,
            berater: "",
            projektstandort: "",
            symbol: "",
            name: "",
            ansprechpartnerId: [],
            startdatum: "",
            enddatum: "",
            projektunterbrechungen: [],
            isReferenz: true,
            druck: true,
            isVorlage: false,
            isKomplett: false,
            kurzBeschreibung: "",
            langBeschreibung: "",
            zusammenfassung: "",
            themenBeschreibung: "",
            aufgabenBeschreibung: "",
            dateien: [],
            kommentar: "",
            kompetenzen: [],
            beraterrollen: [],
            status: "FREIGABE_QUALITAETSSICHERUNG"
        },
        fields:{
            stammdaten:{
                topic1: {
                    kundenprojektId: "Kundenprojekt",
                    berater: "Projektmitarbeiter",
                    group1:{
                        name: "Titel",
                        symbol: "Projektkürzel"
                    },
                    projekttyp: "Projektyp",
                    projektstandort: "Projektstandort",
                },
                topic2: {
                    ansprechpartnerId: "Ansprechpartner",
                    group2: {
                        startdatum: "Startdatum",
                        enddatum: "Enddatum",
                    },
                    projektunterbrechungen: "Projektunterbrechungen",
                    group3: {
                        isReferenz: "Referenzprojekt",
                        druck: "Druck",
                    },
                    group4: {
                        isKomplett: "Komplett",
                        isVorlage: "Vorlage"
                    }
                }
            },
            beschreibung:{
                topic1: {
                    kurzBeschreibung: "Kurze Projektbeschreibung",
                    langBeschreibung: "Ausführliche Projektbeschreibung",
                    zusammenfassung: "Projektzusammenfassung (Chronologie)",
                },
                topic2: {
                    themenBeschreibung: "Themenbeschreibung",
                    aufgabenBeschreibung: "Aufgabenbeschreibung",
                    dateien: "Dateien",
                },
                kommentar: "Kommentar"
            },
            kompetenzen:{
                beraterrollen: "Rollen",
                kompetenzen: "Kompetenzen",
            }
        },
        searchAttributes:{
            name: "Titel",
            symbol: "Kürzel",
            berater: "Projektmitarbeiter",
            kundenprojektId_kundeId: "Kunde",
            projektstandort: "Projektstandort",
            kompetenzen: "Kernkompetenzen"
        },
        sumAttributes:{
            id: "ID",
            symbol: "Projektkürzel",
            berater: "Projektmitarbeiter",
            kundenprojektId_kundeId: "Kunde",
        }
    },
    // moderation:{
    //     searchAttributes:{
    //         kuerzel: "Berater Kürzel",
    //         symbol: "Projekt Kürzel",
    //         berater: "Projektmitarbeiter",
    //         BP_name: "Projekttitel",
    //         titel: "Kompetenztitel",
    //         kategorie: "Kompetenzkategorie",
    //         kundenprojektId_kundeId: "Kunde",
    //         name: "Name",
    //         vorname: "Vorname",
    //     }
    // },
    drucken:{
        fields:{
            berater:{
                beraterlabel: "Beraterlabel",
                kundenbetreuer: "Kundenbetreuer",
                anredetitel: "Titel",
                name: "Name",
                kuerzel: "Kürzel",
                profilbild: "Profilbild",
                motto: "Motto",
                projektexpertise: "Projektexpertise",
                adresse: "Adresse",
                beraterfunktion: "Beraterfunktion",
                onlineProfile: "Onlineprofile",
                techSchwerpunkt: "technische Schwerpunkte",
                fachSchwerpunkt: "fachliche Schwerpunkte",
                erfahrung: "Projekterfahrung (Monate)",
                sprachen: "Fremdsprachen",
                kompetenzen: "Kompetenzen",
                bildungswege: "Bildungsweg",
                qualifikationenzeitraum: "Qualifikationszeitraum (Jahre)",
                qualifikationen: "Qualifikationen",
            },
            beraterprojekte:{
                beraterrollen: "Beraterrolle",
                kernkompetenzen: "Kompetenzen",
                referenzprojekte: "Referenzprojekte",
                projekte: "Projekte"
            },
            layout:{
                layout: "Layout",
                companyLogo_exclude: "Unternehmenslogo ausblenden",
                cover_only: "Nur Deckblatt drucken"
            }
        },
        beckprofil:{
            anredetitel: "B_anredetitel",
            name: "B_vorname_B_name",
            kuerzel: "B_kuerzel",
            adresse: "B_adressen",
            profilbild: "B_profilbild_dateiData",
            motto: "B_motto",
            projektexpertise: "B_projektexpertise",
            beraterfunktion: "B_beraterfunktionId",
            // kundenbetreuer: "B_kundenbetreuer",
            email: "B_betreuer-kuerzel_email",
            onlineProfile: "B_onlineProfile",
            erfahrung: "B_erfahrung",
            techSchwerpunkt: "B_techSchwerpunkt",
            fachSchwerpunkt: "B_fachSchwerpunkt",
            sprachen: "B_sprachen",
            beraterlabel: "B_beraterlabelId",
            bildungswege: "B_bildungswege",
            kompetenzen:"B_kompetenzen",
            qualifikationen:"B_qualifikationen",
            referenzprojekte:"BP_ref",
            projekte:"BP_",
            beraterrollen: "ref_beraterrollen",
            kernkompetenzen: "ref_kompetenzen",
        },
        searchAttributes:{
            kuerzel: "Kürzel",
            name: "Name",
            vorname: "Vorname",
            email: "E-Mail",
            adressen: "Ort",
            aktuellesProjekt: "Aktuelle Projekte",
            kompetenzen: "Kernkompetenzen"
        },
        projektSearchAttributes: {
            // startdatum: "Startdatum",
            // enddatum: "Enddatum",
            name: "Titel",
            kundenprojektId_kundeId: "Kunde",
            kompetenzen: "Kernkompetenzen",
            symbol: "Kürzel"
        }
    },
    beraterrolle:{
        zoDefault:{
            beraterrolleId: 0,
            druck: true,
            titel: "",
            niveau: "GRUNDKENNTNISSE"
        },
        zoFields:{
            druck: "Druck",
            titel: "Titel",
            niveau: "Niveau"
        },
    },
    kompetenz:{
        default:{
            status: "FREIGABE_QUALITAETSSICHERUNG",
            druck: true,
            kompetenztyp:"",
            verfall: "",
            kategorie: 0,
            parentId: 0,
            titel: "",
            synonyme: [],
            isSynonym: false,
            beschreibung: ""
        },
        fields:{
            status: "Sperren",
            druck: "Druck",
            isSynonym: "Synonym",
            kategorie: "Kategorie",
            kompetenztyp: "Kompetenztyp",
            titel: "Titel",
            beschreibung: "Beschreibung",
            verfall: "Verfallsdatum",
            parentId: "Parent"
        },
        searchAttributes:{
            titel: "Titel",
            kategorie: "Kategorie",
            isSynonym: "Synonym",
            parentId: "Parent"
        },
        zoDefault:{
            kompetenzId: 0,
            druck: true,
            titel: "",
            niveau: "GRUNDKENNTNISSE",
            kategorie: "TECHNIK",
            status: "FREIGABE_QUALITAETSSICHERUNG"
        },
        zoFields:{
            druck: "Druck",
            titel: "Titel",
            niveau: "Niveau"
        },
        sumAttributes:{
            id: "ID"
        }
    },
    sprache: {
        fields:{
            status: "Sperren",
            druck: "Druck",
            titel: "Titel" 
        },
        default:{
            status: "FREIGABE_QUALITAETSSICHERUNG",
            druck: true,
            titel: "" 
        },
        zoDefault:{
            spracheId: 0,
            druck: true,
            titel: "",
            niveau: "GRUNDKENNTNISSE"
        },
        zoFields:{
            druck: "Druck",
            titel: "Titel",
            niveau: "Niveau"
        },
        searchAttributes:{
            titel: "Name"
        }
    },
    abschluss: {
        default:{
            titel: "",
            druck: true,
            status: "FREIGABE_QUALITAETSSICHERUNG"
        },
        fields:{
            status: "Sperren",
            druck: "Druck",
            titel: "Titel",  
        },
        searchAttributes:{
            titel: "Name",
        }
    },
    institut: {
        default:{
            titel: "",
            status: "FREIGABE_QUALITAETSSICHERUNG"
        },
        fields:{
            status: "Sperren",
            titel: "Titel"
        },
        searchAttributes:{
            titel: "Name"
        }
    },
    bildungstitel: {
        default:{
            abschlussId: 0,
            titel: "",
            status: "FREIGABE_QUALITAETSSICHERUNG",
        },
        fields:{
            status: "Sperren",
            abschlussId: "Abschluss",
            titel: "Titel"
        },
        searchAttributes:{
            titel: "Name",
            abschlussId: "Abschluss",
        }
    },
    qualifikation:{
        default:{
            status: "FREIGABE_QUALITAETSSICHERUNG",
            druck: true,
            typ: "",
            titel: "",
            institutId: 0,
            spracheId: 0,
            link: "",
            kompetenzenId: [],
            beschreibung: ""
        },
        fields:{
            status: "Sperren",
            druck: "Druck",
            typ: "Qualifikationstyp",
            titel: "Titel",
            institutId: "Institut",
            spracheId: "Sprache",
            link: "Link",
            kompetenzenId: "Kompetenzen",
            beschreibung: "Beschreibung"
        },
        searchAttributes:{
            titel: "Titel",
            typ: "Typ",
            institutId: "Institut"
        },
        zoDefault:{
            qualifikationId: 0,
            enddatum: "",
            druck: true,
            isDozent: false,
            ort: "",
            datei: {
                name: "",
                beschreibung: "",
                dateityp: "",
                dateiData: ""
            }
        },
        zoFields:{
            druck: "Druck",
            typ: "Qualifikationstyp",
            qualifikationTitel: "Titel",
            isDozent: "Dozent",
            ort : "Ort",
            institutId: "Institut",
            spracheId: "Sprache",
            enddatum: "Datum",
            link: "Link",
            beschreibung: "Beschreibung",
            datei: "Datei"
        },
        sumAttributes:{
            id: "ID"
        }
    },
    bildungsweg:{
        default:{
            druck: true,
            abschlussId: 0,
            bildungstitelId: 0,
            enddatum: "",
            institutId: 0,
            arbeit: "",
            ort: ""
        },
        fields:{
            druck: "Druck",
            abschlussId: "Abschluss",
            bildungstitelId: "Bildungstitel",
            enddatum: "Abgabedatum",
            institutId: "Institut",
            arbeit: "Wissenschaftliche Arbeit",
            ort: "Ort"
        }
    },
    beraterattribut:{
        searchAttributes:{
            titel: "Name",
            attributeType: "Attribut",
        },
        childFields:{
            attributeType: "Attribut",
            status: "Sperren",
            druck: "Druck",
            titel: "Titel" 
        },
        childDefault:{
            attributeType: "",
            status: "FREIGABE_QUALITAETSSICHERUNG",
            druck: true,
            titel: "" 
        },
        childs: ["beraterrolle", "beraterlabel", "beraterfunktion"]
    },
    adresse:{
        default:{
            adresstyp: "",
            strasse: "",
            hausnummer: "",
            stadt: "",
            postleitzahl: "",
            laendercode: "",
            zusatz: "",
            postfach: "",
            telefonnummer: "",
            beschreibung: "",
            isVerfuegbar_mon: false,
            isVerfuegbar_tue: false,
            isVerfuegbar_wen: false,
            isVerfuegbar_thu: false,
            isVerfuegbar_fri: false,
            isVerfuegbar_sat: false,
            verfuegbarkeit_kommentar: ""
        },
        fields:{
            adresstyp: "Adresstyp",
            group1:{
                strasse: "Straße",
                hausnummer: "Hausnummer",
                stadt: "Stadt",
                postleitzahl: "PLZ",
                zusatz: "Zusatz",
                laendercode: "Land",
            },
            postfach: "Postfach (optional)",
            // group2:{
                telefonnummer: "Telefonnummer",
                beschreibung: "Beschreibung",
            // },
            group3: {
                isVerfuegbar_mon: "Mo",
                isVerfuegbar_tue: "Di",
                isVerfuegbar_wen: "Mi",
                isVerfuegbar_thu: "Do",
                isVerfuegbar_fri: "Fr",
                isVerfuegbar_sat: "Sa",
                verfuegbarkeit_kommentar: "Kommentar zur Verfügbarkeit"
            },
        },
        searchAttributes:{
            stadt: "Stadt",
            strasse: "Straße",
            hausnummer: "Hausnummer",
            postleitzahl: "PLZ",
            adresstyp: "Adresstyp",
            laendercode: "Land"
        }
    },
    projektunterbrechung: {
        default:{
            startdatum: "",
            enddatum: "",
            kommentar: ""
        },
        fields:{
            startdatum: "Startdatum",
            enddatum: "Enddatum",
            kommentar: "Kommentar"
        }
    },
    ansprechpartner:{
        default:{
            anredetitel: "",
            anrede: "",
            name: "",
            vorname: "",
            email: "",
            beraterfunktionId: 0,
            adressen: []
        },
        fields:{
            stammdaten:{
                id: "ID",
                group1: {
                    anrede: "Anrede",
                    anredetitel: "Anredetitel"
                },
                group2: {
                    name: "Name",
                    vorname: "Vorname"
                },
                email: "E-Mail",
                beraterfunktionId: "Funktion",
                adressen: "Adresse"
            }
        },
        searchAttributes:{
            name: "Name",
            vorname: "Vorname",
            email: "E-Mail",
            beraterfunktionId: "Funktion",
            kundeId: "Kunde"
        },
        sumAttributes:{
            id: "ID",
            name_vorname: "Name"
        }
    },
    datei:{
        default:{
            name: "",
            beschreibung: "",
            dateityp: "",
            dateiData: ""
        },
        fields:{
            name: "Name",
            beschreibung: "Beschreibung",
            dateityp: "Dateityp"
        }
    },
    layout: {
        default:{
            titel: "",
            layoutDatei: {
                name: "",
                beschreibung: "",
                dateityp: "DOKUMENT",
                dateiData: ""
            },
            styleDatei: {
                name: "",
                beschreibung: "",
                dateityp: "DOKUMENT",
                dateiData: ""
            },
            status: "FREIGABE_QUALITAETSSICHERUNG"
        },
        fields:{
            titel: "Titel",
            layoutDatei: "Layoutdatei",
            styleDatei: "Styledatei",
            status: "Status"
        },
        searchAttributes:{
            titel: "Titel"
        },
        sumAttributes:{
            id: "ID",
            titel: "Titel"
        }
    },
    beckprofil:{
        anredetitel: "",
        name: "",
        kuerzel: "",
        adresse: "",
        profilbild: "",
        motto: "",
        projektexpertise: "",
        beraterfunktion: "",
        kundenbetreuer: "",
        email: "",
        onlineProfile: "",
        erfahrung: "",
        techSchwerpunkt: "",
        fachSchwerpunkt: "",
        sprachen: "",
        beraterlabel: "",
        bildungswege: [],
        kompetenzen:{},
        qualifikationen:{},
        qualifikationenzeitraum: 2,
        companyLogo_exclude: false,
        cover_only: false,
        referenzprojekte:[],
        projekte:[],
        beraterrollen: "",
        kernkompetenzen: "",
    },
    beckprofil_objects:{
        referenzprojekt:{
            name: "",
            kurzBeschreibung: "",
            zusammenfassung: "",
            symbol: "",
            branche: "",
            branchenspezifikation: "",
            dauer: "",
            langBeschreibung: "",
            themenBeschreibung: "",
            aufgabenBeschreibung: "",
            beraterrollen: {},
            kompetenzen: {},
            kundenprojekt: "",
            kunde: "",
            ansprechpartner: "",
            stadt: "",
        },
        projekt:{
            name: "",
            kurzBeschreibung: "",
            zusammenfassung: "",
            symbol: "",
            branche: "",
            branchenspezifikation: "",
            dauer: "",
            beraterrollen: {},
            kompetenzen: {},
            kundenprojekt: "",
            kunde: "",
            ansprechpartner: "",
            stadt: "",
        },
        fields:{
            branche: "KP_kundenprojektId-id_K_kundeId-id_branche",
            branchenspezifikation: "KP_kundenprojektId-id_K_kundeId-id_branchenspezifikation",
            // beraterrollen: "BP_beraterrolleId",
            kundenprojekt: "KP_kundenprojektId-id_name",
            kunde: "KP_kundenprojektId-id_K_kundeId-id_name",
            ansprechpartner: "KP_kundenprojektId-id_K_kundeId-id_AP_ansprechpartnerId-id_vorname_name",
            stadt: "KP_kundenprojektId-id_K_kundeId-id_adressen_stadt"
        }
    },
    types:{
        abteilung: {type:data.inputTypes.advanced.DROPDOWN, readOnly: false},
        adressen: {type:data.inputTypes.advanced.INLINEDEFINE, readOnly: false, dataAttribute: "adresse", create: true},
        adresse: {type:data.inputTypes.advanced.INLINEDEFINE, readOnly: false, create: true},
        adresstyp: {type:data.inputTypes.advanced.DROPDOWN, readOnly: false, notnull: true},
        anrede: {type:data.inputTypes.advanced.DROPDOWN, readOnly: false},
        anredetitel: {type:data.inputTypes.advanced.DROPDOWN, readOnly: false},
        ansprechpartner: {type:data.inputTypes.advanced.OBJECTFK, businessObject: data.businessObject.contactperson, multiSelect: true, readOnly: false, create: true},
        ansprechpartnerId: {type:data.inputTypes.advanced.OBJECTFK, businessObject: data.businessObject.contactperson, multiSelect: true, selectProperty:'id', presentationProperty:'name, vorname', readOnly: false, create: false},
        aufgabenBeschreibung: {type:data.inputTypes.standard.TEXTAREA, readOnly: false},
        benutzername: {type:data.inputTypes.standard.TEXT, readOnly: false, regex: /^\w{3,4}$/, notnull: true},
        berater: {type:data.inputTypes.advanced.OBJECTFK, selectProperty:'kuerzel', businessObject: data.businessObject.consultant, readOnly: false, create: false, notUpdateable:true, notnull:true},
        beraterfunktionId: {type:data.inputTypes.advanced.DROPDOWN, dataAttribute: "beraterfunktion", readOnly: false},
        beraterfunktion: {type:data.inputTypes.NONE, dataAttribute: "beraterfunktion", readOnly: false, attributeGroup: "beraterattribut"},
        beraterattribut: {attributeGroupParent: true, attributeGroup: "beraterattribut"},
        beraterlabelId: {type:data.inputTypes.advanced.DROPDOWN, dataAttribute: "beraterlabel", readOnly: false},
        beraterlabel: {type:data.inputTypes.advanced.DROPDOWN, dataAttribute: "beraterlabel", readOnly: false, attributeGroup: "beraterattribut"},
        beraterrollen: {type:data.inputTypes.advanced.TABLE, dataAttribute: "beraterrolle", create:true, zo:true, selectProperty:'id', idProperty:'beraterrolleId', readOnly: false},
        beraterrolle: {type:data.inputTypes.NONE, dataAttribute: "beraterrolle", readOnly: false, attributeGroup: "beraterattribut"},
        beschreibung: {type:data.inputTypes.standard.TEXTAREA, readOnly: false},
        betreuer: {type:data.inputTypes.advanced.OBJECTFK, selectProperty:'kuerzel', filterBy: 'isBeraterbetreuer', filterValue: true, businessObject: data.businessObject.consultant, readOnly: false},
        bildungstitelId: {type:data.inputTypes.advanced.DROPDOWN, dataAttribute: "bildungstitel", readOnly: false, filterBy: 'abschlussId', notnull: true},
        bildungswege: {type:data.inputTypes.advanced.ROWDEFINE, dataAttribute: "bildungsweg", readOnly: false},
        branche: {type:data.inputTypes.advanced.DROPDOWN, readOnly: false},
        branchenspezifikation: {type:data.inputTypes.advanced.DROPDOWN, readOnly: false},
        companyLogo_exclude: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        cover_only: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        datei: {type: data.inputTypes.advanced.FILE, readOnly: false},//dataAttribute: "dateityp",
        styleDatei: {type: data.inputTypes.advanced.FILE, readOnly: false, notnull:true},//dataAttribute: "dateityp",
        layoutDatei: {type: data.inputTypes.advanced.FILE, readOnly: false, notnull:true},//dataAttribute: "dateityp",
        dateien: {type: data.inputTypes.advanced.FILE, dataAttribute: "datei", multiSelect: true, readOnly: false},
        dateityp: {type: data.inputTypes.advanced.DROPDOWN, readOnly: false},
        druck: {type:data.inputTypes.standard.CHECKBOX, readOnly: false, notnull: true},
        attributeType: {type:data.inputTypes.advanced.DROPDOWN, readOnly: false, notUpdateable:true, notnull: true},
        enddatum: {type:data.inputTypes.standard.DATE, readOnly: false, semanticCheck: {function: ">=",compareTo: "startdatum"}},
        email: {type:data.inputTypes.standard.TEXT, readOnly: false, regex: /^\S+@\S+\.\S+$/, notnull: ["benutzer"]},
        erfahrung: {type:data.inputTypes.standard.NUMBER, readOnly:false},
        fachSchwerpunkt:  {type:data.inputTypes.standard.MULTIPLE, readOnly: false},
        geburtstag: {type:data.inputTypes.standard.DATE, readOnly: false},
        abschlussId: {type:data.inputTypes.advanced.DROPDOWN, dataAttribute: "abschluss", readOnly: false, notnull: true},
        hausnummer: {type:data.inputTypes.standard.SHORT, readOnly: false, regex: /^\d{1,3}\w{0,2}$/},
        hobbies: {type:data.inputTypes.standard.TEXTAREA, readOnly: false},
        id: {type:data.inputTypes.standard.PLAIN, readOnly: true},
        institutId: {type:data.inputTypes.advanced.DROPDOWN, dataAttribute: "institut", readOnly: false, searchable: true},
        isBerater: {type:data.inputTypes.standard.CHECKBOX, readOnly: false, notnull:true},
        isBeraterbetreuer: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        isDozent: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        isKomplett: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        isKundenbetreuer: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        isReferenz: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        isSynonym: {type:data.inputTypes.standard.CHECKBOX, readOnly: false, semanticCheck: {function: "required", compareTo: "parentId"}},
        isVerfuegbar_fri: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        isVerfuegbar_mon: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        isVerfuegbar_sat: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        isVerfuegbar_thu: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        isVerfuegbar_tue: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        isVerfuegbar_wen: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        isVorlage: {type:data.inputTypes.standard.CHECKBOX, readOnly: false},
        kategorie: {type:data.inputTypes.advanced.DROPDOWN, dataAttribute: "kompetenzkategorie", readOnly: false, notnull:true, notUpdateable:true},
        kommentar: {type:data.inputTypes.standard.TEXTAREA, readOnly: false, maxChar: 200},
        kompetenzen: {type:data.inputTypes.advanced.TABLE, readOnly: false, dataAttribute: "kompetenz", create:true, zo:true, idProperty: 'kompetenzId', sortProperty: 'kategorie'},
        kompetenzenId: {type:data.inputTypes.advanced.DROPDOWN, dataAttribute: "kompetenz", multiSelect: true, searchable: true, readOnly: false},
        kompetenztyp: {type:data.inputTypes.advanced.DROPDOWN, dataAttribute: "kompetenztyp", readOnly: false, notnull:true},
        kuerzel: {type:data.inputTypes.advanced.OBJECTFK, selectProperty:'benutzername', businessObject: data.businessObject.user, overwrite: ['name', 'vorname', 'email', 'anredetitel'], readOnly:false, unique:true, notUpdateable:true, notnull:true},
        kundeId: {type:data.inputTypes.advanced.OBJECTFK, selectProperty:'id', presentationProperty:'name', businessObject: data.businessObject.customer, readOnly: false, notnull:true},
        kundenbetreuer: {type:data.inputTypes.advanced.OBJECTFK, selectProperty:'kuerzel', businessObject: data.businessObject.consultant, filterBy: 'isKundenbetreuer', filterValue: true,  multiSelect: true, readOnly: false, create: false},
        kundenprojektId: {type:data.inputTypes.advanced.OBJECTFK, selectProperty:'id', presentationProperty:'name', businessObject: data.businessObject.customerproject, overwrite: ['name', 'langBeschreibung', 'kurzBeschreibung'], readOnly: false, notUpdateable:true},
        kurzBeschreibung: {type:data.inputTypes.standard.TEXTAREA, readOnly: false,  maxChar: 200, notnull:true},
        zusammenfassung: {type:data.inputTypes.standard.TEXTAREA, readOnly: false, notnull: true},
        kurzProjekt: {type:data.inputTypes.standard.TEXTAREA, readOnly: false},
        laendercode: {type:data.inputTypes.standard.SHORT, readOnly: false, maxChar: 2, regex: /^\w{2}$/},
        langBeschreibung: {type:data.inputTypes.standard.TEXTAREA, readOnly: false},
        layout: {type:data.inputTypes.advanced.DROPDOWN, readOnly: false},
        langProjekt: {type:data.inputTypes.standard.TEXTAREA, readOnly: false},
        link: {type:data.inputTypes.standard.TEXT, readOnly: false, regex: /\S+\.\S+\.\S+/},
        mandantId: {type:data.inputTypes.standard.PLAIN, readOnly: true, notnull:false},
        motto: {type:data.inputTypes.standard.TEXTAREA, readOnly: false},
        projektexpertise: {type: data.inputTypes.standard.TEXTAREA, readOnly: false},
        niveau: {type: data.inputTypes.advanced.DROPDOWN, readOnly: false},
        name: {type:data.inputTypes.standard.TEXT, notnull: true},
        onlineProfile: {type:data.inputTypes.standard.MULTIPLE, readOnly: false, regex: /\S+\.\S+\.\S+/},
        passwort: {type:data.inputTypes.standard.PASSWORD, readOnly: false, regex: /^(?=.*[A-Z].*[A-Z])(?=.*[!"§$%&`()=?+*~#'\-_.:,\\;<>|^°/])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z]).{10,}$/},
        parentId: {type:data.inputTypes.advanced.OBJECTFK, selectProperty:'id', presentationProperty: 'titel', dataLocation: "kompetenz", dataAttribute: "parent", businessObject: data.businessObject.competence, readOnly: false, filterBy: 'kategorie'},
        postleitzahl: {type:data.inputTypes.standard.SHORT, readOnly: false, asNumber: true, regex: /^\d{5,6}$/},
        profilbild: {type: data.inputTypes.advanced.FILE,picture: true, readOnly: false},
        projektstandort: {type:data.inputTypes.advanced.DROPDOWN, readOnly: false},
        projekttyp: {type:data.inputTypes.advanced.DROPDOWN, readOnly: false, notnull: true},
        projektunterbrechungen: {type:data.inputTypes.advanced.INLINEDEFINE, readOnly: false, dataAttribute: "projektunterbrechung", create: true},
        qualifikationen:  {type:data.inputTypes.advanced.ROWDEFINE, readOnly: false, dataAttribute: "qualifikation", zo:true, idProperty: 'qualifikationId', sortProperty: 'typ'},
        qualifikationTitel: {type:data.inputTypes.advanced.DROPDOWN, dataLocation: "qualifikation", dataAttribute: "titel", filterBy: 'typ', readOnly: false, create: true, notnull: true},
        rolle: {type:data.inputTypes.advanced.SINGLESELECT, readOnly: false, data: data.presentation.roles, notnull: true},
        spracheId: {type:data.inputTypes.advanced.DROPDOWN, dataAttribute: "sprache", readOnly: false, searchable: true},
        sprachen: {type:data.inputTypes.advanced.TABLE, dataAttribute: "sprache", readOnly: false, zo:true, idProperty: 'spracheId'},
        startdatum: {type:data.inputTypes.standard.DATE, readOnly: false, semanticCheck: {function: "<=",compareTo: "enddatum"}, notnull: ["beraterprojekt", "berater"]},
        status: {type:data.inputTypes.standard.CHECKBOX, readOnly: false, notnull:true},
        symbol: {type: data.inputTypes.standard.SHORT, unique: true, regex: /^\w{3,9}$/},
        synonyme: {type:data.inputTypes.NONE, readOnly: false},
        techSchwerpunkt: {type:data.inputTypes.standard.MULTIPLE, readOnly: false},
        telefonnummer: {type:data.inputTypes.standard.TEXT, readOnly: false, regex: /^(0049|0)[0-9]{3,4}[0-9]{6,8}$/},
        themenBeschreibung: {type:data.inputTypes.standard.TEXTAREA, readOnly: false},
        titel: {type:data.inputTypes.standard.TEXT, notnull: true},
        typ: {type:data.inputTypes.advanced.DROPDOWN, dataAttribute: "qualifikationstyp", readOnly: false, notnull:true, notUpdateable:true},
        verfall: {type:data.inputTypes.standard.DATE, readOnly: false},
        verfuegbarkeit_kommentar: {type:data.inputTypes.standard.TEXTAREA, readOnly: false},
        vorname: {type:data.inputTypes.standard.TEXT},
        webseite: {type:data.inputTypes.standard.TEXT, readOnly: false, regex: /\S+\.\S+\.\S+/},
    },
    displayList: {
        adressen: "stadt",
        ansprechpartner: "name, vorname",
        aktuellesProjekt: {join: "beraterprojekt", joinType:"right", joinOn:{left:"kuerzel", right: "berater"}, display: "name"},
        kompetenzen: {join: "kompetenz", joinType:"left", joinOn:{left:"kompetenzId", right: "id"}, display: "titel", filterBy: "niveau", filterValue:["EXPERTE","PRAKTISCH"]},
        beraterrollen: {join: 'beraterrolle', joinType:"left", joinOn:{left:"beraterrolleId", right: "id"}, display:"titel", filterBy:"niveau", filterValue:["EXPERTE","PRAKTISCH"]},
        kundeId: {join: "kunde", joinType:"right", joinOn:{left:"kundeId", right: "id"}, display: "name"},
        kundenprojektId_kundeId: {join: "kundenprojekt", joinType:"right", joinOn:{left:"kundenprojektId", right: "id"}, nextJoin: "kundeId"},
        erfahrung: {join: "beraterprojekt", joinType:"right", joinOn:{left:"kuerzel", right: "berater"}, calc: "projectduration"},
        // kundenprojektId: {}
    }
}

export default businessobject_data