import { USERS_REQUEST_FAILED, USERS_REQUEST_START, USERS_RESPONSE_ERROR, SERVER, USERS_REQUEST_ERROR, SET_USERS, USERS_SUCCESS, USERS_UNSET_SUCCESS } from "./constants"
import { getApiRequestBuilder } from "./util"

import message_data from '../data/message_data'

/**
 * Redux API um den Nutzer am Backend zu verwalten
 * 
 * @author DHR
 */

// build the requestBuilder with the Failure types for the Reducer
const requestBuilder = getApiRequestBuilder(USERS_REQUEST_START, USERS_REQUEST_FAILED, USERS_REQUEST_ERROR, USERS_RESPONSE_ERROR)
export const requestURL_get = SERVER + 'benutzer'
const requestURL_pwreset = SERVER + '/passwortReset'
const requestURL_create = SERVER + 'benutzer/'
const requestURL_delete = SERVER + 'benutzer/'
const requestURL_update = SERVER + 'benutzer/'

/**
 * Setzt das JSON Objekt aus der Response im State
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben
 * 
 * @param {Object} jsonData - Responsejson.data
 * @returns action(type,...payload) zum aktualisieren des States
 */
 const setUserList = (jsonData) => ({type: SET_USERS, list:jsonData})

/**
 * Setzt die success Message im State
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben
 * 
 * @returns action(type) zum aktualisieren des States
 */
 const setSuccess = () => ({type: USERS_SUCCESS})

 /**
 * Setzt die success Message im State zurück
 * 
 * @returns action(type) zum aktualisieren des States
 */
 export const unsetSuccess = () => ({type: USERS_UNSET_SUCCESS})

 /**
 * Sendet Request um die Liste der Benutzer zu erhalten
 * 
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
 export function sendGetUsersRequest(){
     return (requestBuilder(
         requestURL_get,
         {
         method: 'GET', 
         headers: { credentials: "same-origin", 'Accept': 'application/json'},
         }, setUserList)
     )
 }

/**
 * Sendet Request um das Passwort des übergebenden Benutzer zurückzusetzen
 * 
 * @param {String} benutzername - benutzername
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendPasswortResetRequest(benutzername){
    return(requestBuilder(
        //requestURL_update+benutzername+requestURL_pwreset, 
        requestURL_get+'/'+benutzername+'/passwortReset',
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' }
        }, setSuccess)
    )
}

/**
 * Sendet Request um den Benutzer zu löschen
 * 
 * @param {String} id - id
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendUserDeleteRequest(id){
    return(requestBuilder(
        (requestURL_delete+id), 
        {
            method: 'DELETE', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' }
        }, setSuccess)
    )
}

/**
 * Sendet Request um Attribute des Benutzers neu zusetzen
 * 
 * @param {Object} benutzer - Objekt des Benutzers
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendUserUpdateRequest(benutzer){
    return(requestBuilder(
        (requestURL_update + benutzer.id), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(benutzer)
        }, setSuccess)
    )
}

/**
 * Sendet Request um Benutzer anzulegen
 * 
 * @param {Object} benutzer - Objekt des Benutzers
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendUserCreateRequest(benutzer){
    return(requestBuilder(
        (requestURL_create), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(benutzer)
        }, setSuccess)
    )
}

/**
 * Zugriff auf den aktuellen State und erweitern bzw. modifizieren dessen
 * Reducer by action.type
 * 
 * @see constants.js
 * @param {*} state - takes the currentstate
 * @param {*} action - takes some type and some values as payload
 * @returns the new state 
 */
export const users = (state = { }, { type, ...payload }) => {
    switch (type) {
        case USERS_SUCCESS:
            return {
                ...state,
                success: message_data.success.redux.default
            }
        case USERS_UNSET_SUCCESS:{
            return {
                ...state,
                success: ''
            }
        }
        case SET_USERS:
            return {
                ...state,
                error: "",
                list: payload.list
            }
        case USERS_REQUEST_FAILED:
            return {
                ...state,
                error: message_data.error.redux.unavailable
            }
        case USERS_REQUEST_ERROR:
            if (payload.errorMessage.responseStatusCode === 403){
                return {
                    ...state,
                    error: message_data.error.redux.unauthorized
                }
            }
            if (payload.errorMessage.responseStatusCode === 500){
                return {
                    ...state,
                    error: message_data.error.redux.fatal
                }
            }
            return {
                ...state,
                error: message_data.error.redux.default + payload.errorMessage.responseStatusCode + payload.errorMessage.responseStatusText
            }
        case USERS_RESPONSE_ERROR:
            return {
                ...state,
                error: payload.errorMessage
            }
        default:
            return state
    }
}