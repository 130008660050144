import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';

import businessobject_data from '../../../data/businessobject_data'
import data from './../../../data/_data'
import message_data from './../../../data/message_data';

import PflegenKopfzeile_Kompetenz from './PflegenKopfzeile_Kompetenz'
import PflegenFusszeile from '../../Fusszeile'

import {sendDeleteCompetenceRequest, sendCreateCompetenceRequest, sendUpdateCompetenceRequest, sendGetCompetenceListRequest} from '../../../redux/competences'
import NotFound from './../../NotFound';
import { useParams } from 'react-router';
import { optionsDataByTypeInit, getOptionsInit, equals, somethingChanges, defaultEncoder, flattenObject2Array, list2options, objectList2options, objectDict2options, object2options, optionsFilter, validateValues, checkInvalidation, checkReadOnly, arrayDefaultFilter, fieldData2options } from './../../_helpers/functions';
import PopUp from './../../_helpers/PopUp';
import { PermissionForbidden } from './../../_helpers/ReadPermission';
import { RowInput, TextInput } from '../../_helpers/InnerContent';



/**
 * Unter-Komponente für die Seiten "Beraterprofil Pflegen".
 * Bearbeitet oder legt den gewählte Kompetenzen an
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {String} username - benutzername des angemeldeten Benutzers
 * @param {String} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} accessStatus - status des Pflegens des Geschaeftsobjektes (suche, neu, gefuehrt)
 * @param {Object} id - die ID des konkreten Beraters
 * @param {Object} list - Liste aller Kompetenzen aus dem State, von der API
 * @param {Function} getList - Funktion zum request der Kompetenzen von der API
 * @param {Function} sendCreate - Funktion zum API call zum Erstellen des Objektes
 * @param {Function} sendUpdate - Funktion zum API call zum Aktualisieren des Objektes
 * @param {Function} sendDelete - Funktion zum API call zum Löschen des Objektes
 * @param {String} errorMsg - errorMsg wenn API call fehlschlägt
 * @param {String} fieldData - Feldauspraegungen aus dem State
 * @param {String} fieldDataError - errorMsg wenn API call, speziell für die Feldauspraegungen fehlschlägt
 * @param {String} successMsg - successMsg wenn Anlegen oder Update erfolgreich
 * @returns {HTML} Seite zum Pflegen der Geschaeftsobjekte
 * @see PflegenKopfzeile
 * @see PflegenFusszeile
 */
function Pflegen_Kompetenz({role, username, id, accessStatus, readOnly, list, getList, fieldData, fieldDataError, sendDelete, sendUpdate, sendCreate, errorMsg, successMsg, searchFilterQuery}){
    var businessObject = data.businessObject.competence
    const [showDeletePopUp, setShowDeletePopUp] = useState(false)
    const [failedValidation, setFailedValidation] = useState({notnull: {}, regex: {}, semantic:  {}})

    const {category} = useParams()

    const history = useHistory();
    useEffect(() => {if (successMsg === message_data.success.redux.default){
        if (category){
            history.push('/pflegen/kompetenz?tab='+((businessobject_data.types[category] && businessobject_data.types[category].attributeGroup)?businessobject_data.types[category].attributeGroup:category))
        } else {
            history.push('/pflegen')
        }
    }}, [successMsg])

    const [error, setError] = useState("")
    var object;
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [error, errorMsg])

    // request Data if needed
    const fetchCompetence = () => {
        if (list && accessStatus === data.accessStatus.update){
            if (category !== data.businessObject.competence && category !== data.businessObject.qualification){
                if (list[category]){
                    return {...list[category].find(o => o.id === parseInt(id)), ['attributeType']: category}
                }
            } else {
                return flattenObject2Array(list[category]).find(o => o.id === parseInt(id))
            }
        }
    }

    // default Object for creation, especially for educationobjects
    const getDefaultObject = () => {
        return ((businessobject_data.types[category] && businessobject_data.types[category].attributeGroup) ? businessobject_data[businessobject_data.types[category].attributeGroup].childDefault : (businessobject_data[category] ? businessobject_data[category].default : {}))
    }

    // presentation Object, especially for educationobjects
    const getFieldsObject = () => {
        return ((businessobject_data.types[category] && businessobject_data.types[category].attributeGroup) ? businessobject_data[businessobject_data.types[category].attributeGroup].childFields : (businessobject_data[category] ? businessobject_data[category].fields : {}))
    }

    object = fetchCompetence();

    
    const [values, setValues] = useState(accessStatus === data.accessStatus.update ? defaultEncoder(object) : {new: [defaultEncoder(getDefaultObject())]})
    if (values){readOnly=(readOnly ? readOnly : PermissionForbidden(role, username, id, data.businessObject.competence, values))}

    // load Object or Default if id or competenceCategorie changes
    useEffect(() => {
        if (!list){getList();}
        if (accessStatus === data.accessStatus.update){
            object = fetchCompetence();
            setValues(defaultEncoder(object))
        }
        if (accessStatus === data.accessStatus.create && category){
            setValues({new: [defaultEncoder(getDefaultObject())]})
        }
    }, [id, category, list])
    useEffect(() => {
        getList()
    }, [])

    // open PopUpto to Confirm on Function call
    const onDelete = () => {setShowDeletePopUp(true)}
    // close PopUp and do api call
    const onDelete_sure = () => {
        setShowDeletePopUp(false);
        sendDelete(id, category);
    }

    const onRelease = () => {
        values.status = data.status.FREIGABE_QUALITAETSSICHERUNG
        values.druck = true
        onSave()
    }
    // validate and copy the object to save it via API call
    const onSave = () => {
        
        if (!validateValues(setError, values, failedValidation, setFailedValidation)){return;}
        for (let key of Object.keys(values)){
            
            if (!!values[key] || (!!object[key] && !values[key])){
                if (Array.isArray(values[key])){
                    //filter default values out of the array
                    let tempValues = arrayDefaultFilter(values[key], key, category)
                    if (tempValues.length > 0 || (object[key] && object[key].length > 0)){
                        object[key] =  tempValues
                    }
                } else {
                    object[key] = values[key]
                }
            }
        }
        sendUpdate(object, category)
    }

    // validate and create the object via API call
    const onCreate = () => {
        if (!validateValues(setError, values.new, failedValidation, setFailedValidation)){return;}
        for (let o of values.new){
            let object = {}
            if (o.titel){
                for (let key of Object.keys(o)){
                    if (key !== 'dateien' && ((!!o[key] && !equals(o[key], getDefaultObject()[key])) || (businessobject_data.types[key] && ((typeof businessobject_data.types[key].notnull === 'boolean' && businessobject_data.types[key].notnull) || (Array.isArray(businessobject_data.types[key].notnull) && businessobject_data.types[key].notnull.includes(businessObject))))) && Object.keys(getDefaultObject()).includes(key)){
                        if (Array.isArray(o[key])){
                            //filter default values out of the array
                            let tempValues = arrayDefaultFilter(o[key], key, category)
                            if (tempValues.length > 0){
                                object[key] =  tempValues
                            }
                        } else {
                            object[key] = o[key]
                        }
                    }
                }
                sendCreate(object, (o.attributeType ? o.attributeType : category))
            }
        }
    }

    // load functions to get keys and options data
    const optionsDataByType = optionsDataByTypeInit(list, fieldData2options(fieldData))
    const getOptions = getOptionsInit(optionsDataByType)

    var optObject = {}
    const disObject = {}

    //load options for the input if needed
    switch (category){
        case data.businessObject.competence:
            optObject = getOptions(category)
            optObject.parentId = (list ? optionsFilter(flattenObject2Array(list.kompetenz, 1, 'kategorie'), 'isSynonym', true, true) : [])
            optObject._onSelectCategory = {parentId:"kompetenz"}
            if (accessStatus === data.accessStatus.create){
                for (let i in values.new){
                    if (!values.new[i].kategorie){
                        disObject[i] = {...disObject[i], parentId: true}
                    }
                    for (let o of Object.keys(values.new[i])){
                        if (checkReadOnly(o, readOnly, accessStatus)){
                            disObject[i] = {...disObject[i], [o]: true}
                        }
                    }
                }
            } else {
                if (values && !values.kategorie){
                    disObject.parentId = true
                }
                for (let o of Object.keys(values)){
                    if (checkReadOnly(o, readOnly, accessStatus)){
                        disObject[o] = true
                    }
                }
            }
            break;
        case data.businessObject.qualification:
            optObject = getOptions(category)
            optObject.kompetenzenId = objectDict2options((list ? list.kompetenz : []), 'titel')
            if (accessStatus === data.accessStatus.create){
                for (let i in values.new){
                    for (let o of Object.keys(values.new[i])){
                        if (checkReadOnly(o, readOnly, accessStatus)){
                            disObject[i] = {...disObject[i], [o]: true}
                        }
                    }
                }
            } else {
                for (let o of Object.keys(values)){
                    if (checkReadOnly(o, readOnly, accessStatus)){
                        disObject[o] = true
                    }
                }
            }
            break;
        default:
            optObject = getOptions(((businessobject_data.types[category] && businessobject_data.types[category].attributeGroup) ? businessobject_data.types[category].attributeGroup : category))//.grad = optionsDataByType('grad')
            optObject.attributeType = ((businessobject_data.types[category] && businessobject_data.types[category].attributeGroup) ? list2options(businessobject_data[businessobject_data.types[category].attributeGroup].childs) : [])
            if (accessStatus === data.accessStatus.create){
                if (values && businessobject_data[category]){
                    for (let i in values.new){
                        for (let k of Object.keys(getFieldsObject())){
                            if (getFieldsObject().attributeType && !values.new[i].attributeType && k !== 'attributeType'){
                                disObject[i] = {...disObject[i], [k]: true}
                            } // else if (values.new[i].attributeType && (businessobject_data[values.new[i].attributeType] && businessobject_data[values.new[i].attributeType].fields && Object.keys(businessobject_data[category].childFields).filter(x => !Object.keys(businessobject_data[values.new[i].attributeType].fields).includes(x)).includes(k))){
                            //     disObject[i] = {...disObject[i], [k]: true}
                            // }
                            if (checkReadOnly(k, readOnly, accessStatus)){
                                disObject[i] = {...disObject[i], [k]: true}
                            }
                        }
                    }
                }
            } else {
                if (businessobject_data[((businessobject_data.types[category] && businessobject_data.types[category].attributeGroup) ? businessobject_data.types[category].attributeGroup : category)]){
                    for (let k of Object.keys(getFieldsObject())){
                        // if (attributeType && (businessobject_data[values.attributeType] && businessobject_data[values.attributeType].fields && Object.keys(businessobject_data[category].childFields).filter(x => !Object.keys(businessobject_data[values.attributeType].fields).includes(x)).includes(k))){
                        //     disObject[k] = true
                        // }
                        if (checkReadOnly(k, readOnly, accessStatus)){
                            disObject[k] = true
                        }
                    }
                }
            }
            break;
    }
    if (Object.keys(values).length === 0 && values.constructor === Object){
        return (
            <div className="content" id="competence">
                <PflegenKopfzeile_Kompetenz accessStatus={accessStatus} readOnly={true} businessObjectFunctions={{onDelete, onSave, onCreate}}/>
                <div className="inner-content">
                    {errorMsg && <div id="server-error"> <br /> <strong className="error">{errorMsg}</strong></div>}
                    <div className="not-found">
                        <div><b>{category} / {id}</b> wurde nicht gefunden.</div>
                    </div>
                </div>
                <PflegenFusszeile accessStatus={accessStatus} modDate={'00.00.0000 00:00'} />
            </div>
        )
    } else {
        return(
            <div className="content" id="competence">
                <PflegenKopfzeile_Kompetenz id={id} role={role} loggedInUsername={username} accessStatus={accessStatus} businessObject={businessObject} category={category} object={values} readOnly={readOnly} somethingChanges={somethingChanges(accessStatus, (accessStatus === data.accessStatus.create ? values.new : values), object, category)} businessObjectFunctions={{onDelete, onSave, onCreate, onRelease}} filterQuery={searchFilterQuery} />
                <div className="inner-content">
                {(fieldDataError && !errorMsg) && <div><strong className="error">{fieldDataError}</strong><br /> <br /> </div>}
                {errorMsg && <div id="server-error"><strong className="error">{errorMsg}</strong></div>}
                {showDeletePopUp ? <PopUp title='Sind Sie sich sicher?' text={<div>Sie möchten die Kompetenz, <strong>{category}/{id} löschen</strong></div>} close={onDelete_sure} abort={() => setShowDeletePopUp(false)} closeText="Löschen" /> : null}
                {accessStatus === data.accessStatus.create && <h3>Neue {category[0].toUpperCase() + category.substring(1)} anlegen</h3>}
                {error && <div id="client-error"> <br /> <small className="error">{error}</small></div>}
                    <div className="main-inputs">
                        {accessStatus === data.accessStatus.create &&<RowInput value={values['new']} name={'kompetenz'} readOnly={checkReadOnly(category, readOnly, accessStatus)} setValue={v => setValues({...values, new: v})} defaultObject={{...getDefaultObject()}} presentationObject={getFieldsObject()} optionsObject={optObject} disabledObject={disObject} invalidObject={checkInvalidation(category, failedValidation, setFailedValidation, values.new)} />}
                        {accessStatus === data.accessStatus.update &&<RowInput value={values} name={'kompetenz'} readOnly={checkReadOnly(category, readOnly, accessStatus)} setValue={v => setValues(v)} presentationObject={getFieldsObject()} optionsObject={optObject} disabledObject={disObject} invalidObject={checkInvalidation(category, failedValidation, setFailedValidation, values)} />}
                    </div>
                </div>
                <PflegenFusszeile accessStatus={accessStatus} modDate={(object ? object.modDate : '00.00.0000 00:00')} />
            </div>
        )
    }
}

/**
 * Weist den verwendeten lokalen Variablen, variablen aus dem State zu
 * 
 * @param {*} state - aktueller State
 * @param {*} ownProps - weitere Variablen
 * @returns den aktuellen State als Komponentenvariablen
 */
 const mapStateToProps = (state, ownProps) => ({
    role: state.user.role,
    username: state.user.username,
    errorMsg: state.businessobjects.error,
    successMsg: state.businessobjects.success,
    fieldData: state.fielddata.data,
    fieldDataError: state.fielddata.error,
    list: state.competences.list,
    searchFilterQuery: state.businessobjects.searchFilterQuery,
    ...ownProps
})

/**
 * Weist den verwendeten lokalen Funktionen, Funktionen aus dem Redux zu
 * 
 * @returns Die gemappten Funktionen
 */
const mapDispatchToProps = dispatch => ({
    getList: () => dispatch(sendGetCompetenceListRequest()),
    sendDelete: (id, category) => dispatch(sendDeleteCompetenceRequest(id, category)),
    sendUpdate: (competence, category) => dispatch(sendUpdateCompetenceRequest(competence, category)),
    sendCreate: (competence, category) => dispatch(sendCreateCompetenceRequest(competence, category)),
}) 

export default connect(mapStateToProps, mapDispatchToProps)(Pflegen_Kompetenz)