import React from 'react'
import data from './../../data/_data';

/**
 * Rendert einen Tag mit der aktuellen Version der Anwendung
 * 
 * @author DHR
 * @returns {HTML} Versionstag
 */
function VersionInfo(){


    return(
        <div className="versionInfo" style={{position: 'absolute', top: 0, right: '15px', color: '#A9BED6', backgroundColor: '#333333', fontSize: 'small', zIndex: '98', padding: '5px', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px'}}>
            beckProfile{data.DEBUG ? ' Preview' : ''}: {data.version}
        </div>
    )
}
export default VersionInfo