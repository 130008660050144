import { SERVER, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_REQUEST_START, BUSINESSOBJECT_REQUEST_ERROR, BUSINESSOBJECT_RESPONSE_ERROR, BUSINESSOBJECT_SUCCESS, SET_LAYOUT, SET_LAYOUTS, LAYOUT_CREATE_SUCCESS, SET_PROFILE } from "./constants"
import { getApiRequestBuilder } from "./util"
import {setSuccess} from './businessobjects'

const requestBuilder = getApiRequestBuilder(BUSINESSOBJECT_REQUEST_START, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_REQUEST_ERROR, BUSINESSOBJECT_RESPONSE_ERROR)
export const requestURL_get = SERVER + "layout"
const requestURL_create = SERVER + "layout"
const requestURL_delete = SERVER + "layout"
// const requestURL_update = SERVER + "layout"

const setLayoutList = (jsonData) => ({type: SET_LAYOUTS, list:jsonData})

 export function sendGetLayoutListRequest(){
    
    return (requestBuilder(
        (requestURL_get),
        {
        method: 'GET', 
        headers: { credentials: "same-origin", 'Accept': 'application/json'},
        }, setLayoutList)
    )
}

const setLayoutFile = (id, layoutFilename, layoutData, styleFilename, styleData) => ({type: SET_LAYOUT, id, layoutFilename, layoutData, styleFilename, styleData})

export function sendLayoutDownload(id){
    return(requestBuilder(
        (requestURL_create + '/' + id + '/' + 'layout'), 
        {
            method: 'GET', 
            headers: { credentials: "same-origin", 'Accept': 'application/octet-stream' },
        }, (filename, data) => (sendStyleDownload(id, filename, data)))
    )
}

function sendStyleDownload(id, layoutFilename, layoutData){
    return(requestBuilder(
        (requestURL_create + '/' + id + '/' + 'style'), 
        {
            method: 'GET', 
            headers: { credentials: "same-origin", 'Accept': 'application/octet-stream' },
        }, (filename, data) => (setLayoutFile(id, layoutFilename, layoutData, filename, data)))
    )
}


 export function sendDeleteLayoutRequest(id){
    return(requestBuilder(
        (requestURL_delete + '/'+id), 
        {
            method: 'DELETE', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' }
        }, setSuccess)
    )
}

 export function sendCreateLayoutRequest(layout, layoutFile, styleFile){
    // let uLayout = {...layout}
    // delete uLayout.datei.dateiData
    return(requestBuilder(
        (requestURL_create), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(layout)
        }, (location) => (sendLayoutFileUpload(location.slice(location.lastIndexOf('/')+1), layoutFile, styleFile)))//layout.datei.dateiData
    )
}

export function sendLayoutFileUpload(id, file, styleFile){
    let uFile = new FormData()
    uFile.append('file', file)
    return(requestBuilder(
        (requestURL_create + '/' + id + '/' + 'layout'), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin" }, //'Content-Type': 'multipart/form-data'
            body: uFile
        }, () => (sendStyleFileUpload(id, styleFile)))
    )
}

export function sendStyleFileUpload(id, file){
    let uFile = new FormData()
    uFile.append('file', file)
    return(requestBuilder(
        (requestURL_create + '/' + id + '/' + 'style'), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin" }, //'Content-Type': 'multipart/form-data'
            body: uFile
        }, setSuccess)
    )
}

export const setBeckProfile = (profile) => ({type: SET_PROFILE, profile})

/**
 * Zugriff auf den aktuellen State und erweitern bzw. modifizieren dessen
 * Reducer by action.type
 * 
 * @see constants.js
 * @param {*} state - takes the currentstate
 * @param {*} action - takes some type and some values as payload
 * @returns the new state 
 */
 export const layouts = (state = { }, { type, ...payload }) => {
    switch (type) {
        case SET_LAYOUTS:
            return {
                ...state, 
                list: payload.list,
            }
        case SET_LAYOUT:
            let tempSpecific = {...(state.list ? state.list.find(l => l.id === payload.id) : {})}
            if (Object.keys(tempSpecific).length > 0){
                tempSpecific.layoutDatei = {...tempSpecific.layoutDatei, dateiData: {data: payload.layoutData, filename: payload.layoutFilename}}
                tempSpecific.styleDatei = {...tempSpecific.styleDatei, dateiData: {data: payload.styleData, filename: payload.styleFilename}}
            }
            return {
                ...state,
                specific: (Object.keys(tempSpecific).length > 0 ? tempSpecific : {})
            }
        case SET_PROFILE:
            return {
                ...state,
                beckProfile: payload.profile
            }
        default:
            return state
    }
}