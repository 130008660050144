import React, {useState, useEffect} from 'react'

import PflegenKopfzeile_Berater from './Pflegen/Berater/PflegenKopfzeile_Berater'
import PflegenKopfzeile_Beraterprojekt from './Pflegen/Beraterprojekt/PflegenKopfzeile_Beraterprojekt'
import PflegenKopfzeile_Kunde from './Pflegen/Kunde/PflegenKopfzeile_Kunde'
import PflegenKopfzeile_Kundenprojekt from './Pflegen/Kundenprojekt/PflegenKopfzeile_Kundenprojekt'
import PflegenKopfzeile_Kompetenz from './Pflegen/Kompetenz/PflegenKopfzeile_Kompetenz'

import data from '../data/_data'
import businessobject_data from '../data/businessobject_data';
import ModerationKopfzeile from './Moderieren/ModerationKopfzeile';
import DruckenKopfzeile from './Drucken/DruckenKopfzeile'

/**
 * Kopfzeilen-Komponente für die Seiten "Beraterprofil Plegen", Rendert alle folegenden Komponenten zur Einstellung der Filter- und Suchkriterien
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {String} accessStatus - status des Pflegens des Geschaeftsobjektes (suche, neu, gefuehrt)
 * @param {String} businessObject - aktuell gewaehltes Geschaeftsobjekt
 * @param {Object} object - das ausgewaehlte Geschaeftsobjekt
 * @param {Object} businessObjectFunctions - Objekt als Sammlung der Funktionen zum Pflegen im Parent, dem Subject (e.g. Anlegen)
 * @param {Function} onSelect - Funktion zum setzen des ausgewählten Objektes
 * @param {String} onSelectCategory - Key des CategoryFilter bei onSelect
 * @param {Function} setLockedState - Funktion zum setzen von locked
 * @param {Function} setSearchAttrState - Funktion zum setzen des Attributes der Suche
 * @param {Function} setSearchValState - Funktion zum setzen des Wertes zur Suche
 * @param {Function} setStatusFilterState - Funktion zum setzen des Status zum Filtern in der Suche
 * @param {Function} setBooleanFilterState - Funktion zum setzen des Status zum Filtern in der Suche
 * @param {Function} setTab - Funktion zum festlegen des ausgeaehlten Reiters
 * @param {Array} tabs - zur Verfügung stehende Reiter
 * @param {String} tab - ausgewaehlter Reiter
 * @param {String} successMsg - SuccesMsg der API bei Erfolg
 * @param {String} loggedInUsername - Username des aktuell angemeldeten Benutzers
 * @param {Object} competenceFilterData - optionen fürs Dropdown zum auswaehlen zum Filtern
 * @param {Function} setSearchCompetenceAttrState - Funktion zum setzen des Attributes der Kompetenz zum Filtern in der Suche
 * @param {Function} setSearchCompetenceValState - Funktion zum setzen des Wertes der Kompetenz, passen dzum Attribut, zum Filtern in der Suche
 * @param {Function} setSearchStartDateState - Funktion zum setzen des Startdatums zum Filtern in der Suche
 * @param {Function} setSearchEndDateState - Funktion zum setzen des Enddatums zum Filtern in der Suche
 * @param {Function} setCategoryFilterState - Funktion zum setzen der Category zum Filtern in der Suche
 * @param {String} categoryFilterState - Key des CategoryFilter
 * @see Pflegen 
 * @see Suche als Parent
 * @returns {HTML} Kopfzeile zum Filtern der Suche oder wechseln der Reiter während der Bearbeitung
 */
function Suche_Kopfzeile({role, accessStatus, businessObject, object, fkLists, businessObjectFunctions, onSelect, onSelectCategory, onSelectPrefiltered, setLockedState, setSearchAttrState, setSearchValState, setSearchAttrState2, setSearchValState2, setCustomFilterState, setStatusFilterState, setBooleanFilterState, setTab, tabs, tab, successMsg, loggedInUsername, competenceFilterData, categoryFilterData, setSearchFKAttrState, setSearchStartDateState, setSearchEndDateState, setCategoryFilterState, categoryFilterState, guidedProgessState, setGuidedProgessState, maxguidedProgess, consultantObject, consultantprojectObject, filterQuery, setFilterQuery}){
    // states to manage the Filter
    const [locked, setLocked] = useState(false)
    const [searchAttr, setSearchAttr] = useState((businessObject === data.businessObject.competence)?(filterQuery?.searchAttr ? filterQuery.searchAttr : 'titel'):( businessobject_data[businessObject] ? Object.keys(businessobject_data[businessObject].searchAttributes)[0]: ''))
    const [searchVal, setSearchVal] = useState((filterQuery?.searchVal ? filterQuery.searchVal : ''))
    const [searchAttr2, setSearchAttr2] = useState('')
    const [searchVal2, setSearchVal2] = useState('')
    const [searchFKAttr, setSearchFKAttr] = useState('')
    const [searchStartDate, setSearchStartDate] = useState('')
    const [searchEndDate, setSearchEndDate] = useState(-1)
    const [statusFilter, setStatusFilter] = useState({})
    const [booleanFilter, setBooleanFilter] = useState({})
    const [customFilter, setCustomFilter] = useState(false)
    // reset the state and the parent state
    const onFilterReset = () => {
        setLocked(false)
        setSearchAttr('')
        setSearchVal('')
        setSearchAttr2('')
        setSearchVal2('')
        setSearchStartDate('')
        setSearchEndDate('')
        setStatusFilter({})
        setSearchFKAttr('')
        setBooleanFilter({})
        setCustomFilter(false)

        setLockedState(false)
        setSearchAttrState('')
        setSearchValState('')
        setSearchAttrState2('')
        setSearchValState2('')
        setSearchStartDateState('')
        setSearchEndDateState('')
        setStatusFilterState({})
        setSearchFKAttrState('')
        setBooleanFilterState({})
        setCustomFilterState(false)

        setFilterQuery({})

        loadDefaultFilter()
    }
    // set the parent state
    const onFilter = (sA, sV, sED, cF) => {
        setLockedState(locked)
        if (typeof sA === 'string' && sA){setSearchAttrState(sA)}else{setSearchAttrState(searchAttr)}
        if (sV){setSearchValState(sV)}else{setSearchValState(searchVal)}
        if(sED){setSearchEndDateState(sED)}else{setSearchEndDateState(searchEndDate)}
        if (cF){setCustomFilterState(cF)}else{setCustomFilterState(customFilter)}
        setSearchAttrState2(searchAttr2)
        setSearchValState2(searchVal2)
        setSearchStartDateState(searchStartDate)
        setStatusFilterState(statusFilter)
        setSearchFKAttrState(searchFKAttr)
        setBooleanFilterState(booleanFilter)
    }

    const loadDefaultFilter = () => {
        switch(businessObject){
            case data.businessObject.drucken:
                setSearchAttr2('erfahrung')
                setSearchEndDate(-1)
                setSearchAttrState2('erfahrung')
                setSearchEndDateState(-1)
            case data.businessObject.consultant:
                setSearchEndDate(-1)
            default:
                break;
        }
    }

    if (filterQuery && filterQuery.searchVal && filterQuery.searchVal !== searchVal){
        setSearchVal(filterQuery.searchVal)
        setSearchAttr(filterQuery.searchAttr)
    }

    useEffect(() => {loadDefaultFilter()}, [])

    // reset Filter when changing the Search
    useEffect(() => {if (setSearchAttr&&setSearchAttrState&&setSearchVal&&setSearchValState){setSearchAttr('');setSearchAttrState('');setSearchVal('');setSearchValState('')}}, [categoryFilterState])
    useEffect(() => {setSearchVal('')}, [searchAttr])
    useEffect(() => {if (businessObject === data.businessObject.competence)setSearchAttr('titel')}, [categoryFilterState])
    useEffect(()=>{if (businessObject!==data.businessObject.gefuehrt){onFilterReset()}}, [businessObject])
    
    const stateProps = {onSelect:onSelect, locked:locked, searchAttr:searchAttr, searchVal:searchVal, searchAttr2:searchAttr2, searchVal2:searchVal2, setSearchFKAttr:setSearchFKAttr, searchStartDate:searchStartDate, searchEndDate:searchEndDate, statusFilter:statusFilter, booleanFilter:booleanFilter, categoryFilter:categoryFilterState, categoryFilterData:categoryFilterData, competenceFilterData:competenceFilterData}
    const stateSetProps = {onSelectCategory:onSelectCategory, setLocked:setLocked, setSearchAttr:setSearchAttr, setSearchVal:setSearchVal, setSearchAttr2:setSearchAttr2, setSearchVal2:setSearchVal2, setSearchFKAttr:setSearchFKAttr, setSearchStartDate:setSearchStartDate, setSearchEndDate:setSearchEndDate, setStatusFilter:setStatusFilter, setBooleanFilter:setBooleanFilter, setCategoryFilter:setCategoryFilterState}
    const stateSetPropsFunctions = {onFilter:onFilter, onFilterReset:onFilterReset, loggedInUsername:loggedInUsername}
    const editProps = {setTab:setTab, tabs:tabs, tab:tab, loggedInUsername:loggedInUsername}

    // return header according to the current businessobject
    function switchBusinessobject(cls, accessStatus){
        switch (cls){
            case data.businessObject.consultant:
                return <PflegenKopfzeile_Berater role={role} accessStatus={accessStatus} onSelectPrefiltered={onSelectPrefiltered} {...stateProps} {...stateSetProps} successMsg={successMsg} {...stateSetPropsFunctions} />
                // switch (accessStatus){
                //     case data.accessStatus.search:
                //         return <PflegenKopfzeile_Berater role={role} accessStatus={accessStatus} {...stateProps} {...stateSetProps} successMsg={successMsg} {...stateSetPropsFunctions} />
                //     default:
                //         return <PflegenKopfzeile_Berater role={role} accessStatus={accessStatus} object={object} successMsg={successMsg} {...editProps} />
                // }                
            case data.businessObject.consultantproject:
                return <PflegenKopfzeile_Beraterprojekt role={role} accessStatus={accessStatus} onSelectPrefiltered={onSelectPrefiltered} {...stateProps} {...stateSetProps} successMsg={successMsg} {...stateSetPropsFunctions} />
                // switch (accessStatus){
                //     case data.accessStatus.search:
                //         return <PflegenKopfzeile_Beraterprojekt role={role} accessStatus={accessStatus} {...stateProps} {...stateSetProps} successMsg={successMsg} {...stateSetPropsFunctions} />
                //     default:
                //         return <PflegenKopfzeile_Beraterprojekt role={role} accessStatus={accessStatus} object={object} successMsg={successMsg} {...editProps} />
                // }
            case data.businessObject.contactperson:
            case data.businessObject.customer:
                return <PflegenKopfzeile_Kunde role={role} accessStatus={accessStatus} onSelectPrefiltered={onSelectPrefiltered} businessObject={cls} {...stateProps} {...stateSetProps} successMsg={successMsg} {...stateSetPropsFunctions} />
                // switch (accessStatus){
                //     case data.accessStatus.search:
                //         return <PflegenKopfzeile_Kunde role={role} accessStatus={accessStatus} businessObject={cls} {...stateProps} {...stateSetProps} successMsg={successMsg} {...stateSetPropsFunctions} />
                //     default:
                //         return <PflegenKopfzeile_Kunde role={role} accessStatus={accessStatus} object={object} successMsg={successMsg} {...editProps} />
                // }
            case data.businessObject.customerproject:
                return <PflegenKopfzeile_Kundenprojekt role={role} accessStatus={accessStatus} onSelectPrefiltered={onSelectPrefiltered} {...stateProps} {...stateSetProps} successMsg={successMsg} {...stateSetPropsFunctions} />
                // switch (accessStatus){
                //     case data.accessStatus.search:
                //         return <PflegenKopfzeile_Kundenprojekt role={role} accessStatus={accessStatus} {...stateProps} {...stateSetProps} successMsg={successMsg} {...stateSetPropsFunctions} />
                //     default:
                //         return <PflegenKopfzeile_Kundenprojekt role={role} accessStatus={accessStatus} object={object} successMsg={successMsg} {...editProps} />
                // }
            case data.businessObject.competence:
                return <PflegenKopfzeile_Kompetenz role={role} accessStatus={accessStatus} onSelectPrefiltered={onSelectPrefiltered} {...stateProps} {...stateSetProps} successMsg={successMsg} {...stateSetPropsFunctions} filterQuery={filterQuery} />
                // switch (accessStatus){
                //     case data.accessStatus.search:
                //         return <PflegenKopfzeile_Kompetenz role={role} accessStatus={accessStatus} {...stateProps} {...stateSetProps} successMsg={successMsg} {...stateSetPropsFunctions} />
                //     default:
                //         return <PflegenKopfzeile_Kompetenz role={role} accessStatus={accessStatus} object={object} successMsg={successMsg} {...editProps} />
                // }
            case data.businessObject.moderation:
                return <ModerationKopfzeile role={role} accessStatus={data.accessStatus.custom} onSelectPrefiltered={onSelectPrefiltered} {...stateProps} {...stateSetProps} successMsg={successMsg} {...stateSetPropsFunctions} />
            case data.businessObject.drucken:
                return <DruckenKopfzeile role={role} accessStatus={data.accessStatus.search} onSelectPrefiltered={onSelectPrefiltered} {...stateProps} {...stateSetProps} successMsg={successMsg} {...stateSetPropsFunctions} setOnlyAvailableConsultant={setCustomFilter} onlyAvailableConsultant={customFilter} />
            default:
                return (
                    <div className="content-header">
                    </div>
                )
        }
    }

    return(
        switchBusinessobject(businessObject, accessStatus)
    )
}

export default Suche_Kopfzeile