/**
 * Console-Logging für den Store/Redux, bei jeder action
 * 
 * @author DHR
 * @param {*} store - aktueller Store
 */
export const logger = store => next => action => {
    const { type, ...rest } = action
    console.log('dispatch', type, rest)
    next(action)
    console.log('next state', store.getState())
}