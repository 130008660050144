import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from './components/_helpers/PrivateRoute'

import Login from './Login'
//import Profile from './components/Drucken/Profile'
const Profile = React.lazy(() => import(/* webpackChunkName: "Profile" */'./components/Drucken/Profile'))
//import InnerApp from './InnerApp'
const InnerApp = React.lazy(() => import(/* webpackChunkName: "InnerApp" */'./InnerApp'))

/**
 * generiert durch create-react-app, leicht modified
 * ZugriffsComponent, überprüft Login und leitet weiter
 * Übernimmt das Routing
 * 
 * @author DHR
 * @returns {HTML} in der Index.js gerendert wird
 */
function App() {
    
    return (
        <Suspense fallback={<div>Loading...</div>} >
            <Switch>
                <Route path='/login' component={Login} />
                <Route path='/beckprofile/:id' component={Profile} />
                <PrivateRoute path='/*' component={InnerApp} />
            </Switch>
        </Suspense>
    );
}

export default App;
