import thunk from 'redux-thunk';
import {logger} from './logger';
import { refresher } from './refresher';

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import {UNSET_USER} from './constants';

import {user} from './user';
import {users} from './users';
import {consultants} from './consultants';
import {consultantprojects} from './consultantprojects';
import {customers} from './costumers';
import {customerprojects} from './customerprojects';
import {competences} from './competences';
import {fielddata} from './fielddata';
import {layouts} from './layouts';
import { businessobjects } from './businessobjects';
import {loadingReducer} from './loadingphase'
//import {} from './'; all API-Request classes here

// DEVELOPMENT - defaultState as second Parameter for createStore
// import {defaultState} from '../_development'

/**
 * Custom Store für Redux, weitgehend standard
 * Überschreibe Standard Redux
 * Dabei wird der Reducer des User im SessionStorage des Browsers Persisteiert,
 * Der Nutzer bleibt trotz Seitenaktualisierung eingeloggt
 * 
 * Kombniert alle Reducer (./redux/...) und ergänzt Thunk sowie einen Logger als Middleware
 * 
 * @author DHR
 */

const userPersistenceConfig ={
    key: 'userState',
    blacklist: ['error'],
    storage: storage
};

// weiter imports
const { createStore, combineReducers, applyMiddleware } = require("redux");

//combine Reducers
const appReducer = combineReducers({
    user: persistReducer(userPersistenceConfig, user), users, businessobjects, consultants, consultantprojects, customers, customerprojects, competences, fielddata, layouts, loading: loadingReducer // all API-Request classes (Reducers) here
})

// Reducer der über den einzelnen Reducerns liegt
const rootReducer = (state, action) => {
    if (action.type === UNSET_USER) {
        storage.removeItem('persist:userState');
        return appReducer(undefined, action) //state = undefiend will reset the hole state to initial
    }

    return appReducer(state, action)
}

// createStore
// export const store = createStore(rootReducer, defaultState, applyMiddleware(thunk, logger, refresher));
export const store = createStore(rootReducer, applyMiddleware(thunk, logger, refresher));
// persitentStore, der State des User
export const persistedStore = persistStore(store);