import React from 'react'
import { TooltipInfo } from './InnerContent'

/**
 * PopUp-Komponente
 * Zeigt ein PopUp an und wird mit Variablen Inhalt gefüllt
 * 
 * @author DHR
 * @param {HTML} title - Überschrift
 * @param {HTML} text - Beschreibungstext
 * @param {HTML} content - Inhalt (z.b. mehr Text oder ein <form/>)
 * @param {Function} close - Funktion die zum Erfolgreichen Schließen des PopUps führt, Buttontext: Individuell
 * @param {String} closeText - Text des Buttons der CloseFunktion
 * @param {Function} abort - Funktion die zum Abgebrochenen Schließen des PopUps führt, Buttontest: "Abbrechen"
 * @returns {HTML} PopUp
 */
function PopUp({important, title, name, text, content, close, closeText, abort, customClose = null}){

    return(
        <div className="pop-up-box" style={(important ? {zIndex:999} : null)}>
            <div className="pop-up">
                <h2>{title}</h2><TooltipInfo name={name} />
                <span>{text}</span>
                {content && <div className="pop-up-content">
                    {content}
                </div>}
                <div className="pop-up-buttons">
                    {abort && <button onClick={abort} >Abbrechen</button>}
                    {(close ? <button onClick={close} >{closeText}</button> : customClose)}
                </div>
            </div>
        </div>
    )
}

export default PopUp