import { BUSINESSOBJECT_REQUEST_ERROR, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_RESPONSE_ERROR, BUSINESSOBJECT_SUCCESS, BUSINESSOBJECT_UNSET_SUCCESS, BUSINESSOBJECT_FILE_REQUEST_ERROR, BUSINESSOBJECT_FILE_RESPONSE_ERROR } from "./constants"
import { SET_COMPETENCES, SET_CONSULTANTS, SET_CONSULTANT, SET_CONSULTANT_FILE, SET_CONSULTANT_PIC, CONSULTANT_CREATE_SUCCESS, SET_CUSTOMERS, SET_CUSTOMERPROJECTS, SET_CONSULTANTPROJECTS, SET_CONSULTANTPROJECT } from './constants'
import {SET_SEARCH_FILTER_QUERY} from './constants'

import message_data from '../data/message_data'

/**
 * Verwaltet die success und error Messages im Redux für die Geschäftsobjekte bei Kommunikation mit der API
 * 
 * @author DHR
 */
/**
 * Generische onSuccess-Methode, setzt die entsprechende Message
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben.
 * 
 * @returns action(type) zum aktualisieren des States
 */
export const setSuccess = () => ({type: BUSINESSOBJECT_SUCCESS})

/**
 * Setzt die success Message zurück
 * 
 * @returns action(type) zum aktualisieren des States
 */
export const unsetSuccess = () => ({type: BUSINESSOBJECT_UNSET_SUCCESS})

export const setSearchFilterQuery = (query) => ({type: SET_SEARCH_FILTER_QUERY, query})

/**
 * Zugriff auf den aktuellen State und erweitern bzw. modifizieren dessen
 * Reducer by action.type
 * 
 * @see constants.js
 * @param {*} state - takes the currentstate
 * @param {*} action - takes some type and some values as payload
 * @returns the new state 
 */
export const businessobjects = (state = { }, { type, ...payload }) => {
    switch (type) {
        case BUSINESSOBJECT_SUCCESS:
            return {
                ...state,
                error: '',
                success: message_data.success.redux.default
            }
        case BUSINESSOBJECT_UNSET_SUCCESS:
        return {
            ...state,
            success: ''
        }
        case BUSINESSOBJECT_FILE_REQUEST_ERROR:
        case BUSINESSOBJECT_REQUEST_ERROR:
            if (payload.errorMessage.responseStatusCode === 403){
                return {
                    ...state,
                    error: message_data.error.redux.unauthorized
                }
            }
            if (payload.errorMessage.responseStatusCode === 409){
                return {
                    ...state,
                    error: message_data.error.redux.optimisticLockFail
                }
            }
            return {
                ...state,
                error: message_data.error.redux.default + " " + payload.errorMessage.responseStatusCode + " - " + payload.errorMessage.responseStatusText
            }
        case BUSINESSOBJECT_RESPONSE_ERROR:
            return {
                ...state,
                error: payload.errorMessage
            }
        case BUSINESSOBJECT_REQUEST_FAILED:
            return {
                ...state,
                error: message_data.error.redux.unavailable
            }
        case BUSINESSOBJECT_FILE_RESPONSE_ERROR:
            return {
                ...state,
                error: message_data.error.redux.file
            }
        case SET_COMPETENCES:
        case SET_CONSULTANTS:
        case SET_CONSULTANT:
        case SET_CONSULTANT_FILE:
        case SET_CONSULTANT_PIC:
        case CONSULTANT_CREATE_SUCCESS:
        case SET_CUSTOMERS:
        case SET_CUSTOMERPROJECTS:
        case SET_CONSULTANTPROJECTS:
        case SET_CONSULTANTPROJECT:
            return {
                ...state,
                error: ''
            }
        case SET_SEARCH_FILTER_QUERY:
            return {
                ...state,
                searchFilterQuery: payload.query
            }
        default:
            return state
    }
}