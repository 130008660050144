import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect} from 'react-router-dom'

import data from '../../data/_data'
import NotFound from '../NotFound'

/**
 * Alle Routen durch diesen Tag benötigen eine Authentifizierung durch ein Login
 * Bei Erfolgreicher Authentifizierung durch den ROLENAME wird zur gegebenen Komponente geroutet
 * sonst Redirect zur Anmeldeseite
 * Entspricht die Rolle des Benutzers nicht den Erlaubte für die jeweilige Route, so Redirect zu notFound mit notAuth
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers, aus dem State
 * @param {boolean} temporaryPasswort - einmalPasswort des angemeldeten Benutezrs aus dem State
 * @param {Object} exclusiveRole - Erlaubte Rollen
 * @param {Component} component - Die zu rendernde Komponente
 * @param {*} [payload] - Eigenschaften der Component
 * @returns {HTML} Redirect
 */

const roles = Object.values(data.roles)
const PrivateRoute = ({role, temporaryPasswort = false, exclusiveRole = roles, component: Component, ...payload}) => {
    if ((exclusiveRole !== roles) && !temporaryPasswort){
        return (
            <Route 
                {...payload}
                render={() => ((exclusiveRole.includes(role)) ? 
                    (<Component {...payload}/>) :
                    (<NotFound notAuth={true} />)
                    )
                }
            />
        )
    } else {
        return (
            <Route 
                {...payload}
                render={() => ((exclusiveRole.includes(role) && !temporaryPasswort) ? 
                    (<Component {...payload}/>) :
                    (<Redirect to='/login'/>)
                    )
                }
            />
        )
    }
}

/**
 * Weist den verwendeten lokalen Variablen, variablen aus dem State zu
 * 
 * @param {*} state - aktueller State
 * @param {*} ownProps - weitere lokale Variablen
 * @returns den aktuellen State als Komponentenvariablen
 */
const mapStateToProps = (state, ownProps) => ({
    role: state.user.role,
    temporaryPasswort: state.user.temporaryPasswort,
    ...ownProps
})

export default connect(mapStateToProps)(PrivateRoute)