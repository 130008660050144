import { SERVER, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_REQUEST_START, BUSINESSOBJECT_REQUEST_ERROR, BUSINESSOBJECT_RESPONSE_ERROR, BUSINESSOBJECT_SUCCESS, SET_CONSULTANTPROJECT, SET_CONSULTANTPROJECTS, CONSULTANTPROJECT_CREATE_SUCCESS, CONSULTANTPROJECT_UPDATE_SUCCESS, SET_CONSULTANTPROJECT_FILE, SET_CONSULTANTPROJECTS4MODERATION, SET_CONSULTANTPROJECTSDETAIL } from "./constants"
import { getApiRequestBuilder } from "./util"

import message_data from '../data/message_data'
import { setSuccess } from './businessobjects';

/**
 * Redux API um Beraterprojekte am Backend zu verwalten
 * 
 * @author DHR
 */
// build the requestBuilder with the Failure types for the Reducer
const requestBuilder = getApiRequestBuilder(BUSINESSOBJECT_REQUEST_START, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_REQUEST_ERROR, BUSINESSOBJECT_RESPONSE_ERROR)
export const requestURL_get = SERVER + "beraterprojekt"
export const requestURL_get4Moderation = SERVER + "beraterprojekt/moderation"
const requestURL_create = SERVER + "beraterprojekt"
const requestURL_delete = SERVER + "beraterprojekt"
const requestURL_update = SERVER + "beraterprojekt"

/**
 * Setzt die Id des erstellten Beraterprojekt in den State
 * 
 * @param {int} id - id des erstellten Beraterprojekt
 * @returns action(type) zum aktualisieren des States
 */
const setCreateSuccess = (id) => ({type: CONSULTANTPROJECT_CREATE_SUCCESS, id})
/**
 * Setzt die Id des geupdateten Beraterprojekt in den State
 * 
 * @param {int} id - id des geupdateten Beraterprojekt
 * @returns action(type) zum aktualisieren des States
 */
const setUpdateSuccess = (id) => ({type: CONSULTANTPROJECT_UPDATE_SUCCESS, id})

/**
 * Setzt das Json Objekt der Response als den spezifisch angefragten Beraterprojekt
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben.
 * 
 * @param {Object} jsonData - Responsejson.data
 * @returns action(type,...payload) zum aktualisieren des States
 */
 const setConsultantproject = (id, jsonData) => ({type: SET_CONSULTANTPROJECT, specific:jsonData})

/**
 * Setzt das Json Objekt der Response als Liste aller Beraterprojekte
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben.
 * 
 * @param {Object} jsonData Responsejson.data
 * @returns action(type,...payload) zum aktualisieren des States
 */
const setConsultantprojectList = (jsonData) => ({type: SET_CONSULTANTPROJECTS, list:jsonData})
const setConsultantproject4ModerationList = (jsonData) => ({type: SET_CONSULTANTPROJECTS4MODERATION, list:jsonData})
const setConsultantprojectDetailList = (detailed, consultantId) => ({type: SET_CONSULTANTPROJECTSDETAIL, detailed:detailed, consultantId:consultantId})

/**
 * Sendet Request um die Liste aller Beraterprojekte zu erhalten
 * 
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
 export function sendGetConsultantprojectListRequest(){
    
    return (requestBuilder(
        (requestURL_get),
        {
        method: 'GET', 
        headers: { credentials: "same-origin", 'Accept': 'application/json'},
        }, setConsultantprojectList)
    )
}
export function sendGetConsultantproject4ModerationListRequest(){
    
    return (requestBuilder(
        (requestURL_get4Moderation),
        {
        method: 'GET', 
        headers: { credentials: "same-origin", 'Accept': 'application/json'},
        }, setConsultantproject4ModerationList)
    )
}

export function sendGetConsultantprojectDetailListRequest(id, consultantId){
    return requestBuilder(
        (requestURL_get + '/'+id),
        {
        method: 'GET', 
        headers: { credentials: "same-origin", 'Accept': 'application/json'},
        }, (detailedProject) => setConsultantprojectDetailList(detailedProject, consultantId)
    )
}
/**
 * Sendet Request um die Details das eindeutige Beraterprojekt zu erhalten
 * 
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
 export function sendGetConsultantprojectRequest(id){
    return (requestBuilder(
        (requestURL_get + '/'+id),
        {
        method: 'GET', 
        headers: { credentials: "same-origin", 'Accept': 'application/json'},
        }, (j) => setConsultantproject(id, j))
    )
}

/**
 * Setzt die Daten der angefragten Datei in den State
 * 
 * @param {String} filename - name der angefragten Datei
 * @param {Object} data - die Datei
 * @param {int} id - id des Beraterprojekt
 * @returns action(type) zum aktualisieren des States
 */
const setConsultantprojectFile = (filename, data, id) => ({type: SET_CONSULTANTPROJECT_FILE, id, filename, data})

/**
 * Sendet Request um eine Datei zum Berater zu erhalten
 * 
 * @param {int} id - id des Kundenprojekt
 * @param {int} fileId - id der anzufragenden Datei
 * @param {boolean} profilepicture - gibt an ob es sich um ein Profilbild handelt
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendFileDownload(id, fileId,){
    return(requestBuilder(
        (requestURL_create + '/' + id + '/datei/'+fileId), 
        {
            method: 'GET', 
            headers: { credentials: "same-origin", 'Accept': 'application/octet-stream' },
        }, (filename, data) => (setConsultantprojectFile(filename, data, id)))
    )
}

/**
 * Sendet Request um das Beraterprojekt zu löschen
 * 
 * @param {String} id - id des zu löschenden Beraterprojekt
 * @returns Redux Action
 */
 export function sendDeleteConsultantprojectRequest(id){
    return(requestBuilder(
        (requestURL_delete + '/'+id), 
        {
            method: 'DELETE', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' }
        }, setSuccess)
    )
}

/**
 * Sendet Request um Attribute des Beraterprojektes neu zusetzen
 * 
 * @param {Object} consultantproject - Objekt des Beraterprojekts
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
 export function sendUpdateConsultantprojectRequest(consultantproject){
    return(requestBuilder(
        (requestURL_update + '/' + consultantproject.id), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(consultantproject)
        }, () => setUpdateSuccess(consultantproject.id))
    )
}
/**
 * Sendet Request um ein Beraterprojekt anzulegen
 * 
 * @param {Object} consultantproject - Objekt des Beraterprojekts
 * @returns Redux Action
 */
 export function sendCreateConsultantprojectRequest(consultantproject){
    return(requestBuilder(
        (requestURL_create), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(consultantproject)
        }, (location) => (setCreateSuccess(location.slice(location.lastIndexOf('/')+1))))
    )
}

/**
 * Sendet Request um eine Datei zu löschen
 * 
 * @param {int} id - id des Beraterprojekt
 * @param {int} fileId - id der zulöschenden Datei
 * @returns Redux Action
 */
export function sendDeleteFileRequest(id, fileId){
    return(requestBuilder(
        (requestURL_delete + '/'+ id  + '/datei/'+ fileId), 
        {
            method: 'DELETE', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' }
        }, setSuccess)
    )
}

/**
 * Übergibt die Metadaten der neuangelegten Datei der Funktion zum Upload der Datei
 * 
 * @param {int} id - id des Beraterprojekt
 * @param {Object} file - Daten der Datei
 * @param {int} fileId - id der Datei
 * @returns action(type) zum aktualisieren des States vom Upload
 */
const setFileMetaData = (id, file, fileId) => {return sendFileUpload(id, file.dateiData, fileId)}

/**
 * Sendet Request zum Anlegen von Metadaten einer Datei zu einem Beraterprojekt
 * 
 * @param {int} id - id des Beraterprojekt
 * @param {int} file - Daten der Datei
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendCreateFileRequest(id, file){
    let uFile = {...file}
    delete uFile.dateiData
    return(requestBuilder(
        (requestURL_create + '/' + id + '/datei'), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(uFile)
        }, (location) => (setFileMetaData(id, file, location.slice(location.lastIndexOf('/')+1))))
    )
}

/**
 * Sendet Request zum Hochladen der Dateidaten zu den entsprechend angelegten Metadaten
 * 
 * @param {int} id - id des projektprojekt
 * @param {Object} file - Daten der Datei
 * @param {int} fileId - id der Datei
 * @returns @returns Redux Action
 */
export function sendFileUpload(id, file, fileId){
    let uFile = new FormData()
    uFile.append('file', file)
    return(requestBuilder(
        (requestURL_create + '/' + id + '/datei/'+fileId), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin" }, //'Content-Type': 'multipart/form-data'
            body: uFile
        }, setSuccess)
    )
}


/**
 * Zugriff auf den aktuellen State und erweitern bzw. modifizieren dessen
 * Reducer by action.type
 * 
 * @see constants.js
 * @param {*} state - takes the currentstate
 * @param {*} action - takes some type and some values as payload
 * @returns the new state 
 */
 export const consultantprojects = (state = { }, { type, ...payload }) => {
    switch (type) {
        case SET_CONSULTANTPROJECTS:
            return {
                ...state, 
                list: payload.list,
                createdId: 0
            }
        case SET_CONSULTANTPROJECTS4MODERATION:
            return {
                ...state, 
                moderation: payload.list
            }
        case SET_CONSULTANTPROJECTSDETAIL:
            const tempDetailed = []
            if (payload.consultantId === state.detailedConsultantId) {
                tempDetailed.push(...(state.detailed ? state.detailed : []))
            }
            if (!state.detailed?.find(d => d.id === payload.detailed.id)){
                tempDetailed.push(payload.detailed)
            } 

            return {
                ...state, 
                detailed: tempDetailed,
                detailedConsultantId: payload.consultantId
            }
        case SET_CONSULTANTPROJECT:
            return {
                ...state,
                specific: payload.specific
            }
        case SET_CONSULTANTPROJECT_FILE:
            return {
                ...state,
                requestedFile: {data: payload.data, filename: payload.filename, id: payload.id}
            }
        case CONSULTANTPROJECT_CREATE_SUCCESS:
            return {
                ...state,
                createdId: payload.id
            }
        case CONSULTANTPROJECT_UPDATE_SUCCESS:
            return {
                ...state,
                createdId: payload.id
            }
        default:
            return state
    }
}