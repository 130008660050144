import React from 'react'
import { useParams } from 'react-router'

/**
 * 404 Not Found und 403 Forbidden Page
 * 
 * @author DHR
 * @param {boolean} notAuth - Forbidden
 * @param {String} errorMsg - error Message der API
 * @param {String} notFoundSite - Information zur Seite welche nicht erreicht werden konnte
 * @param {String} notFoundObject - Information zur Seite welche nicht erreicht werden konnte
 * @returns abhängig des Parameters eine 404 Seite oder eine 403 Seite
 */
function NotFound({notAuth = false, errorMsg, notFoundSite, notFoundObject}){
    const { notFoundSiteParam, notFoundObjectParam } = useParams()

    if (notFoundSiteParam){notFoundSite = notFoundSiteParam}
    if (notFoundObjectParam){notFoundObject = notFoundObjectParam}

    return(
        <div className="content">
            <div className="content-header" />
            <div className="inner-content">
                {errorMsg && <div> <br /> <strong className="error">{errorMsg}</strong></div>}
                {!notAuth && <div>Die Seite <b>{notFoundSite}/{notFoundObject}</b> wurde nicht gefunden.</div>}
                {notAuth && <div>Sie sind nicht berechtigt, die Seite <b>{notFoundSite}/{notFoundObject}</b> aufzurufen!</div>}
            </div>
            <div className="content-footer" />
        </div>
    )
}

export default NotFound