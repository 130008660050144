import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router'
import { connect } from 'react-redux'

import AdministrationKopfzeile from './AdministrationKopfzeile'

import { sendPasswortResetRequest, sendUserDeleteRequest, sendUserUpdateRequest, sendUserCreateRequest, sendGetUsersRequest } from '../../redux/users'
import PopUp from '../_helpers/PopUp'
import { PermissionForbidden } from '../_helpers/ReadPermission'

import data from '../../data/_data'
import businessobject_data from '../../data/businessobject_data'
import message_data from '../../data/message_data'
import NotFound from './../NotFound';
import {DropdownInput, TextInput, HandleMainInputs} from '../_helpers/InnerContent';
import { checkInvalidation, checkReadOnly, equals, fieldData2options, optionsDataByTypeInit } from '../_helpers/functions'
import { validateValues, defaultEncoder, somethingChanges, arrayDefaultFilter } from './../_helpers/functions';
import { InputByType } from './../_helpers/InnerContent';
import { SingleSelect } from './../_helpers/Kopfzeile';
import Fusszeile from '../Fusszeile'

/** 
 * Komponente des Menüpunktes administration/benutzer/:benutzername
 * Möglichkeit den benutzer zu bearbeiten oder die Attribute eines neuen Benutzer zu erfassen
 * 
 * @author DHR
 * @param {String} ROLENAME - aktuelle Rolle des angemeldeten Benutzers, aus dem State
 * @param {String} loggedIn_benutzername - aktueller Benutzer des angemeldeten Benutzers, aus dem State
 * @param {Function} sendPasswortReset - Request um das Passwort des Benutzer zurückzusetzen vom Backend zu erhalten
 * @param {Function} sendUserDelete - Request um den Benutzer am Backend zu löschen
 * @param {Function} sendUserUpdate - Request um die Attribute des Benutzer am Backend neu zu setzen
 * @param {Function} sendUserCreate - Request um einen Benutzer am Backen zu erstellen
 * @param {Array} userList - Die Liste aller Benutzer, aus dem State
 * @param {String} errorMsg - Error Message sollte Request fehlschlagen, aus dem State
 * @returns {HTML} Benutzer Verwalten
 */
function Administration_SpecificBenutzer({role, loggedIn_username, sendPasswortReset, sendUserDelete, sendUserUpdate, getUserList, competenceData, fieldData, fieldDataError, sendUserCreate, errorMsg, successMsg, userList = [], onSelect, onSelectProperty}){
    var readOnly = true
    const [showResetPopUp, setShowResetPopUp] = useState(false)
    const [showDeletePopUp, setShowDeletePopUp] = useState(false)
    const [showLockPopUp, setShowLockPopUp] = useState(false)
    const [failedValidation, setFailedValidation] = useState({notnull: {}, regex: {}, semantic:  {}})

    const history = useHistory();

    useEffect(() => {if (successMsg === message_data.success.redux.default){history.push('/admin')}}, [successMsg])
    
    var { id } =  useParams()
    const businessObject = data.businessObject.user

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    
    const readOnly_Param = new URLSearchParams(useLocation().search).get('readOnly')
    var accessStatus = ((id === 'neu' | !!onSelect) ? data.accessStatus.create : data.accessStatus.update)

    const fetchUser4Update = () => {
        if(accessStatus === data.accessStatus.update && userList) {
            if (parseInt(id)){
                return userList.find(user => user.id === parseInt(id))
            } else {
                return userList.find(user => user.benutzername === id)
            }
        }
    }

    var object = fetchUser4Update();

    const [values, setValues] = useState((accessStatus === data.accessStatus.update ? defaultEncoder(object) : defaultEncoder(businessobject_data.benutzer.default)))
    if (values){readOnly=(readOnly_Param ? readOnly_Param : PermissionForbidden(role, loggedIn_username, id, businessObject))}
    // const [username, setUsername] = useState(( !benutzer ? "": benutzer.benutzername))
    // const [selectedRole, setSelectedRole] = useState(( !benutzer ? data.roles.MITARBEITER : benutzer.rolle))
    // const [name, setName] = useState(( !benutzer ? "": benutzer.name))
    // const [firstName, setFirstName] = useState(( !benutzer ? "": benutzer.vorname))
    // const [email, setEmail] = useState(( !benutzer ? "": benutzer.email))
    // const [comment, setComment] = useState(( !benutzer ? "": benutzer.beschreibung))
    // // const [employee, setEmployee] = useState(( !benutzer ? "": benutzer.mitarbeiter_id))
    // const [locked, setLocked] = useState(( !benutzer ? false : benutzer.status === data.status.GESPERRT))

    const [error, setError] = useState("")
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [error, errorMsg])
    useEffect(() => {
        if (object && accessStatus === data.accessStatus.update){
            setValues(defaultEncoder(object))
        }
        if (accessStatus === data.accessStatus.create){
            setValues(defaultEncoder(businessobject_data.benutzer.default))
        }
    }, [object, id])
    useEffect(() => {
        if(accessStatus !== data.accessStatus.create){getUserList()};
        if(accessStatus === data.accessStatus.update && userList){
            object = fetchUser4Update()
        }
    }, [id])

    
    const onPasswordReset = () => {setShowResetPopUp(true)}
    const onDelete = () => {setShowDeletePopUp(true)}
    const onLock = () => {setShowLockPopUp(true)}

    const onPasswordReset_sure = () => {setShowResetPopUp(false);sendPasswortReset(object.benutzername)}
    const onDelete_sure = () => {setShowDeletePopUp(false);sendUserDelete(object.id)}
    const onLock_sure = () => {
        setShowLockPopUp(false)
        values.status = (object.status === data.status.GESPERRT ? data.status.FREIGABE_QUALITAETSSICHERUNG : data.status.GESPERRT)
        onSave()
    }

    const onSave = () => {
        if (!validateValues(setError, values, failedValidation, setFailedValidation)){return;}
        if(checkUnique(values.benutzername, values.email)){
            for (let key of Object.keys(values)){
                if (!!values[key] || (!!object[key] && !values[key])){
                    if (Array.isArray(values[key])){
                        //filter default values out of the array
                        let tempValues = arrayDefaultFilter(values[key], key, businessObject)
                        if (tempValues.length > 0 || (object[key] && object[key].length > 0)){
                            object[key] =  tempValues
                        }
                    } else {
                        object[key] = values[key]
                    }
                }
            }
            if(onSelect){
                if (onSelectProperty){
                    onSelect(object[onSelectProperty])
                } else {onSelect(object)}
            } else {
                sendUserUpdate(object)
                if (values.email !== userList.find(u => u.id === object.id).email){
                    sendPasswortReset(object.benutzername)
                }
            }
        }
    }
    const onCreateLock = () => {
        values.status = data.status.GESPERRT
        onCreate()
    }
    const onCreate = () => {
        if (!validateValues(setError, values, failedValidation, setFailedValidation)){return;}
        if(checkUnique(values.benutzername, values.email)){
            object = {}
            for (let key of Object.keys(values)){
                if (key !== 'dateien' && ((!!values[key] && !equals(values[key], businessobject_data[businessObject].default[key])) || (businessobject_data.types[key] && ((typeof businessobject_data.types[key].notnull === 'boolean' && businessobject_data.types[key].notnull) || (Array.isArray(businessobject_data.types[key].notnull) && businessobject_data.types[key].notnull.includes(businessObject)))))){            
                    if (Array.isArray(values[key])){
                        //filter default values out of the array
                        let tempValues = arrayDefaultFilter(values[key], key, businessObject)
                        if (tempValues.length > 0){
                            object[key] =  tempValues
                        }
                    } else {
                        object[key] = values[key]
                    }
                }
            }
            object['benutzername'] = object['benutzername'].toUpperCase();
            
            sendUserCreate(object)

            if(onSelect){
                if (onSelectProperty){
                    onSelect(object[onSelectProperty])
                } else {onSelect(object)}
            }
        }
    }

    // Unique Prüfung, wenn Username oder Email geändert wurden
    const checkUnique = (username, email) => {
        if (!username){setError(message_data.error.admin.user.unsetUser);return false}
        else if (!email){setError(message_data.error.admin.user.unsetEmail);return false}
        for (let user of userList){
            if(object){
                if (user.benutzername === username && username !== object.benutzername) {
                    setError(message_data.error.admin.user.uniqueUser)
                    return false
                }
                else if (user.email === email && email !== object.email){
                    setError(message_data.error.admin.user.uniqueEmail)
                    return false
                }
            } else {
                if(user.benutzername === username){
                    setError(message_data.error.admin.user.uniqueUser)
                    return false
                } else if (user.email === email){
                    setError(message_data.error.admin.user.uniqueEmail)
                    return false
                }
            } 
        }
        return true
    }

    const optionsDataByType = optionsDataByTypeInit(competenceData, fieldData2options(fieldData))

    function returnInput(type, index, key, presentation){
        if (type){
            switch(type.type){
                case data.inputTypes.advanced.SINGLESELECT:
                    if (role === data.roles.BECKOFFICE && key === 'rolle' && accessStatus === data.accessStatus.create && values[key] !== data.roles.MITARBEITER){
                        setValues({...values, [key]: data.roles.MITARBEITER})
                    }
                    return <SingleSelect key={index} readOnly={(role !== data.roles.ADMIN ? true :checkReadOnly(key, readOnly, accessStatus))} filterState={values[key]} selection={type.data} setFilterState={v => setValues({...values, [key]: v})} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null))} notNull={((typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject)))}/>
                case data.inputTypes.advanced.DROPDOWN:
                    let optionsDrop = optionsDataByType(key)
                    return <DropdownInput key={index} multiSelect={!!type.multiSelect} value={values[key]} presentation={presentation} name={key} readOnly={checkReadOnly(key, readOnly, accessStatus)} setValue={v => setValues({...values, [key]: v})} options={optionsDrop} searchable={(type.searchable ? true : false)} notNull={((typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject)))} />
                default:
                    return <InputByType key={index} passKey={index} type={type.type} asNumber={type.asNumber} readOnly={checkReadOnly(key, readOnly, accessStatus)} value={values[key]} name={key} presentation={presentation} setValue={v => setValues({...values, [key]: v})} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null))} notNull={((typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject)))} />
            }
        } else {
            return <TextInput key={index} readOnly={checkReadOnly(key, readOnly, accessStatus)} value={values[key]} name={key} presentation={presentation} setValue={v => setValues({...values, [key]: v})} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null))} notNull={(businessobject_data.types[key] && ((typeof businessobject_data.types[key].notnull === 'boolean' && businessobject_data.types[key].notnull) || (Array.isArray(businessobject_data.types[key].notnull) && businessobject_data.types[key].notnull.includes(businessObject))))} />
        }
    }

    if (accessStatus === data.accessStatus.create){
        if(PermissionForbidden(role, values.benutzername, 'neu', businessObject)){
            return <NotFound notAuth={true} notFoundSite={businessObject} notFoundObject={'neu'} />
        }
    }

    if (Object.keys(values).length === 0 && values.constructor === Object){
        return (
            <div className="content" id="user">
                <AdministrationKopfzeile accessStatus={accessStatus} businessObject={data.businessObject.user} loggedInUser={loggedIn_username} readOnly={true} businessObjectFunctions={{onPasswordReset, onDelete, onLock, onSave, onCreate, onCreateLock}} />
                <div className="inner-content">
                    {errorMsg && <div id="server-error"> <br /> <strong className="error">{errorMsg}</strong></div>}
                    <div className="not-found">
                        <div>Der Benutzer <b>{id}</b> wurde nicht gefunden.</div>
                    </div>
                </div>
                <Fusszeile accessStatus={accessStatus} modDate={'00.00.0000 00:00'}/>
            </div>
        )

    } else {
        return( 
        <div className="content" id="user">
            <AdministrationKopfzeile somethingChanges={somethingChanges(accessStatus, values, object, businessObject)} businessObject={data.businessObject.user} accessStatus={accessStatus} object={values} loggedInUser={loggedIn_username} readOnly={readOnly} businessObjectFunctions={{onPasswordReset, onDelete, onLock, onSave, onCreate, onCreateLock}} />
            <div className="inner-content">
                {(fieldDataError && !errorMsg) && <div id="server-error"><strong className="error">{fieldDataError}</strong><br /><br />  </div>}
                {errorMsg && <div><strong className="error">{errorMsg}</strong><br /> </div>}
                {showResetPopUp ? <PopUp title='Sind Sie sich sicher?' text={<div>Sie möchten das Passwort für <strong>{values.benutzername} zurücksetzen</strong></div>} close={onPasswordReset_sure} abort={() => setShowResetPopUp(false)} closeText="Zurücksetzen" /> : null}
                {showDeletePopUp ? <PopUp title='Sind Sie sich sicher?' text={<div>Sie möchten den Nutzer <strong>{values.benutzername} löschen</strong></div>} close={onDelete_sure} abort={() => setShowDeletePopUp(false)} closeText="Löschen" /> : null}
                {showLockPopUp ? <PopUp title='Sind Sie sich sicher?' text={<div>Sie möchten den Nutzer <strong>{values.benutzername} {(values.status === data.status.GESPERRT ? "Entsperren" : "Sperren")}</strong></div>} close={onLock_sure} abort={() => setShowLockPopUp(false)} closeText={(values.status === data.status.GESPERRT ? "Entsperren" : "Sperren")} /> : null}
                {accessStatus === data.accessStatus.create && <h3>Neuen Benutzer anlegen</h3>}
                {error && <div id="client-error"> <br /> <small className="error">{error}</small></div>}
                <HandleMainInputs fields={businessobject_data.benutzer.fields} businessObject={businessObject} returnInput={returnInput} />
                <br />
                {accessStatus === data.accessStatus.create && <small>*Beim Anlegen eines Benutzers wird ein automatisch generiertes Einmal-Passwort per Mail an den Nutzer gesendet</small>}
            </div>
            <Fusszeile status={accessStatus} modDate={(object ? object.modDate : '00.00.0000 00:00')} />
        </div>
        )
    }
}
/**
 * Weist den verwendeten lokalen Variablen, variablen aus dem State zu
 * 
 * @param {*} state aktueller State
 * @param {*} ownProps weitere Variablen
 * @returns variablen aus dem State alsVariablen der Componenten
 */
 const mapStateToProps = (state, ownProps) => ({
    role: state.user.role,
    loggedIn_benutzername: state.user.username,
    userList: state.users.list,
    fieldData: state.fielddata.data,
    fieldDataError: state.fielddata.error,
    competenceData: state.competences.list,
    errorMsg: state.users.error,
    successMsg: state.users.success,
    ...ownProps
})

/**
 * Weist den verwendeten lokalen Funktionen, Funktionen aus dem Redux zu
 * 
 * @returns Die gemappten Funktionen
 */
const mapDispatchToProps = dispatch => ({
    getUserList: () => dispatch(sendGetUsersRequest()),
    sendPasswortReset: username => dispatch(sendPasswortResetRequest(username)),
    sendUserDelete: id => dispatch(sendUserDeleteRequest(id)),
    sendUserUpdate: benutzer  => dispatch(sendUserUpdateRequest(benutzer)),
    sendUserCreate: benutzer => dispatch(sendUserCreateRequest(benutzer))
})

export default connect(mapStateToProps, mapDispatchToProps)(Administration_SpecificBenutzer)