import React from 'react'
import {SERVER, UNSET_USER, REFRESH_USER, REQUIRED_USER, UNSUCCESS_REFRESH_USER} from './constants'
import {requestURL_Login, requestURL_refresh} from './user'
import {SET_LOADING, SET_LOADINGSCREEN_RESPONSE} from './constants'
import {setLoadingScreenResponse} from './loadingphase'
import message_data from '../data/message_data'
/**
 * Der RequestBuilder baut für jeden Request zur API die Verbindung auf und fängt Fehler ab.
 * Bei Erfolg wird die übergebene Funktion ausgeführt (meist eine Funkton um die responseJson zu einem Type zu mappen).
 *
 * @author DHR
 * @param {String} requestStart - der type der action wenn der Request startet.
 * @param {String} requestFailed - der type der action wenn der Request scheitert.
 * @param {String} requestError - der type der action wenn der Request mit einem HTTP Fehlercode scheitert
 * @param {String} responseError - der type der action wenn der Request mit einer errorMsg in der Response scheitert
 * @returns responseJson.data Die Empfangene JSON.data, also ohne header etc.
 */
var responseStatusCode = 0
export const getApiRequestBuilder =
    (requestStart, requestFailed, requestError, responseError) =>
    (url, options = {}, onSuccess = (f) => f) =>
    (dispatch) => {
        dispatch({type: requestStart})
        const responseLoadingScreen = options.method
        dispatch({type: SET_LOADING, payload: responseLoadingScreen})
        // console.log("### content loading")
        var result = fetch(url, options)
            // HTTP works
            // depending on the status set action for state
            .then((response) => {
                // Parse the JSON
                // if (responseLoadingScreen === 'GET') {
                //     dispatch({type: SET_LOADING, payload: false})
                //     // console.log("### content loaded, GET")
                // }
                responseStatusCode = response.status
                if (response.status === 200 || response.status === 400) {
                    if (response.headers.get('Content-Type') === 'application/octet-stream;charset=UTF-8') {
                        response.blob().then((blob) => {
                            const filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0] //content-disposition: attachment; filename=
                            dispatch({type: SET_LOADING, payload: false})
                            return dispatch(onSuccess(filename, blob))
                        })
                    } else {
                        dispatch({type: SET_LOADING, payload: false})
                        return response.json()
                    }
                } else if (response.status === 201) {
                    dispatch(setLoadingScreenResponse(message_data.success.redux.created, true))
                    return dispatch(onSuccess(response.headers.get('Location')))
                } else if (response.status === 202) {
                    if (options.method === 'DELETE'){
                        dispatch(setLoadingScreenResponse(message_data.success.redux.deleted, true))
                    } else {
                        dispatch(setLoadingScreenResponse(message_data.success.redux.updated, true))
                    }
                    return dispatch(onSuccess(response.headers.get('Location')))
                } else if (response.status === 204) {
                    if (options.method === 'DELETE'){
                        dispatch(setLoadingScreenResponse(message_data.success.redux.deleted, true))
                    }
                    dispatch({type: SET_LOADING, payload: false})
                    return dispatch(onSuccess())
                } else if (url !== requestURL_Login && (response.status === 401 || response.status === 403)) {
                    dispatch({type: SET_LOADING, payload: false})
                    return dispatch({type: REQUIRED_USER})
                } else if (response.status === 404) {
                    dispatch({type: SET_LOADING, payload: false})
                    return dispatch({type: requestError, errorMessage: {responseStatusCode, responseStatusText: message_data.error.redux.notFound}})
                } else if ((response.status > 400 && response.status < 405) || response.status === 409 || response.status === 500) {
                    var responseStatusText = response.statusText
                    const errorMessage = {responseStatusCode, responseStatusText}
                    if (url !== requestURL_Login){
                        dispatch(setLoadingScreenResponse([message_data.error.redux.default, <br />, responseStatusText]))
                    }
                    return dispatch({type: requestError, errorMessage})
                } else {
                    dispatch({type: SET_LOADING, payload: false})
                    return Promise.reject(response)
                }
            })
            .then((json) => {
                if (responseStatusCode === 200) {
                    dispatch({type: SET_LOADING, payload: false})
                    // console.log("### content loaded, 200")
                    return dispatch(onSuccess(json))
                } else if (responseStatusCode === 400) {
                    dispatch(setLoadingScreenResponse(json.errorMessage))
                    return dispatch({type: responseError, ...json})
                }
            })
            // HTTP doesnt work or above return Promise.reject
            .catch((error) => {
                dispatch(setLoadingScreenResponse(message_data.error.redux.fatal))
                console.error(error)
                dispatch({type: requestFailed, error})
            })

        return result
    }
