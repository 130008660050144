import { SET_DATA, DATA_REQUEST_ERROR, DATA_REQUEST_FAILED, DATA_REQUEST_START, DATA_RESPONSE_ERROR, SERVER } from "./constants"
import { getApiRequestBuilder } from "./util"

import message_data from '../data/message_data'

/**
 * Redux API um weitere Daten, unteranderem für Dropdowns anzufordern
 * 
 * @author DHR
 */

// build the requestBuilder with the Failure types for the Reducer
const requestBuilder = getApiRequestBuilder(DATA_REQUEST_START, DATA_REQUEST_FAILED, DATA_REQUEST_ERROR, DATA_RESPONSE_ERROR)
export const requestURL = SERVER + 'data/'

/**
 * Setzt die Field Data (DropdownOptions etc.) in den State
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben
 * 
 * @param {Object} jsonData - Responsejson.data
 * @returns action(type,...payload) zum aktualisieren des States
 */
const setData = jsonData => ({type: SET_DATA, ...jsonData})

/**
 * Sendet Field Data Request
 * Es wird eine onSucces-Funktion 'setData' Übergeben
 * 
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
export function sendDataRequest(){
    return(requestBuilder(
        requestURL, 
        {
            method: 'GET', 
            headers: { credentials: "same-origin", 'Accept': 'application/json', 'Content-Type': 'application/json' },
        }, 
        setData)
    )
}

/**
 * Zugriff auf den aktuellen State und erweitern bzw. modifizieren dessen
 * Reducer by action.type
 * 
 * @see constants.js
 * @param {*} state - takes the currentstate
 * @param {*} action - takes some type and some values as payload
 * @returns the new state 
 */
export const fielddata = (state = { }, { type, ...payload }) => {
    switch (type) {
        case SET_DATA:
            return {
                data: payload
            }
        case DATA_REQUEST_FAILED:
            return {
                error: message_data.error.redux.unavailable
            }
        case DATA_REQUEST_ERROR:
            return {
                error: message_data.error.redux.default + payload.errorMessage.responseStatusCode + payload.errorMessage.responseStatusText
            }
        case DATA_RESPONSE_ERROR:
            return {
                error: payload.errorMessage
            }
        default:
            return state
    }
}