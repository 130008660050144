import React, {useState, useEffect} from 'react'
import businessobject_data from '../../data/businessobject_data'
import { DateSearch, HeaderButtons, Tabs} from '../_helpers/Kopfzeile'
import Select from 'react-select';
import { optionsFilter } from '../_helpers/functions';
import data from '../../data/_data';

// import { Search } from '../_helpers/Kopfzeile'

/**
 * Kopfzeilen-Komponente für die Seiten "Moderation", Rendert alle folegenden Komponenten zur Einstellung der Filter- und Suchkriterien
 * 
 * @author DHR
 * @param {String} geschaeftsobjekt - aktuelles Geschäftsobjekt der Route
 * @returns {HTML} zu Rendernde Komponente:
 */
function ModerationKopfzeile ({readOnly, categoryFilter, setCategoryFilter, setSearchAttr, setSearchVal, searchAttr, searchVal, successMsg, setSearchStartDate, setSearchEndDate, searchStartDate, searchEndDate, onFilter, onFilterReset, competenceFilterData, setSearchFKAttr}){
    var options = []
    // for (var value of Object.keys(businessobject_data.moderation.searchAttributes)){
    //     options.push({value: value, label: businessobject_data.moderation.searchAttributes[value]})
    // }
    if (categoryFilter) {
        for (var value of Object.keys(businessobject_data[categoryFilter].searchAttributes)){
            if (value !== 'isSynonym'){options.push({value: value, label: businessobject_data[categoryFilter].searchAttributes[value]})}
        }
        if (categoryFilter === data.businessObject.competence || categoryFilter === data.businessObject.qualification){
            options.push({value: 'modBy', label: 'Geändert von'})
        }// [{value: '',label: ''}]
    }
    
    const tabs = [data.businessObject.consultant, data.businessObject.consultantproject, data.businessObject.competence, data.businessObject.qualification]   
    const [tab, setTab] = useState(( categoryFilter ? categoryFilter : tabs[0]))
    
    const setTabExt = (tab) => {setTab(tab); setCategoryFilter(tab)}
    useEffect(() => {if (categoryFilter){setSearchVal(''); setSearchAttr(options[1].value)}}, [tab])

    useEffect(() => {if (!searchAttr && options[0]){setSearchAttr(options[0].value)}})

    return (
        <div className="content-header" id="moderation-search">
            <div className="search">
                <div className="textlabel"><b>Suche: </b>Moderation</div> {/* Berater, Beraterprojekte und Kompetenzen <br /> zur  */}

                

                <div className="search-bar">
                    <Select className="dropdown" classNamePrefix="react-select" name="SearchAttr" options={options} value={optionsFilter(options, 'value', searchAttr)} isSearchable={false} defaultValue={options[0]} placeholder={(readOnly ? "" : "Bitte wählen...")} onChange={s => setSearchAttr(s.value)}/>
                    {searchAttr==='kompetenzen' && <Select className="dropdown" classNamePrefix="react-select" name="SearchFKAttr" options={competenceFilterData} placeholder={(readOnly ? "" : "Bitte wählen...")} isSearchable={false} onChange={s => setSearchFKAttr(s.value)}/> }
                    {((searchAttr==='kategorie' || searchAttr==='typ' || searchAttr==='attributeType') ?
                        <Select className="dropdown" classNamePrefix="react-select" name="SearchVal" options={competenceFilterData} isSearchable={false} placeholder={(readOnly ? "" : "Bitte wählen...")} onChange={s => setSearchVal(s.value)}/>
                        :
                        <input className="search-input" type="text" name="SearchVal" disabled={!categoryFilter} value={searchVal} onChange={e=> setSearchVal(e.target.value)} onKeyDown={(event) => {if(event.key == 'Enter'){onFilter()}}} />
                    )}
                </div>

                <DateSearch searchStartDate={searchStartDate} searchEndDate={searchEndDate} setSearchStartDate={setSearchStartDate} setSearchEndDate={setSearchEndDate}  readOnly={((categoryFilter === data.businessObject.competence || categoryFilter === data.businessObject.qualification) ? true : false)} />

                <Tabs tabs={tabs} activeTab={tab} setTab={setTabExt} />
            </div>
            
            <HeaderButtons main={{function: onFilter, value: 'Filter anwenden'}} second={{function: onFilterReset, value: 'Filter zurücksetzen'}} />
        </div>
    )
}

export default ModerationKopfzeile