import { SERVER, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_REQUEST_START, BUSINESSOBJECT_REQUEST_ERROR, BUSINESSOBJECT_RESPONSE_ERROR, SET_CUSTOMERS } from "./constants"
import { getApiRequestBuilder } from "./util"
import {setSuccess} from './businessobjects'

import message_data from '../data/message_data'

/**
 * Redux API um den Berater am Backend zu verwalten
 * 
 * @author DHR
 */
// build the requestBuilder with the Failure types for the Reducer
const requestBuilder = getApiRequestBuilder(BUSINESSOBJECT_REQUEST_START, BUSINESSOBJECT_REQUEST_FAILED, BUSINESSOBJECT_REQUEST_ERROR, BUSINESSOBJECT_RESPONSE_ERROR)
export const requestURL_get = SERVER + "kunde"
const requestURL_create = SERVER + "kunde"
const requestURL_delete = SERVER + "kunde"
const requestURL_update = SERVER + "kunde"


 /**
 * Setzt das Json Objekt der Response als Liste aller Kunden
 * Die Funktion wird dem RequestBuilder als onSuccess gegeben.
 * 
 * @param {Object} jsonData - Responsejson.data
 * @returns action(type,...payload) zum aktualisieren des States
 */
  const setCustomerList = (jsonData) => ({type: SET_CUSTOMERS, list:jsonData})

/**
 * Sendet Request um die Liste des jeweiligen Geschäftsobjektes zu erhalten
 * 
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
 export function sendGetCustomerListRequest(){
    
    return (requestBuilder(
        (requestURL_get),
        {
        method: 'GET', 
        headers: { credentials: "same-origin", 'Accept': 'application/json'},
        }, setCustomerList)
    )
}
/**
 * Sendet Request um den Benutzer zu löschen
 * 
 * @param {String} id - des zulöschenden Kunden
 * @returns Redux Action mit ggf. Payload des Requestbodys
 */
 export function sendDeleteCustomerRequest(id){
    return(requestBuilder(
        (requestURL_delete + '/'+id), 
        {
            method: 'DELETE', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' }
        }, setSuccess)
    )
}
/**
 * Sendet Request um Attribute das Kunden neu zusetzen
 * 
 * @param {Object} customer - Objekt des Kunden
 * @returns Redux Action
 */
 export function sendUpdateCustomerRequest(customer){
    return(requestBuilder(
        (requestURL_update + '/' + customer.id), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(customer)
        }, setSuccess)
    )
}
/**
 * Sendet Request um einen Kunden anzulegen
 * 
 * @param {Object} customer - Objekt des Kunden
 * @returns Redux Action
 */
 export function sendCreateCustomerRequest(customer){
    return(requestBuilder(
        (requestURL_create), 
        {
            method: 'POST', 
            headers: { credentials: "same-origin", 'Content-Type': 'application/json' },
            body: JSON.stringify(customer)
        }, setSuccess)
    )
}

/**
 * Zugriff auf den aktuellen State und erweitern bzw. modifizieren dessen
 * Reducer by action.type
 * 
 * @see constants.js
 * @param {*} state - takes the currentstate
 * @param {*} action - takes some type and some values as payload
 * @returns the new state 
 */
 export const customers = (state = { }, { type, ...payload }) => {
    switch (type) {
        case SET_CUSTOMERS:
            return {
                ...state, 
                list: payload.list
            }
        default:
            return state
    }
}