import React, { useState, useEffect} from 'react'
import { connect } from 'react-redux'

import businessobject_data from '../../../data/businessobject_data'
import data from '../../../data/_data'

import PflegenKopfzeile_Kunde from './PflegenKopfzeile_Kunde'

import {sendDeleteCustomerRequest, sendCreateCustomerRequest, sendUpdateCustomerRequest, sendGetCustomerListRequest} from '../../../redux/costumers'
import PflegenFusszeile from '../../Fusszeile'
import NotFound from './../../NotFound';
import { getOptions, getOptionsInit, optionsDataByTypeInit, defaultEncoder, equals, checkReadOnly, checkInvalidation, fieldData2options } from '../../_helpers/functions'
import {DropdownInput, TextInput, HandleMainInputs} from '../../_helpers/InnerContent';
import { FileInput, InLineInput, InputByType, ObjectFKInput } from './../../_helpers/InnerContent';
import { PermissionForbidden } from './../../_helpers/ReadPermission';
import message_data from './../../../data/message_data';
import { useHistory } from 'react-router-dom';
import { somethingChanges, semanticCheck, validateValues, arrayDefaultFilter } from './../../_helpers/functions';
import PopUp from './../../_helpers/PopUp';



/**
 * Unter-Komponente für die Seiten "Beraterprofil Pflegen".
 * Bearbeitet oder legt den gewählte Berater an
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {String} username - benutzername des angemeldeten Benutzers
 * @param {String} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} accessStatus - status des Pflegens des Geschaeftsobjektes (suche, neu, gefuehrt)
 * @param {Object} id - die ID des konkreten Kunden
 * @param {Object} businessObject - die Art des Gechäftsobjektes, hier Ansprechpartner oder Kunde
 * @param {Object} list - Liste aller Kunden aus dem State, von der API
 * @param {Object} object - Ansprechpartnerobjekt
 * @param {Object} consultantlist - Liste aller Berater
 * @param {Function} getList - Funktion zum request der Kunden von der API
 * @param {Function} sendCreate - Funktion zum API call zum Erstellen des Objektes
 * @param {Function} sendUpdate - Funktion zum API call zum Aktualisieren des Objektes
 * @param {Function} sendDelete - Funktion zum API call zum Löschen des Objektes
 * @param {String} errorMsg - errorMsg wenn API call fehlschlägt
 * @param {String} fieldData - Feldauspraegungen aus dem State
 * @param {String} competenceData - Kompetenzen zur Auswahl im Dropdown o.ä.
 * @param {String} fieldDataError - errorMsg wenn API call, speziell für die Feldauspraegungen fehlschlägt
 * @param {String} onSelect - Funktion welche aufgerufen wird wenn Angelegt wird, nur wnen Komponentnet direkt und nicht über das Routing aufgerufen wird
 * @param {String} onSelectProperty - Key des als Fremdschlüssel zu verwendenen Objektes bei onSelect
 * @param {String} onSelectRemove -Funktion zum Löschen des Objektes, ohne API
 * @param {String} successMsg - successMsg wenn Anlegen oder Update erfolgreich
 * @returns {HTML} Seite zum Pflegen der Geschaeftsobjekte
 * @see PflegenKopfzeile
 * @see PflegenFusszeile
 */
function Pflegen_Kunde({role, username, accessStatus, id, anspId, businessObject, object, list, consultantList, getList, readOnly, successMsg, errorMsg, sendCreate, sendUpdate, sendDelete, onSelect, onSelectObject, onSelectRemove, onSelectProperty, fieldData, fieldDataError, competenceData}){
    // correct the accessStatus if onSelect has given an object
    if (!accessStatus){accessStatus = (onSelectObject ? data.accessStatus.update : data.accessStatus.create)}
    // some State Var for PopUp and Notification about the success of an api call
    const [showDeletePopUp, setShowDeletePopUp] = useState(false)
    const [showLockPopUp, setShowLockPopUp] = useState(false)
    const [failedValidation, setFailedValidation] = useState({notnull: {}, regex: {}, semantic:  {}})
    const [tabOfFailure, setTabOfFailure] = useState('')


    const [tab, setTabintern] = useState(Object.keys(businessobject_data[businessObject].fields)[0])
    const setTab = (tab) => {
        if(somethingChanges(accessStatus, values, object, businessObject) && !validateValues(setError, values, failedValidation, setFailedValidation, businessObject, setTabOfFailure)){
            if (tabOfFailure){
                setTabintern(tabOfFailure)
                return;
            } else {
                return;
            }
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTabintern(tab)
    } 

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    const history = useHistory();
    useEffect(() => {if (successMsg === message_data.success.redux.default && businessObject !== data.businessObject.contactperson){history.push('/pflegen')}}, [successMsg])
    // var object
    if (businessObject === data.businessObject.contactperson){
        object = (accessStatus === data.accessStatus.update ? onSelectObject : null)
    } else {
        if(accessStatus === data.accessStatus.update && list){
            object = list.find(o => o.id === parseInt(id))
        }
    }

    // const businessOnject = data.businessObject.customer

    const [error, setError] = useState("")
    // values for the object to safe
    const [values, setValues] = useState((accessStatus === data.accessStatus.update ? defaultEncoder(object) : (businessObject === data.businessObject.contactperson ? defaultEncoder(businessobject_data.ansprechpartner.default) : defaultEncoder(businessobject_data.kunde.default))))
    // check the rights of the current user
    if (values){readOnly=(readOnly ? readOnly : PermissionForbidden(role, username, id, data.businessObject.customer, values))}
    // load Object
    useEffect(() => {
        if (object && accessStatus === data.accessStatus.update){
            setValues(defaultEncoder(object))
        }
        if (accessStatus === data.accessStatus.create){
            if (businessObject === data.businessObject.contactperson){
                setValues(defaultEncoder(businessobject_data.ansprechpartner.default))
            } else {
                setValues(defaultEncoder(businessobject_data.kunde.default))
            }
        }
    }, [object, id])
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [error, errorMsg])
    // find object by id
    useEffect(() => {
        if(accessStatus !== data.accessStatus.create){getList()};
        if(accessStatus === data.accessStatus.update && list){
            object = list.find(o => o.id === parseInt(id))
        }
    }, [id])

    // open PopUpto to Confirm on Function call
    const onDelete = () => {setShowDeletePopUp(true)}
    const onLock = () => {setShowLockPopUp(true)}
    // close PopUp and do api call
    const onDelete_sure = () => {
        setShowDeletePopUp(false);
        if (onSelectRemove){
            onSelectRemove()
        } else {
            sendDelete(id);
        }
    }
    const onLock_sure = () => {
        setShowLockPopUp(false)
        values.status = (object.status === data.status.GESPERRT ? data.status.FREIGABE_QUALITAETSSICHERUNG : data.status.GESPERRT)
        onSave()
    }

    const checkUnique = (symbol) => {
        if (symbol !== object?.symbol) {
            if (list?.find(o => o.symbol === symbol)){
                setError(message_data.error.pflegen.unique)
                return false
            }
        }
        return true
    }
    // validate and copy the object to save it via API call
    const onSave = () => {
        if (!validateValues(setError, values, failedValidation, setFailedValidation)){
            if (tabOfFailure){
                setTabintern(tabOfFailure)
                return;
            } else {
                return;
            }
        }
        if(!checkUnique(values.symbol)){return;}
        for (let key of Object.keys(values)){
            if (!!values[key] || (!!object[key] && !values[key])){
                if (Array.isArray(values[key])){
                    //filter default values out of the array
                    let tempValues = arrayDefaultFilter(values[key], key, businessObject)
                    if (tempValues.length > 0 || (object[key] && object[key].length > 0)){
                        object[key] =  tempValues
                    }
                } else {
                    object[key] = values[key]
                }
            }
        }
        if(onSelect){
            if (onSelectProperty){
                onSelect(object[onSelectProperty])
            } else {onSelect(object)}
        } else {
            sendUpdate(object)
        }
        
    }
    // lock the Object and create it
    const onCreateLock = () => {
        values.status = data.status.GESPERRT
        onCreate()
    }
    // validate and create the object via API call
    const onCreate = () => {
        if (!validateValues(setError, values, failedValidation, setFailedValidation)){
            if (tabOfFailure){
                setTabintern(tabOfFailure)
                return;
            } else {
                return;
            }
        }
        if(!checkUnique(values.symbol)){return;}
        object = {}
        for (let key of Object.keys(values)){
            if (key !== 'dateien' && ((!!values[key] && !equals(values[key], businessobject_data[businessObject].default[key])) || (businessobject_data.types[key] && ((typeof businessobject_data.types[key].notnull === 'boolean' && businessobject_data.types[key].notnull) || (Array.isArray(businessobject_data.types[key].notnull) && businessobject_data.types[key].notnull.includes(businessObject)))))){            
                if (Array.isArray(values[key])){
                    //filter default values out of the array
                    let tempValues = arrayDefaultFilter(values[key], key, businessObject)
                    if (tempValues.length > 0){
                        object[key] =  tempValues
                    }
                } else {
                    object[key] = values[key]
                }
            }
        }
        if(onSelect){
            if (onSelectProperty){
                onSelect(object[onSelectProperty])
            } else {onSelect(object)}
        } else {
            sendCreate(object)
        }
    }
    // load functions to get keys and options data
    const optionsDataByType = optionsDataByTypeInit(competenceData, fieldData2options(fieldData))
    const getOptions = getOptionsInit(optionsDataByType)
    // tabs
    var tabs
    var fields
    tabs = Object.keys(businessobject_data[businessObject].fields)
    fields = businessobject_data[businessObject].fields[tab]
    // return inputfield for each key by type
    //load options for the input if needed
    function returnInput(type, index, key, presentation){
        if (type){
            switch(type.type){
                case data.inputTypes.advanced.DROPDOWN:
                    let optionsDrop = optionsDataByType(key)
                    return <DropdownInput key={index} multiSelect={!!type.multiSelect} value={values[key]} presentation={presentation} name={key} readOnly={checkReadOnly(key, readOnly, accessStatus)} setValue={v => setValues({...values, [key]: v})} options={optionsDrop} notNull={((typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject)))} />
                // case data.inputTypes.advanced.FILE:
                //     return <FileInput key={index} picture={!!type.picture} requestedFile={requestedFile4Download} downloadFile={onFileDownload} multiple={!!type.multiSelect} dateitypOptions={optionsDataByType('dateityp')} presentation={presentation} field={key} readOnly={checkReadOnly(key, readOnly, accessStatus} value={values[key]} setValue={v => setValues({...values, [key]: v})}  />
                case data.inputTypes.advanced.INLINEDEFINE:
                    let optionsObjInline = getOptions(key)
                    return <InLineInput key={index} name={key} presentation={presentation} create={!!type.create} readOnly={checkReadOnly(key, readOnly, accessStatus)} setValue={v => setValues({...values, [key]: v})} value={values[key]} defaultObject={{...businessobject_data[(businessobject_data.types[key].dataAttribute ? businessobject_data.types[key].dataAttribute : key)].default}} presentationObject={businessobject_data[(businessobject_data.types[key].dataAttribute ? businessobject_data.types[key].dataAttribute : key)].fields} optionsObject={optionsObjInline} invalidObject={checkInvalidation(key, failedValidation, setFailedValidation, values[key])} />
                case data.inputTypes.advanced.OBJECTFK:
                    return <ObjectFKInput key={index} name={key} readOnly={checkReadOnly(key, readOnly, accessStatus)} presentation={presentation} value={values[key]} setValue={v => setValues({...values, [key]: v})} multiSelect={type.multiSelect} create={type.create} objectList={(type.businessObject === data.businessObject.consultant ? (consultantList ? consultantList : []) : [])} businessObject={type.businessObject} valueProperty={type.selectProperty} presentationProperty={(type.presentationProperty ? type.presentationProperty : type.selectProperty)} filter={((type.filterBy && type.filterValue) ? {attr: type.filterBy, value: type.filterValue} : null)} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null))} notNull={((typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject)))} />
                default:
                    return <InputByType key={index} passKey={index} type={type.type} asNumber={type.asNumber} readOnly={checkReadOnly(key, readOnly, accessStatus)} value={values[key]} name={key} presentation={presentation} setValue={v => setValues({...values, [key]: v})} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null))} notNull={((typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject)))} />
            }
        } else {
            return <TextInput key={index} readOnly={checkReadOnly(key, readOnly, accessStatus)} value={values[key]} name={key} presentation={presentation} setValue={v => setValues({...values, [key]: v})} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null))} notNull={(businessobject_data.types[key] && ((typeof businessobject_data.types[key].notnull === 'boolean' && businessobject_data.types[key].notnull) || (Array.isArray(businessobject_data.types[key].notnull) && businessobject_data.types[key].notnull.includes(businessObject))))} />
        }
    }

    if (Object.keys(values).length === 0 && values.constructor === Object){
        return (
            <div className="content" id="customer">
                <PflegenKopfzeile_Kunde accessStatus={accessStatus} readOnly={true} businessObjectFunctions={{onDelete, onLock, onSave, onCreate, onCreateLock}} setTab={setTab} tabs={tabs} tab={tab} />
                <div className="inner-content">
                    {errorMsg && <div> <br /> <strong className="error">{errorMsg}</strong></div>}
                    <div className="not-found">
                        <div>Der Kunde <b>{id}</b> wurde nicht gefunden.</div>
                    </div>
                </div>
                <PflegenFusszeile accessStatus={accessStatus} modDate={'00.00.0000 00:00'} />
            </div>
        )
    } else {
        if (businessObject === data.businessObject.contactperson){
            return(
                <div className="content" id="contactperson">
                    <PflegenKopfzeile_Kunde id={id} role={role} loggedInUsername={username} accessStatus={accessStatus} businessObject={data.businessObject.contactperson} object={values} readOnly={readOnly} somethingChanges={somethingChanges(accessStatus, values, object, businessObject)} businessObjectFunctions={{onDelete, onSave, onCreate}} setTab={setTab} tabs={Object.keys(businessobject_data.ansprechpartner.fields)} tab={tab} />
                    <div className="inner-content">
                    {(fieldDataError && !errorMsg) && <div id="server-error"><strong className="error">{fieldDataError}</strong><br /> <br /> </div>}
                    {errorMsg && <div><strong className="error">{errorMsg}</strong></div>}
                    {showDeletePopUp ? <PopUp title='Sind Sie sich sicher?' text={<div>Sie möchten den Ansprechpartner <strong>löschen</strong></div>} close={onDelete_sure} abort={() => setShowDeletePopUp(false)} closeText="Löschen" /> : null}
                    {accessStatus === data.accessStatus.create && <h3>Neuen Ansprechpartner Anlegen</h3>}
                    {error && <div id="client-error"> <br /> <small className="error">{error}</small></div>}
                    <HandleMainInputs fields={fields} tab={tab} businessObject={businessObject} returnInput={returnInput} />
                    </div>
                    <PflegenFusszeile accessStatus={accessStatus} modDate={(object ? object.modDate : '00.00.0000 00:00')} />
                </div>
            )
        } else {
            return(
                <div className="content" id="customer">
                    <PflegenKopfzeile_Kunde id={id} role={role} loggedInUsername={username} accessStatus={accessStatus} businessObject={data.businessObject.customer} object={values} readOnly={readOnly} somethingChanges={somethingChanges(accessStatus, values, object, businessObject)} businessObjectFunctions={{onDelete, onLock, onSave, onCreate, onCreateLock}} setTab={setTab} tabs={Object.keys(businessobject_data.kunde.fields)} tab={tab} />
                    <div className="inner-content">
                    {(fieldDataError && !errorMsg) && <div id="server-error"><strong className="error">{fieldDataError}</strong><br /> <br /> </div>}
                    {errorMsg && <div><strong className="error">{errorMsg}</strong></div>}
                    {showDeletePopUp ? <PopUp title='Sind Sie sich sicher?' text={<div>Sie möchten den Kunden, <strong>{id}</strong> löschen</div>} close={onDelete_sure} abort={() => setShowDeletePopUp(false)} closeText="Löschen" /> : null}
                    {showLockPopUp ? <PopUp title='Sind Sie sich sicher?' text={<div>Sie möchten den Kunden, <strong>{id}</strong> {(values.status === data.status.GESPERRT ? "Entsperren" : "Sperren")}</div>} close={onLock_sure} abort={() => setShowLockPopUp(false)} closeText={(values.status === data.status.GESPERRT ? "Entsperren" : "Sperren")} /> : null}
                    {accessStatus === data.accessStatus.create && <h3>Neuen Kunden anlegen</h3>}
                    {error && <div id="client-error"> <br /> <small className="error">{error}</small></div>}
                    <HandleMainInputs fields={fields} tab={tab} businessObject={businessObject} returnInput={returnInput} />
                    </div>
                    <PflegenFusszeile accessStatus={accessStatus} modDate={(object ? object.modDate : '00.00.0000 00:00')} />
                </div>
            )
        }
    }
}

/**
 * Weist den verwendeten lokalen Variablen, variablen aus dem State zu
 * 
 * @param {*} state - aktueller State
 * @param {*} ownProps - weitere Variablen
 * @returns den aktuellen State als Komponentenvariablen
 */
 const mapStateToProps = (state, ownProps) => ({
    role: state.user.role,
    username: state.user.username,
    errorMsg: state.businessobjects.error,
    list: state.customers.list,
    consultantList: state.consultants.list,
    successMsg: state.businessobjects.success,
    fieldData: state.fielddata.data,
    competenceData: state.competences.list,
    fieldDataError: state.fielddata.error,

    ...ownProps
})

/**
 * Weist den verwendeten lokalen Funktionen, Funktionen aus dem Redux zu
 * 
 * @returns Die gemappten Funktionen
 */
const mapDispatchToProps = dispatch => ({
    getList: () => dispatch(sendGetCustomerListRequest()),
    sendDelete: (id) => dispatch(sendDeleteCustomerRequest(id)),
    sendUpdate: (customer) => dispatch(sendUpdateCustomerRequest(customer)),
    sendCreate: (customer) => dispatch(sendCreateCustomerRequest(customer)),
}) 

export default connect(mapStateToProps, mapDispatchToProps)(Pflegen_Kunde)